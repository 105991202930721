// 当前浏览器的ua
const ua = window.navigator.userAgent.toLowerCase();
// 真正对外提供的AppCore对象
const AppCore = {};
const eleProto = typeof Element !== 'undefined' ? Element.prototype : {};
const vendor = eleProto.matches || eleProto.matchesSelector || eleProto.webkitMatchesSelector || eleProto.mozMatchesSelector || eleProto.msMatchesSelector || eleProto.oMatchesSelector;

// 绑定事件的缓存
const EvtCache = {};
/**
 * 执行代理事件
 *
 * @param {Element} element
 * @param {String} selector
 * @param {String} type
 * @param {Function} callback
 * @return {Function}
 */
function listener(element, type, selector, callback) {
  return function(e) {
    const match = Helper.closest(e.target, selector, true);
    if (match) {
      const event = Object.assign(createProxy(e), { currentTarget: match });
      // 立即阻止冒泡
      if (event.isImmediatePropagationStopped()) return;
      const result = callback.call(match, event);
      if (result === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      return result;
    }
  };
}
const returnTrue = function() {
  return true;
};
const returnFalse = function() {
  return false;
};
const ignoreProperties = /^([A-Z]|returnValue$|layer[XY]$|webkitMovement[XY]$)/;
const eventMethods = {
  preventDefault: 'isDefaultPrevented',
  stopImmediatePropagation: 'isImmediatePropagationStopped',
  stopPropagation: 'isPropagationStopped',
};
/*eslint-disable */
function compatible(event, source) {
  if (source || !event.isDefaultPrevented) {
    source || (source = event);
    for (const name in eventMethods) {
      const sourceMethod = source[name];
      const predicate = eventMethods[name];
      event[name] = function() {
        this[predicate] = returnTrue;
        return sourceMethod && sourceMethod.apply(source, arguments);
      };
      event[predicate] = returnFalse;
    }
    try {
      event.timeStamp || (event.timeStamp = Date.now());
    } catch (ignored) {}

    if (source.defaultPrevented !== undefined ? source.defaultPrevented : 'returnValue' in source ? source.returnValue === false : source.getPreventDefault && source.getPreventDefault()) {
      event.isDefaultPrevented = returnTrue;
    }
  }
  return event;
}
function createProxy(event) {
  let key,
    proxy = {
      originalEvent: event,
    };
  for (key in event) {
    if (!ignoreProperties.test(key) && event[key] !== undefined) proxy[key] = event[key];
  }

  return compatible(proxy, event);
}
/*eslint-enable */

// 工具对象
const Helper = {
  ua,
  os: /(?:iphone|ipad|ipod)/.test(ua) ? 'ios' : /(?:android|adr )/.test(ua) ? 'android' : 'other',
  // 014-12-23 马超 增加相同命令发送间隔不能低于500ms，防止彩票客户端在iscroll内多次触发命令的bug
  sendCmd(cmd, type) {
    if (Helper.__lastCmd === cmd) {
      return;
    }
    Helper.__lastCmd = cmd;
    window.setTimeout(() => {
      Helper.__lastCmd = '';
    }, 500);
    // 修改location方式发送命令
    if (type === 'href') {
      window.location.href = cmd;
      return;
    }
    // 默认iframe方式发送命令
    let iframe = document.getElementById('__cmdFrame');
    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.id = '__cmdFrame';
      document.body.appendChild(iframe);
      iframe.style.display = 'none';
    }
    iframe.src = cmd;
  },

  /**
   * @name AppCore.helper.loadTextCss
   * @description 加载文本格式的CSS样式表
   * @function
   */
  loadTextCss(cssText) {
    if (!cssText) return;
    const style = document.createElement('style');
    const head = document.head || document.getElementsByTagName('head')[0];
    const textNode = document.createTextNode(cssText);
    head.appendChild(style);
    style.type = 'text/css';
    style.appendChild(textNode);
  },

  /**
   * @name AppCore.helper.loadJS
   * @description 加载单个JS文件(utf-8)，并提供回调
   * @function
   */
  loadJS(js, callback) {
    let head = document.getElementsByTagName('head')[0] || document.documentElement || document.body;
    let tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.charset = 'UTF-8';
    let done = false;
    tag.onload = tag.onreadystatechange = function() {
      if (
        !done &&
        (!this.readyState ||
          {
            loaded: 1,
            complete: 1,
          }[this.readyState])
      ) {
        // 重置状态
        done = true;
        tag.onload = tag.onreadystatechange = null;
        this.parentNode.removeChild(this);
        callback && callback();
        // 释放引用，内存回收
        head = tag = null;
      }
    };
    tag.src = js;
    head.appendChild(tag, head.lastChild);
  },

  /**
   * @name AppCore.helper.format
   * @description 模版格式化
   * @function
   */
  format(string, source) {
    if (source === undefined || source === null) {
      return string;
    }
    let isArray = true;
    const type = Object.prototype.toString.call(source);
    // 检测数据源
    const data = type === '[object Object]' ? ((isArray = false), source) : type === '[object Array]' ? source : Array.prototype.slice.call(arguments, 1);
    const N = isArray ? data.length : 0;
    // 执行替换
    // eslint-disable-next-line no-useless-escape
    return String(string).replace(/\{([\w\.]+)\}/g, (match, index) => {
      const isNumber = /^\d+$/.test(index);
      let n;
      let val;
      if (isNumber && isArray) {
        n = parseInt(index, 10);
        return n < N ? data[n] : match;
      }
      // 数据源为对象，则遍历逐级查找数据
      const fnPath = index.split('.');
      val = data;
      for (let i = 0; i < fnPath.length; i++) {
        val = val[fnPath[i]];
      }
      return val === undefined ? match : val;
    });
  },

  /**
   * 检测App的build号，仅内部使用
   * build是app的build号码 或者想要检测的app的环境，比如 caipiao
   * osConf: {
   * 	os: 1,
   *  android: 2
   * }
   * 通过比较确定版本号码的大小
   */
  checkBuild(build, osConf) {
    if (typeof build === 'string' && AppCore[build]) {
      build = AppCore[build].version();
    }
    return build >= (osConf[this.os] || Infinity);
  },
  /**
   * 是否是一个函数
   */
  isFunction(fun) {
    return typeof fun === 'function';
  },
  isArray(arr) {
    return arr && arr.length && Object.prototype.toString.call(arr) === '[object Array]';
  },
  /**
   * dom是否准备好
   * @param {function} callback
   */
  ready(callback) {
    const readyReg = /complete|loaded|interactive/;
    if (readyReg.test(document.readyState) && document.body) {
      callback();
    } else {
      document.addEventListener('DOMContentLoaded', callback, false);
    }
  },
  extend(target, ...args) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }
    target = Object(target);
    for (let index = 0; index < args.length; index++) {
      const source = args[index];
      if (source != null) {
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  },
  // 根据给定元素查找该select是否匹配
  matches(el, selector) {
    if (!el || el.nodeType !== 1) {
      return false;
    }
    if (vendor) {
      return vendor.call(el, selector);
    }
    // 不支持原生的match，则修复下
    // 注意修复的性能比较低
    const nodes = el.parentNode.querySelectorAll(selector);
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i] === el) return true;
    }
    return false;
  },
  closest(element, selector, checkYoSelf) {
    let parent = checkYoSelf ? element : element.parentNode;
    while (parent && parent !== document) {
      if (Helper.matches(parent, selector)) {
        return parent;
      }
      parent = parent.parentNode;
    }
  },
  // 简单事件代理，一次只能代理一个element
  delegate(element, type, selector, callback) {
    if (element && element.nodeType === 1 && typeof type === 'string' && typeof selector === 'string' && Helper.isFunction(callback)) {
      const listenerFn = listener.apply(element, arguments);
      if (!element.__cjx__event__id) {
        element.__cjx__event__id = +new Date();
      }
      const id = element.__cjx__event__id;
      EvtCache[id] = EvtCache[id] || [];
      EvtCache[id].push({
        callback,
        listenerFn,
        selector,
        type,
      });
      element.addEventListener(type, listenerFn, false);
    }
  },
  // 简单代理卸载
  undelegate(element, type, selector, callback) {
    if (element && element.__cjx__event__id && EvtCache[element.__cjx__event__id] && typeof type === 'string') {
      const caches = EvtCache[element.__cjx__event__id];
      if (Helper.isFunction(selector)) {
        callback = selector;
        selector = null;
      }
      caches.forEach((cache, index) => {
        if (!cache.type === type) {
          return;
        }
        if (typeof selector === 'string' && !cache.selector === selector) {
          return;
        }
        if (Helper.isFunction(callback) && !cache.callback === callback) {
          return;
        }
        element.removeEventListener(type, caches[index].listenerFn);
        delete caches.splice(index, 1);
        delete element.__cjx__event__id;
        if (!caches.length) {
          delete EvtCache[element.__cjx__event__id];
        }
      });
    }
  },
  prepend(node, childNode) {
    if (!childNode || (typeof childNode !== 'string' && childNode.nodeType !== 1)) {
      return;
    }
    if (!node || node.nodeType !== 1) {
      return;
    }
    if (!node.childNodes.length) {
      return Helper.append(node, childNode);
    }
    if (typeof childNode === 'string') {
      const div = document.createElement('div');
      div.innerHTML = childNode;
      const fragment = document.createDocumentFragment();
      const nodes = Array.prototype.slice.call(div.childNodes, 0);
      nodes.forEach(node => {
        fragment.appendChild(node);
      });
      node.insertBefore(fragment, node.childNodes[0]);
    } else if (childNode) {
      node.insertBefore(childNode, node.childNodes[0]);
    }
  },
  // 插入节点
  append(node, childNode) {
    if (!childNode || (typeof childNode !== 'string' && childNode.nodeType !== 1)) {
      return;
    }
    if (!node || node.nodeType !== 1) {
      return;
    }
    if (typeof childNode === 'string') {
      const div = document.createElement('div');
      div.innerHTML = childNode;
      const fragment = document.createDocumentFragment();
      const nodes = Array.prototype.slice.call(div.childNodes, 0);
      nodes.forEach(node => {
        fragment.appendChild(node);
      });
      node.appendChild(fragment);
    } else if (childNode) {
      node.appendChild(childNode);
    }
  },
  /**
   * @param {string|node|array[node]} selectors  querySelector方法的选择器
   */
  remove(selector) {
    let doms;
    if (!selector) {
      return;
    }
    if (typeof selector === 'string') {
      doms = document.body.querySelectorAll(selector);
    } else if (selector.nodeType) {
      doms = [selector];
    } else if (selector.length) {
      doms = Array.prototype.slice.call(selector, 0);
    }
    if (doms) {
      const nodes = Array.prototype.slice.call(doms, 0);
      nodes.forEach(dom => {
        if (dom && dom.parentNode) {
          dom.parentNode.removeChild(dom);
        }
      });
    }
  },
  addClass(el, cls) {
    if (!el || !el.nodeType === 1) {
      return;
    }
    /* istanbul ignore if */
    if (!cls || !cls.trim()) {
      return;
    }

    /* istanbul ignore else */
    if (el.classList) {
      if (cls.indexOf(' ') > -1) {
        cls.split(/\s+/).forEach(c => el.classList.add(c));
      } else {
        el.classList.add(cls);
      }
    } else {
      const cur = ' ' + el.getAttribute('class') + ' ';
      if (cur.indexOf(' ' + cls + ' ') < 0) {
        el.setAttribute('class', (cur + cls).trim());
      }
    }
  },
  removeClass(el, cls) {
    if (!el || !el.nodeType === 1) {
      return;
    }
    /* istanbul ignore if */
    if (!cls || !cls.trim()) {
      return;
    }
    /* istanbul ignore else */
    if (el.classList) {
      if (cls.indexOf(' ') > -1) {
        cls.split(/\s+/).forEach(c => el.classList.remove(c));
      } else {
        el.classList.remove(cls);
      }
    } else {
      let cur = ' ' + el.getAttribute('class') + ' ';
      const tar = ' ' + cls + ' ';
      while (cur.indexOf(tar) >= 0) {
        cur = cur.replace(tar, ' ');
      }
      el.setAttribute('class', cur.trim());
    }
  },
  findIndex(array, fun) {
    if (!Helper.isFunction(fun) || Helper.isArray(fun)) {
      return -1;
    }
    if (Array.prototype.findIndex) {
      return Array.prototype.findIndex.call(array, fun);
    }
    for (let i = 0, l = array.length; i < l; i++) {
      if (fun.call(array, array[i], i)) {
        return i;
      }
    }
    return -1;
  },
};
// 创建AppCore公共对象
export { AppCore };
AppCore.helper = Helper;
export default Helper;

// array indexOf, forEach
