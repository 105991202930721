import Apps from './core';
import Helper from './helper';

/**
 * @name  AppCore.login
 * @param {string} [url=document.URL] 登录成功后要返回的地址
 * @param {function} [success=noop] 登录成功回调，未必会触发，依赖于执行环境
 * @param {function} [fail=noop] 登录失败或取消回调，未必会触发，依赖于执行环境
 * @description AppCore登录模块统一接口，依赖其他子模块挂接，优先调用内嵌App的登录功能
 * @function
 *
 * @example
 * AppCore.login();
 *
 * @example
 * AppCore.login(document.URL, function(data){
 *     //如果有回调，则不会主动跳转到 document.URL
 *     //data未必会有值，而且不同的执行环境，内容不一样
 *     alert("登录成功");
 * },function(reason){
 *     var text = {
 *         cancel : "取消",
 *         fail : "失败"
 *     }[reason] || "错误";
 *     alert("登录"+ text);
 * });
 */
Apps.regHook('login', {
  defaultFn() {
    console.log('当前环境不支持登录');
  },
  run(fn, appName, cache, url, success, fail) {
    // 检查可选参数
    if (Helper.isFunction(url)) {
      fail = success;
      success = url;
      url = 0;
    }
    // 2015-11-09 增加url数组处理
    let okUrl = url || document.URL;
    let cancelUrl;
    if (Helper.isArray(url)) {
      okUrl = url[0];
      cancelUrl = url[1] || '';
    }
    const callbacks = {
      jump(url) {
        if (window.location.href === url) {
          return window.location.reload(true);
        }
        window.location.href = url;
      },
      success: Helper.isFunction(success)
        ? success
        : function() {
            callbacks.jump(okUrl);
          },
      fail: Helper.isFunction(fail)
        ? fail
        : function() {
            cancelUrl && callbacks.jump(cancelUrl);
          },
    };
    // 调用登录模块
    fn(okUrl, cancelUrl, callbacks.success, callbacks.fail);
  },
});
