/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/** eslint disabled */

/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.nldmessage.MapItem', null, global);
goog.exportSymbol('proto.nldmessage.UserOptionalMsg', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nldmessage.UserOptionalMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nldmessage.UserOptionalMsg.repeatedFields_, null);
};
goog.inherits(proto.nldmessage.UserOptionalMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.nldmessage.UserOptionalMsg.displayName = 'proto.nldmessage.UserOptionalMsg';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nldmessage.UserOptionalMsg.repeatedFields_ = [10];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.nldmessage.UserOptionalMsg.prototype.toObject = function(opt_includeInstance) {
    return proto.nldmessage.UserOptionalMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.nldmessage.UserOptionalMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.nldmessage.UserOptionalMsg.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        eventname: jspb.Message.getField(msg, 1),
        actionParam: jspb.Message.getField(msg, 2),
        itemId: jspb.Message.getField(msg, 3),
        extra: jspb.Message.getField(msg, 4),
        extra1: jspb.Message.getField(msg, 5),
        extra2: jspb.Message.getField(msg, 6),
        extra3: jspb.Message.getField(msg, 7),
        label: jspb.Message.getField(msg, 8),
        refer: jspb.Message.getField(msg, 9),
        itemList: jspb.Message.toObjectList(msg.getItemList(), proto.nldmessage.MapItem.toObject, includeInstance),
        extra4: jspb.Message.getField(msg, 11),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nldmessage.UserOptionalMsg}
 */
proto.nldmessage.UserOptionalMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nldmessage.UserOptionalMsg();
  return proto.nldmessage.UserOptionalMsg.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nldmessage.UserOptionalMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nldmessage.UserOptionalMsg}
 */
proto.nldmessage.UserOptionalMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventname(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setActionParam(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setItemId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setExtra(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setExtra1(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setExtra2(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setExtra3(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setLabel(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setRefer(value);
        break;
      case 10:
        var value = new proto.nldmessage.MapItem();
        reader.readMessage(value, proto.nldmessage.MapItem.deserializeBinaryFromReader);
        msg.addItem(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setExtra4(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nldmessage.UserOptionalMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nldmessage.UserOptionalMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nldmessage.UserOptionalMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nldmessage.UserOptionalMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(2, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(3, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(4, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(5, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(6, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(7, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(8, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(9, f);
  }
  f = message.getItemList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(10, f, proto.nldmessage.MapItem.serializeBinaryToWriter);
  }
};

/**
 * required string eventName = 1;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getEventname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setEventname = function(value) {
  jspb.Message.setField(this, 1, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearEventname = function() {
  jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasEventname = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * required string actionParam = 2;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getActionParam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setActionParam = function(value) {
  jspb.Message.setField(this, 2, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearActionParam = function() {
  jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasActionParam = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * required string itemId = 3;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setItemId = function(value) {
  jspb.Message.setField(this, 3, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearItemId = function() {
  jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasItemId = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * required string extra = 4;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getExtra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setExtra = function(value) {
  jspb.Message.setField(this, 4, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearExtra = function() {
  jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasExtra = function() {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * required string extra1 = 5;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getExtra1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setExtra1 = function(value) {
  jspb.Message.setField(this, 5, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearExtra1 = function() {
  jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasExtra1 = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * required string extra2 = 6;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getExtra2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setExtra2 = function(value) {
  jspb.Message.setField(this, 6, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearExtra2 = function() {
  jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasExtra2 = function() {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * required string extra3 = 7;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getExtra3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setExtra3 = function(value) {
  jspb.Message.setField(this, 7, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearExtra3 = function() {
  jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasExtra3 = function() {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * required string label = 8;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setLabel = function(value) {
  jspb.Message.setField(this, 8, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearLabel = function() {
  jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * required string refer = 9;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getRefer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setRefer = function(value) {
  jspb.Message.setField(this, 9, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearRefer = function() {
  jspb.Message.setField(this, 9, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasRefer = function() {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * repeated MapItem item = 10;
 * @return {!Array<!proto.nldmessage.MapItem>}
 */
proto.nldmessage.UserOptionalMsg.prototype.getItemList = function() {
  return /** @type{!Array<!proto.nldmessage.MapItem>} */ (jspb.Message.getRepeatedWrapperField(this, proto.nldmessage.MapItem, 10));
};

/** @param {!Array<!proto.nldmessage.MapItem>} value */
proto.nldmessage.UserOptionalMsg.prototype.setItemList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 10, value);
};

/**
 * @param {!proto.nldmessage.MapItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nldmessage.MapItem}
 */
proto.nldmessage.UserOptionalMsg.prototype.addItem = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.nldmessage.MapItem, opt_index);
};

proto.nldmessage.UserOptionalMsg.prototype.clearItemList = function() {
  this.setItemList([]);
};

/**
 * required string extra4 = 11;
 * @return {string}
 */
proto.nldmessage.UserOptionalMsg.prototype.getExtra4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};

/** @param {string} value */
proto.nldmessage.UserOptionalMsg.prototype.setExtra4 = function(value) {
  jspb.Message.setField(this, 11, value);
};

proto.nldmessage.UserOptionalMsg.prototype.clearExtra4 = function() {
  jspb.Message.setField(this, 11, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.UserOptionalMsg.prototype.hasExtra4 = function() {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nldmessage.MapItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nldmessage.MapItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.nldmessage.MapItem.displayName = 'proto.nldmessage.MapItem';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.nldmessage.MapItem.prototype.toObject = function(opt_includeInstance) {
    return proto.nldmessage.MapItem.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.nldmessage.MapItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.nldmessage.MapItem.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        key: jspb.Message.getField(msg, 1),
        value: jspb.Message.getField(msg, 2),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nldmessage.MapItem}
 */
proto.nldmessage.MapItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nldmessage.MapItem();
  return proto.nldmessage.MapItem.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nldmessage.MapItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nldmessage.MapItem}
 */
proto.nldmessage.MapItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nldmessage.MapItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nldmessage.MapItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nldmessage.MapItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nldmessage.MapItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(2, f);
  }
};

/**
 * required string key = 1;
 * @return {string}
 */
proto.nldmessage.MapItem.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/** @param {string} value */
proto.nldmessage.MapItem.prototype.setKey = function(value) {
  jspb.Message.setField(this, 1, value);
};

proto.nldmessage.MapItem.prototype.clearKey = function() {
  jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.MapItem.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * required string value = 2;
 * @return {string}
 */
proto.nldmessage.MapItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/** @param {string} value */
proto.nldmessage.MapItem.prototype.setValue = function(value) {
  jspb.Message.setField(this, 2, value);
};

proto.nldmessage.MapItem.prototype.clearValue = function() {
  jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.nldmessage.MapItem.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};

goog.object.extend(exports, proto.nldmessage);
