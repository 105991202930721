const countryList = [
  { alpha2Code: 'SA', phoneCode: '966', name: 'Saudi Arabia', nameAr: 'المملكة العربية السعودية', flag: '🇸🇦' },
  { alpha2Code: 'AO', phoneCode: '244', name: 'Angola', flag: '🇦🇴' },
  { alpha2Code: 'AF', phoneCode: '93', name: 'Afghanistan', flag: '🇦🇫' },
  { alpha2Code: 'AL', phoneCode: '355', name: 'Albania', flag: '🇦🇱' },
  { alpha2Code: 'DZ', phoneCode: '213', name: 'Algeria', flag: '🇩🇿' },
  { alpha2Code: 'AD', phoneCode: '376', name: 'Andorra', flag: '🇦🇩' },
  { alpha2Code: 'AI', phoneCode: '1264', name: 'Anguilla', flag: '🇦🇮' },
  { alpha2Code: 'AG', phoneCode: '1268', name: 'Antigua and Barbuda', flag: '🇦🇬' },
  { alpha2Code: 'AR', phoneCode: '54', name: 'Argentina', flag: '🇦🇷' },
  { alpha2Code: 'AM', phoneCode: '374', name: 'Armenia', flag: '🇦🇲' },
  { alpha2Code: 'SH', phoneCode: '247', name: 'Saint Helena, Ascension and Tristan da Cunha', flag: '🇸🇭' },
  { alpha2Code: 'AU', phoneCode: '61', name: 'Australia', flag: '🇦🇺' },
  { alpha2Code: 'AT', phoneCode: '43', name: 'Austria', flag: '🇦🇹' },
  { alpha2Code: 'AZ', phoneCode: '994', name: 'Azerbaijan', flag: '🇦🇿' },
  { alpha2Code: 'BS', phoneCode: '1242', name: 'Bahamas', flag: '🇧🇸' },
  { alpha2Code: 'BH', phoneCode: '973', name: 'Bahrain', nameAr: 'البحرين', flag: '🇧🇭' },
  { alpha2Code: 'BD', phoneCode: '880', name: 'Bangladesh', flag: '🇧🇩' },
  { alpha2Code: 'BB', phoneCode: '1246', name: 'Barbados', flag: '🇧🇧' },
  { alpha2Code: 'BY', phoneCode: '375', name: 'Belarus', flag: '🇧🇾' },
  { alpha2Code: 'BE', phoneCode: '32', name: 'Belgium', flag: '🇧🇪' },
  { alpha2Code: 'BZ', phoneCode: '501', name: 'Belize', flag: '🇧🇿' },
  { alpha2Code: 'BJ', phoneCode: '229', name: 'Benin', flag: '🇧🇯' },
  { alpha2Code: 'BM', phoneCode: '1441', name: 'Bermuda', flag: '🇧🇲' },
  { alpha2Code: 'BO', phoneCode: '591', name: 'Bolivia (Plurinational State of)', flag: '🇧🇴' },
  { alpha2Code: 'BW', phoneCode: '267', name: 'Botswana', flag: '🇧🇼' },
  { alpha2Code: 'BR', phoneCode: '55', name: 'Brazil', flag: '🇧🇷' },
  { alpha2Code: 'BN', phoneCode: '673', name: 'Brunei Darussalam', flag: '🇧🇳' },
  { alpha2Code: 'BG', phoneCode: '359', name: 'Bulgaria', flag: '🇧🇬' },
  { alpha2Code: 'BF', phoneCode: '226', name: 'Burkina Faso', flag: '🇧🇫' },
  { alpha2Code: 'MM', phoneCode: '95', name: 'Myanmar', flag: '🇲🇲' },
  { alpha2Code: 'BI', phoneCode: '257', name: 'Burundi', flag: '🇧🇮' },
  { alpha2Code: 'CM', phoneCode: '237', name: 'Cameroon', flag: '🇨🇲' },
  { alpha2Code: 'CA', phoneCode: '1', name: 'Canada', flag: '🇨🇦' },
  { alpha2Code: 'KY', phoneCode: '1345', name: 'Cayman Islands', flag: '🇰🇾' },
  { alpha2Code: 'CF', phoneCode: '236', name: 'Central African Republic', flag: '🇨🇫' },
  { alpha2Code: 'TD', phoneCode: '235', name: 'Chad', flag: '🇹🇩' },
  { alpha2Code: 'CL', phoneCode: '56', name: 'Chile', flag: '🇨🇱' },
  { alpha2Code: 'CN', phoneCode: '86', name: 'China', flag: '🇨🇳' },
  { alpha2Code: 'TW', phoneCode: '886', name: 'TaiWan', flag: '' },
  { alpha2Code: 'CO', phoneCode: '57', name: 'Colombia', flag: '🇨🇴' },
  { alpha2Code: 'CG', phoneCode: '242', name: 'Congo', flag: '🇨🇬' },
  { alpha2Code: 'CK', phoneCode: '682', name: 'Cook Islands', flag: '🇨🇰' },
  { alpha2Code: 'CR', phoneCode: '506', name: 'Costa Rica', flag: '🇨🇷' },
  { alpha2Code: 'CU', phoneCode: '53', name: 'Cuba', flag: '🇨🇺' },
  { alpha2Code: 'CY', phoneCode: '357', name: 'Cyprus', flag: '🇨🇾' },
  { alpha2Code: 'CZ', phoneCode: '420', name: 'Czechia', flag: '🇨🇿' },
  { alpha2Code: 'DK', phoneCode: '45', name: 'Denmark', flag: '🇩🇰' },
  { alpha2Code: 'DJ', phoneCode: '253', name: 'Djibouti', flag: '🇩🇯' },
  { alpha2Code: 'DO', phoneCode: '1890', name: 'Dominican Republic', flag: '🇩🇴' },
  { alpha2Code: 'EC', phoneCode: '593', name: 'Ecuador', flag: '🇪🇨' },
  { alpha2Code: 'EG', phoneCode: '20', name: 'Egypt', nameAr: 'مصر', flag: '🇪🇬' },
  { alpha2Code: 'SV', phoneCode: '503', name: 'El Salvador', flag: '🇸🇻' },
  { alpha2Code: 'EE', phoneCode: '372', name: 'Estonia', flag: '🇪🇪' },
  { alpha2Code: 'ET', phoneCode: '251', name: 'Ethiopia', flag: '🇪🇹' },
  { alpha2Code: 'FJ', phoneCode: '679', name: 'Fiji', flag: '🇫🇯' },
  { alpha2Code: 'FI', phoneCode: '358', name: 'Finland', flag: '🇫🇮' },
  { alpha2Code: 'FR', phoneCode: '33', name: 'France', flag: '🇫🇷' },
  { alpha2Code: 'GF', phoneCode: '594', name: 'French Guiana', flag: '🇬🇫' },
  { alpha2Code: 'GA', phoneCode: '241', name: 'Gabon', flag: '🇬🇦' },
  { alpha2Code: 'GM', phoneCode: '220', name: 'Gambia', flag: '🇬🇲' },
  { alpha2Code: 'GE', phoneCode: '995', name: 'Georgia', flag: '🇬🇪' },
  { alpha2Code: 'DE', phoneCode: '49', name: 'Germany', flag: '🇩🇪' },
  { alpha2Code: 'GH', phoneCode: '233', name: 'Ghana', flag: '🇬🇭' },
  { alpha2Code: 'GI', phoneCode: '350', name: 'Gibraltar', flag: '🇬🇮' },
  { alpha2Code: 'GR', phoneCode: '30', name: 'Greece', flag: '🇬🇷' },
  { alpha2Code: 'GD', phoneCode: '1809', name: 'Grenada', flag: '🇬🇩' },
  { alpha2Code: 'GU', phoneCode: '1671', name: 'Guam', flag: '🇬🇺' },
  { alpha2Code: 'GT', phoneCode: '502', name: 'Guatemala', flag: '🇬🇹' },
  { alpha2Code: 'GN', phoneCode: '224', name: 'Guinea', flag: '🇬🇳' },
  { alpha2Code: 'GY', phoneCode: '592', name: 'Guyana', flag: '🇬🇾' },
  { alpha2Code: 'HT', phoneCode: '509', name: 'Haiti', flag: '🇭🇹' },
  { alpha2Code: 'HN', phoneCode: '504', name: 'Honduras', flag: '🇭🇳' },
  { alpha2Code: 'HK', phoneCode: '852', name: 'Hong Kong', flag: '🇭🇰' },
  { alpha2Code: 'HU', phoneCode: '36', name: 'Hungary', flag: '🇭🇺' },
  { alpha2Code: 'IS', phoneCode: '354', name: 'Iceland', flag: '🇮🇸' },
  { alpha2Code: 'IN', phoneCode: '91', name: 'India', flag: '🇮🇳' },
  { alpha2Code: 'ID', phoneCode: '62', name: 'Indonesia', flag: '🇮🇩' },
  { alpha2Code: 'IR', phoneCode: '98', name: 'Iran (Islamic Republic of)', flag: '🇮🇷' },
  { alpha2Code: 'IQ', phoneCode: '964', name: 'Iraq', flag: '🇮🇶' },
  { alpha2Code: 'IE', phoneCode: '353', name: 'Ireland', flag: '🇮🇪' },
  { alpha2Code: 'IL', phoneCode: '972', name: 'Israel', flag: '🇮🇱' },
  { alpha2Code: 'IT', phoneCode: '39', name: 'Italy', flag: '🇮🇹' },
  { alpha2Code: 'CI', phoneCode: '225', name: "Côte d'Ivoire", flag: '🇨🇮' },
  { alpha2Code: 'JM', phoneCode: '1876', name: 'Jamaica', flag: '🇯🇲' },
  { alpha2Code: 'JP', phoneCode: '81', name: 'Japan', flag: '🇯🇵' },
  { alpha2Code: 'JO', phoneCode: '962', name: 'Jordan', nameAr: 'الأردن', flag: '🇯🇴' },
  { alpha2Code: 'KH', phoneCode: '855', name: 'Cambodia', flag: '🇰🇭' },
  { alpha2Code: 'KZ', phoneCode: '327', name: 'Kazakhstan', flag: '🇰🇿' },
  { alpha2Code: 'KE', phoneCode: '254', name: 'Kenya', flag: '🇰🇪' },
  { alpha2Code: 'KR', phoneCode: '82', name: 'Korea (Republic of)', flag: '🇰🇷' },
  { alpha2Code: 'KW', phoneCode: '965', name: 'Kuwait', nameAr: 'الكويت', flag: '🇰🇼' },
  { alpha2Code: 'KG', phoneCode: '331', name: 'Kyrgyzstan', flag: '🇰🇬' },
  { alpha2Code: 'LA', phoneCode: '856', name: "Lao People's Democratic Republic", flag: '🇱🇦' },
  { alpha2Code: 'LV', phoneCode: '371', name: 'Latvia', flag: '🇱🇻' },
  { alpha2Code: 'LB', phoneCode: '961', name: 'Lebanon', nameAr: 'لبنان', flag: '🇱🇧' },
  { alpha2Code: 'LS', phoneCode: '266', name: 'Lesotho', flag: '🇱🇸' },
  { alpha2Code: 'LR', phoneCode: '231', name: 'Liberia', flag: '🇱🇷' },
  { alpha2Code: 'LY', phoneCode: '218', name: 'Libya', flag: '🇱🇾' },
  { alpha2Code: 'LI', phoneCode: '423', name: 'Liechtenstein', flag: '🇱🇮' },
  { alpha2Code: 'LT', phoneCode: '370', name: 'Lithuania', flag: '🇱🇹' },
  { alpha2Code: 'LU', phoneCode: '352', name: 'Luxembourg', flag: '🇱🇺' },
  { alpha2Code: 'MO', phoneCode: '853', name: 'Macao', flag: '🇲🇴' },
  { alpha2Code: 'MG', phoneCode: '261', name: 'Madagascar', flag: '🇲🇬' },
  { alpha2Code: 'MW', phoneCode: '265', name: 'Malawi', flag: '🇲🇼' },
  { alpha2Code: 'MY', phoneCode: '60', name: 'Malaysia', flag: '🇲🇾' },
  { alpha2Code: 'MV', phoneCode: '960', name: 'Maldives', flag: '🇲🇻' },
  { alpha2Code: 'ML', phoneCode: '223', name: 'Mali', flag: '🇲🇱' },
  { alpha2Code: 'MT', phoneCode: '356', name: 'Malta', flag: '🇲🇹' },
  { alpha2Code: 'MP', phoneCode: '1670', name: 'Northern Mariana Islands', flag: '🇲🇵' },
  { alpha2Code: 'MQ', phoneCode: '596', name: 'Martinique', flag: '🇲🇶' },
  { alpha2Code: 'MU', phoneCode: '230', name: 'Mauritius', flag: '🇲🇺' },
  { alpha2Code: 'MX', phoneCode: '52', name: 'Mexico', flag: '🇲🇽' },
  { alpha2Code: 'MD', phoneCode: '373', name: 'Moldova (Republic of)', flag: '🇲🇩' },
  { alpha2Code: 'MC', phoneCode: '377', name: 'Monaco', flag: '🇲🇨' },
  { alpha2Code: 'MN', phoneCode: '976', name: 'Mongolia', flag: '🇲🇳' },
  { alpha2Code: 'MS', phoneCode: '1664', name: 'Montserrat', flag: '🇲🇸' },
  { alpha2Code: 'MA', phoneCode: '212', name: 'Morocco', flag: '🇲🇦' },
  { alpha2Code: 'MZ', phoneCode: '258', name: 'Mozambique', flag: '🇲🇿' },
  { alpha2Code: 'NA', phoneCode: '264', name: 'Namibia', flag: '🇳🇦' },
  { alpha2Code: 'NR', phoneCode: '674', name: 'Nauru', flag: '🇳🇷' },
  { alpha2Code: 'NP', phoneCode: '977', name: 'Nepal', flag: '🇳🇵' },
  { alpha2Code: 'NL', phoneCode: '31', name: 'Netherlands', flag: '🇳🇱' },
  { alpha2Code: 'NZ', phoneCode: '64', name: 'New Zealand', flag: '🇳🇿' },
  { alpha2Code: 'NI', phoneCode: '505', name: 'Nicaragua', flag: '🇳🇮' },
  { alpha2Code: 'NE', phoneCode: '227', name: 'Niger', flag: '🇳🇪' },
  { alpha2Code: 'NG', phoneCode: '234', name: 'Nigeria', flag: '🇳🇬' },
  { alpha2Code: 'KP', phoneCode: '850', name: "Korea (Democratic People's Republic of)", flag: '🇰🇵' },
  { alpha2Code: 'NO', phoneCode: '47', name: 'Norway', flag: '🇳🇴' },
  { alpha2Code: 'OM', phoneCode: '968', name: 'Oman', nameAr: 'عمان', flag: '🇴🇲' },
  { alpha2Code: 'PK', phoneCode: '92', name: 'Pakistan', flag: '🇵🇰' },
  { alpha2Code: 'PA', phoneCode: '507', name: 'Panama', flag: '🇵🇦' },
  { alpha2Code: 'PG', phoneCode: '675', name: 'Papua New Guinea', flag: '🇵🇬' },
  { alpha2Code: 'PY', phoneCode: '595', name: 'Paraguay', flag: '🇵🇾' },
  { alpha2Code: 'PE', phoneCode: '51', name: 'Peru', flag: '🇵🇪' },
  { alpha2Code: 'PH', phoneCode: '63', name: 'Philippines', flag: '🇵🇭' },
  { alpha2Code: 'PL', phoneCode: '48', name: 'Poland', flag: '🇵🇱' },
  { alpha2Code: 'PF', phoneCode: '689', name: 'French Polynesia', flag: '🇵🇫' },
  { alpha2Code: 'PT', phoneCode: '351', name: 'Portugal', flag: '🇵🇹' },
  { alpha2Code: 'PR', phoneCode: '1787', name: 'Puerto Rico', flag: '🇵🇷' },
  { alpha2Code: 'QA', phoneCode: '974', name: 'Qatar', nameAr: 'قطر', flag: '🇶🇦' },
  { alpha2Code: 'RE', phoneCode: '262', name: 'Réunion', flag: '🇷🇪' },
  { alpha2Code: 'RO', phoneCode: '40', name: 'Romania', flag: '🇷🇴' },
  { alpha2Code: 'RU', phoneCode: '7', name: 'Russian Federation', flag: '🇷🇺' },
  { alpha2Code: 'AS', phoneCode: '684', name: 'American Samoa', flag: '🇦🇸' },
  { alpha2Code: 'WS', phoneCode: '685', name: 'Samoa', flag: '🇼🇸' },
  { alpha2Code: 'SM', phoneCode: '378', name: 'San Marino', flag: '🇸🇲' },
  { alpha2Code: 'ST', phoneCode: '239', name: 'Sao Tome and Principe', flag: '🇸🇹' },
  { alpha2Code: 'SN', phoneCode: '221', name: 'Senegal', flag: '🇸🇳' },
  { alpha2Code: 'SC', phoneCode: '248', name: 'Seychelles', flag: '🇸🇨' },
  { alpha2Code: 'SL', phoneCode: '232', name: 'Sierra Leone', flag: '🇸🇱' },
  { alpha2Code: 'SG', phoneCode: '65', name: 'Singapore', flag: '🇸🇬' },
  { alpha2Code: 'SK', phoneCode: '421', name: 'Slovakia', flag: '🇸🇰' },
  { alpha2Code: 'SI', phoneCode: '386', name: 'Slovenia', flag: '🇸🇮' },
  { alpha2Code: 'SB', phoneCode: '677', name: 'Solomon Islands', flag: '🇸🇧' },
  { alpha2Code: 'SO', phoneCode: '252', name: 'Somalia', flag: '🇸🇴' },
  { alpha2Code: 'ZA', phoneCode: '27', name: 'South Africa', flag: '🇿🇦' },
  { alpha2Code: 'ES', phoneCode: '34', name: 'Spain', flag: '🇪🇸' },
  { alpha2Code: 'LK', phoneCode: '94', name: 'Sri Lanka', flag: '🇱🇰' },
  { alpha2Code: 'LC', phoneCode: '1758', name: 'Saint Lucia', flag: '🇱🇨' },
  { alpha2Code: 'VC', phoneCode: '1784', name: 'Saint Vincent and the Grenadines', flag: '🇻🇨' },
  { alpha2Code: 'SD', phoneCode: '249', name: 'Sudan', flag: '🇸🇩' },
  { alpha2Code: 'SR', phoneCode: '597', name: 'Suriname', flag: '🇸🇷' },
  { alpha2Code: 'SZ', phoneCode: '268', name: 'Eswatini', flag: '🇸🇿' },
  { alpha2Code: 'SE', phoneCode: '46', name: 'Sweden', flag: '🇸🇪' },
  { alpha2Code: 'CH', phoneCode: '41', name: 'Switzerland', flag: '🇨🇭' },
  { alpha2Code: 'SY', phoneCode: '963', name: 'Syrian Arab Republic', flag: '🇸🇾' },
  { alpha2Code: 'TJ', phoneCode: '992', name: 'Tajikistan', flag: '🇹🇯' },
  { alpha2Code: 'TZ', phoneCode: '255', name: 'Tanzania, United Republic of', flag: '🇹🇿' },
  { alpha2Code: 'TH', phoneCode: '66', name: 'Thailand', flag: '🇹🇭' },
  { alpha2Code: 'TG', phoneCode: '228', name: 'Togo', flag: '🇹🇬' },
  { alpha2Code: 'TO', phoneCode: '676', name: 'Tonga', flag: '🇹🇴' },
  { alpha2Code: 'TT', phoneCode: '1809', name: 'Trinidad and Tobago', flag: '🇹🇹' },
  { alpha2Code: 'TN', phoneCode: '216', name: 'Tunisia', flag: '🇹🇳' },
  { alpha2Code: 'TR', phoneCode: '90', name: 'Turkey', nameAr: 'تركيا', flag: '🇹🇷' },
  { alpha2Code: 'TM', phoneCode: '993', name: 'Turkmenistan', flag: '🇹🇲' },
  { alpha2Code: 'UG', phoneCode: '256', name: 'Uganda', flag: '🇺🇬' },
  { alpha2Code: 'UA', phoneCode: '380', name: 'Ukraine', flag: '🇺🇦' },
  { alpha2Code: 'AE', phoneCode: '971', name: 'United Arab Emirates', nameAr: 'الإمارات العربية المتحدة', flag: '🇦🇪' },
  { alpha2Code: 'GB', phoneCode: '44', name: 'United Kingdom of Great Britain and Northern Ireland', flag: '🇬🇧' },
  { alpha2Code: 'US', phoneCode: '1', name: 'United States of America', flag: '🇺🇸' },
  { alpha2Code: 'UY', phoneCode: '598', name: 'Uruguay', flag: '🇺🇾' },
  { alpha2Code: 'UZ', phoneCode: '998', name: 'Uzbekistan', flag: '🇺🇿' },
  { alpha2Code: 'VE', phoneCode: '58', name: 'Venezuela (Bolivarian Republic of)', flag: '🇻🇪' },
  { alpha2Code: 'VN', phoneCode: '84', name: 'Viet Nam', flag: '🇻🇳' },
  { alpha2Code: 'YE', phoneCode: '967', name: 'Yemen', flag: '🇾🇪' },
  // { alpha2Code: 'YU', phoneCode: '381' },
  { alpha2Code: 'ZW', phoneCode: '263', name: 'Zimbabwe', flag: '🇿🇼' },
  // { alpha2Code: 'ZR', phoneCode: '243' },
  { alpha2Code: 'ZM', phoneCode: '260', name: 'Zambia', flag: '🇿🇲' },
  { alpha2Code: 'PS', phoneCode: '970', name: 'State of Palestine', flag: '🇵🇸' },
  { alpha2Code: 'MR', phoneCode: '222', name: 'Mauritania', flag: '🇲🇷' },
  { alpha2Code: 'KM', phoneCode: '269', name: 'Union of Comoros', flag: '🇰🇲' },
  { alpha2Code: 'OTHERS', phoneCode: '', name: 'Others', nameAr: 'الآخرين', flag: '' },
];

export default countryList;

export const getCountryInfo = alpha2Code => {
  if (alpha2Code) {
    const c = countryList.find(item => item.alpha2Code.toLowerCase() === alpha2Code.toLowerCase());
    if (c) {
      return c;
    }
    return null;
  }
  return countryList[0];
};
