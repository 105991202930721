export default {
  'country.title': 'الدولة & اللغة',
  // 尼日利亚
  'country.ng': 'Nigeria',
  // 肯尼亚
  'country.ke.en': 'Kenya | English',
  'country.ke.sw': 'Kenya | Swahili',
  // 加纳
  'country.gh': 'Ghana',
  // 埃及
  'country.eg': 'مصر',
  // 沙特阿拉伯
  'country.sa': 'السعودية',
  // 科特迪瓦
  'country.ci': "Côte d'Ivoire",
  // 喀麦隆
  'country.cm': 'Cameroun',
  // 阿尔及利亚
  'country.dz': 'الجزائر',
  //坦桑尼亚
  'country.tz.en': 'Tanzania | English',
  'country.tz.sw': 'Tanzania | Swahili',
  // 塞内加尔
  'country.sn': 'Sénégal',
  // 赞比亚
  'country.zm': 'Zambia',
  // 几内亚
  'country.gn': 'Guinée',
  // 摩洛哥
  'country.ma.ar': 'المغرب | العربية',
  'country.ma.fr': 'Maroc | Français',
  // 南非
  'country.za': 'South Africa',
  // 刚果民主共和国
  'country.cd.fr': 'DR Congo | Français',
  'country.cd.sw': 'DR Congo | Swahili',
  // 乌干达
  'country.ug.en': 'Uganda | English',
  'country.ug.sw': 'Uganda | Swahili',
  // 布基纳法索
  'country.bf': 'Burkina Faso',
  // 贝宁
  'country.bj': 'Bénin',
  // 津巴布韦
  'country.zw': 'Zimbabwe',
  // 埃塞俄比亚
  'country.et': 'Ethiopia',
  // 利比亚
  'country.ly': 'ليبيا',
  // 突尼斯
  'country.tn.ar': 'تونس | العربية',
  'country.tn.fr': 'Tunisie | Français',
  // 马里
  'country.ml': 'Mali',
  // 尼日尔
  'country.ne': 'Niger',
  // 利比里亚
  'country.lr': 'Liberia',
  // 塞拉利昂
  'country.sl': 'Sierra Leone',
  // 马拉维
  'country.mw': 'Malawi',
  // 阿拉伯联合酋长国
  'country.ae': 'الامارات',
  // 卢旺达
  'country.rw.fr': 'Rwanda | Français',
  'country.rw.sw': 'Rwanda | Swahili',
  // 冈比亚
  'country.gm': 'Gambia',
  // 南苏丹
  'country.ss': 'South Sudan',
  // 布隆迪
  'country.bi': 'Burundi',
  // 马达加斯加岛
  'country.mg': 'Madagascar',
  // 查德
  'country.td': 'Tchad',
  // 多哥
  'country.tg': 'Togo',
  // 苏丹
  'country.sd': 'السودان',
  // 索马里
  'country.so': 'الصومال',
  // 毛里塔尼亚
  'country.mr': 'موريتانيا',
  // 吉布提
  'country.dj': 'جيبوتي',
  // 加蓬
  'country.ga': 'Gabon',
  // 国际站
  'country.global': 'Global | English',
};
