import { fetchData } from './api';

export const fetchDetailInfo = async params => fetchData('GET', '/api/user/userAccount/getDetailInfo', params);

export const fetchCoinBackDetail = async params => fetchData('GET', '/api/user/userAccount/getCoinBackDetail', params);

export const fetchDiamondBackDetail = async params => fetchData('GET', '/api/user/userAccount/getDiamondBackDetail', params);

export const fetchDiamondIncomeStatistics = async params => fetchData('GET', '/api/user/userAccount/getDiamondIncomeStatistics', params);

// 主播评价信息
export const fetchEvaluateInfo = async params => fetchData('GET', '/api/user/guild/evaluate/info', params);

// 主播聊天回合信息
export const fetchImRound = async params => fetchData('GET', '/api/message/im/im/round', params);

// 主播中心数据回复速度接口
export const fetchReplySpeed = async params => fetchData('GET', '/api/message/agency/anchor/reply/speed', params);

// 查询回复速度对应消息钻石收益规则
export const fetchDiamondIncomeReplySpeed = async params => fetchData('GET', '/api/message/diamondIncome/replySpeed', params);

// 查询回复速度对应消息钻石收益规则
export const fetchDiamondIncomeReplyDepth = async params => fetchData('GET', '/api/message/diamondIncome/replyDepth', params);

// 有效上麦时长统计
export const fetchEffectiveMic = async params => fetchData('GET', '/api/voice/content/effectiveMic/statistics', params);

export const fetchEffectiveMicdetails = async params => fetchData('GET', '/api/voice/content/effectiveMic/details/list', params);

export const fetchEffectiveDailyMicdetails = async params => fetchData('GET', '/api/voice/content/effectiveMic/daily/list', params);

export const fetchEffectiveMicConfig = async params => fetchData('GET', '/api/voice/content/effectiveMic/config', params);

export const interactionInfo = async params => fetchData('GET', '/api/message/agency/anchor/interaction/info', params);
