import { luckyGift } from '@/services/api';
import { Toast } from 'antd-mobile';

const LuckyGiftList = {
  namespace: 'luckyGiftList',
  state: {
    list: []
  },
  reducers: {
    updateList(state, { payload }) {
      console.log(payload, 'dncj')
      return {
        ...state,
        list: payload,
      }
    }
  },
  effects: {
    *getList({ payload }, { call, put }) {
      const { error, data } = yield call(luckyGift, payload);
      if (error || !data) {
        yield put({
          type: 'updateList',
          payload: [],
        });
        Toast.show({
          content: error,
        });
        return false;
      }
      yield put({
        type: 'updateList',
        payload: data,
      });
      return true;
    },
  }
};

export default LuckyGiftList;
