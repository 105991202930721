import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {initCountry} from '@/asset/config/country'
import {I18n} from '@/asset/js/i18n'
// import './index.css';
import App from './App';
import store from './store'
import './asset/styles/index.less';
import './asset/js/axiosConfig'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// initCountry()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // 严格模式会导致组件多渲染一次
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <I18n component={App} />
           {/* <App /> */}
        </BrowserRouter>
    </Provider>


  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
