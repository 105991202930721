import { reportRoom, reportUser } from '@/services/api';

const report = {
  namespace: 'report',
  state: {},
  reducers: {},
  effects: {
    *submit({ payload }, { call }) {
      const api = payload.type === 'room' ? reportRoom : reportUser;
      const { error } = yield call(
        api,
        payload.type === 'room'
          ? {
              roomId: payload.id,
              reason: payload.reason,
              details: payload.details,
              screenShots: payload.screenShots.join(','),
              reportSource: payload.reportSource,
            }
          : {
              terms: 4,
              details: payload.details,
              screenShots: payload.screenShots.join(','),
              targetId: payload.id,
              reason: payload.reason,
              reportSource: payload.reportSource,
            }
      );
      if (error) {
        return error;
      }
      return true;
    },
  },
};

export default report;
