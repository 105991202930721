const ID = {
  'recharge.result.pending': 'Operasi selesai',
  'recharge.result.tips': 'Jika koin tidak tiba tepat waktu, jangan khawatir, mungkin ada penundaan jaringan. Jika Anda memiliki pertanyaan, silakan hubungi layanan pelanggan resmi SOYO.',
  'recharge.result.tips.click': 'Klik untuk kembali ke APP',
  '1': 'Januari',
  '2': 'Februari',
  '3': 'Maret',
  '4': 'April',
  '5': 'Mei',
  '6': 'Juni',
  '7': 'Juli',
  '8': 'Agustus',
  '9': 'September',
  '10': 'Oktober',
  '11': 'November',
  '12': 'Desember',
  'coin.recharge.modal.title': 'Metode Pembayaran',
  'coin.recharge.change.country': 'Pilih negara',
  'coin.recharge.current.country': 'Saat ini menggunakan saluran pembayaran',
  'ajax.timeout': 'Maaf, ada yang tidak beres. Silakan coba lagi nanti',
  'ajax.system': 'Maaf, ada yang tidak beres. Silakan coba lagi nanti',
  'ajax.network': 'Tidak ada koneksi jaringan. Silakan coba lagi nanti.',
  blank: '<p>Tidak ada konten terkait</p> <p>Silakan periksa saluran lain</p>',
  'pull.refresh.deactivate': 'Tarik untuk menyegarkan',
  'pull.refresh.activate': 'Rilis untuk menyegarkan',
  'pull.refresh.release': 'Memuat lebih banyak',
  'push.refresh.activate': 'Memuat lebih banyak',
  'push.refresh.noMore': 'Tidak ada info lebih lanjut',
  'click.refresh': 'Klik untuk menyegarkan',
  'share.whatsapp.tip': 'WhatsApp mungkin tidak diinstal pada telepon Anda, instal dan coba lagi.',
  'share.ok': 'OK',
  'share.install': 'Instal Soundi Radio APP Sekarang',
  'share.download': 'Unduh',
  'share.play': 'Putar di Soundi Radio',
  'share.live': 'Live',
  'expection.oops': 'UPS...',
  'expection.homeBtn': 'Halaman Utama',
  'ERR500.tips': 'Ada yang tidak beres. Pengembang telah diberitahu tentang hal ini dan akan memperbaiki masalah ini. Silakan dicoba lagi nanti. Terima kasih.',
  'err404.tips': 'Berita yang Anda cari tidak ada. Silakan periksa topik lainnya. Kami menunggumu di sana!',
  commonErr: '<p>Sistem sibuk,</p> <p>silakan coba lagi nanti</p>',
  loading: 'Memuat...',
  retry: 'Ulang',
  viewMore: 'Lihat lebih banyak',
  more: 'lebih banyak',
  'month.select': 'Pilih Bulan',
  hot: 'Hot',
  all: 'Semua',
  'month.select.day': 'Pilih Tanggal',
  'coin.merchant.title': 'CoinSeller',
  'coin.merchant.set.password': 'Tetapkan kata sandi trading Anda',
  'coin.merchant.confirm.again': 'Konfirmasi kembali password transaksi',
  'coin.merchant.enter.password': 'Masukkan kata sandi transaksi Anda',
  'coin.merchant.enter.original.password': 'Masukkan kata sandi asli',
  'coin.merchant.enter.new.password': 'Masukkan kata sandi baru',
  'coin.merchant.confirm.new.again': 'Konfirmasikan kata sandi baru lagi',
  'coin.merchant.change.password': 'Perubahan\nkata sandi',
  'coin.merchant.history.title': 'Transaksi\nSejarah',
  'coin.merchant.history': 'Sejarah',
  'coin.merchant.user': 'Pengguna',
  'coin.merchant.chat': 'Chat',
  'coin.merchant.coins.num': 'Inventaris Koin:',
  'coin.merchant.transfer.coins': 'Transfer Koin',
  'coin.merchant.enter.user.id': 'Masukkan user id',
  'coin.merchant.search': 'Inquiry',
  'coin.merchant.not.found': 'Pengguna tidak ditemukan',
  'coin.merchant.enter.amount': 'Masukkan jumlah',
  'coin.merchant.tips':
    'Anda akan mentransfer jumlah tambahan kepada pengguna, harap konfirmasi nama dan jumlah koin lagi. Operasi ini tidak dapat diubah, dan pejabat tersebut tidak bertanggung jawab atas kesalahan operasi apa pun.',
  'coin.merchant.enter.transaction.password': 'Masukkan password transaksi',
  'common.cap.Confirm': 'Konfirmasi',
  'coin.merchant.enter': 'Masuk',
  'coin.merchant.to': 'Kepada:',
  'coin.merchant.amount': 'Jumlah:',
  'coin.merchant.id': 'ID',
  'coin.merchant.password.tips': 'Kata sandi harus minimal 8 karakter, dan harus terdiri dari huruf kapital dan huruf kecil dan angka.',
  'coin.merchant.password.error': 'Kata Sandi Salah.Silakan coba lagi.',
  'coin.merchant.success': 'Pengiriman uang berhasil',
  'coin.merchant.list.title': 'Pilih jumlah',
  'coin.merchant.list.desc': 'Transfer koin ke pengguna ini setara dengan isi ulang, yang juga akan meningkatkan pengalaman vip.',
  'coin.merchant.transfer': 'Transfer',
  'coin.merchant.recharge.title': 'Penjual koin\nIsi ulang',
  'coin.merchant.min.input': 'Jumlah minimum 1000',
  'coin.merchant.der.channel': 'Cara Pembayaran:',
  'coin.merchant.der.channel.tips': 'Jika Anda tidak dapat menyelesaikan pembayaran melalui metode berikut, silakan hubungi WhatsApp tim resmi untuk menanyakan tentang pembelian:',
  'coin.merchant.der.channel.tips.link': 'Salin',
  'coin.merchant.der.current.country': 'Saat ini menggunakan saluran pembayaran',
  'coin.merchant.der.change.country': 'Pilih negara',
  'coin.merchant.der.modal.title': 'Metode Pembayaran',
  'common.cap.Recharge': 'Isi ulang',
  'coin.merchant.der.id': 'TalkTalk ID',
  'coin.merchant.der.name': 'Nama panggilan TalkTalk Anda:',
  'coin.merchant.der.error': '*Tidak Ada Hasil yang Ditemukan',
  'coin.merchant.der.amount': 'Jumlah Isi Ulang:',
  'coin.merchant.der.redeem': 'Tebus',
  'coin.merchant.der.fail': 'Gagal Mengisi Ulang',
  'coin.merchant.der.fail.tips': 'Gagal mengisi ulang akun Anda, silakan hubungi kami di: TT_support@molelive.com jika Anda memiliki pertanyaan!',
  'coin.merchant.der.complete': 'Isi Ulang Selesai',
  'coin.merchant.der.complete.tips': '{number} koin telah berhasil diisi ulang ke {name}! Silakan masuk untuk menikmati lebih banyak kesenangan!',
  'coin.merchant.der.another': 'Isi ulang akun lain',
  'coin.merchant.der.more': 'Isi Ulang Lebih Banyak',
  'coin.merchant.der.problem': 'Mengalami masalah?',
  'coin.merchant.der.bind.phone': 'Mengikat nomor ponsel',
  'coin.merchant.der.sms.code': 'Masukkan kode verifikasi',
  'coin.merchant.der.sms.tips': 'Aktivitas abnormal telah terdeteksi. Untuk memastikan keamanan akun Anda, selesaikan proses verifikasi di bawah ini.',
  'coin.merchant.der.bind.phone.placeholder': 'Nomor Telepon',
  'coin.merchant.der.resend': 'Kirim ulang',
  'common.cap.Continue': 'Teruskan',
  'coin.merchant.der.code.tips': 'Jika Anda tidak dapat menerima kode verifikasi, silakan hubungi WhatsApp tim resmi:',
  'total.rank.gift': 'MINGGUAN<br />BINTANG',
  'total.rank.hour': 'Setiap jam',
  'total.rank.day': 'Hari',
  'total.rank.week': 'Unduhan',
  'total.rank.month': 'Bulanan',
  'total.rank.last.hour': 'TOP3 Jam Terakhir',
  'total.rank.last.day': 'Hari Terakhir TOP3',
  'total.rank.last.week': 'Minggu Lalu TOP3',
  'total.rank.last.month': 'TOP3 Bulan Lalu',
  'total.rank.this.hour': 'Jam ini',
  'total.rank.this.day': 'Hari ini',
  'total.rank.this.week': 'Minggu ini',
  'total.rank.this.month': 'Bulan ini',
  'total.rank.gap': 'Gap{X}',
  'total.rank.hide': 'Sembunyikan di Peringkat',
  'total.rank.hide.name': 'Mr.Misteri',
  'total.rank.hide.info': 'Informasi Tersembunyi',
  'total.rank.help.title': 'Aturan Peringkat',
  'total.rank.help':
    '<h1>Jenis Peringkat</h1>\n' +
    ' <p>Peringkat &lt;ruangan&gt;: Selama periode waktu tersebut, semua ruangan akan diberi peringkat sesuai dengan jumlah hadiah koin yang dikirim ke dalam ruangan.</p>\n' +
    ' <p>Peringkat &lt;GIFTER&gt;: Selama periode waktu tersebut, semua pengguna akan diberi peringkat sesuai dengan jumlah hadiah koin yang dikirim oleh pengguna.</p>\n' +
    ' <p>Peringkat &lt;CHARMER&gt;: Selama periode waktu tersebut, semua pengguna akan diberi peringkat sesuai dengan jumlah hadiah koin yang Anda terima.</p>\n' +
    ' <h1>Peringkat Siklus Waktu</h1>\n' +
    ' <p>Siklus peringkat biasanya jam, hari, minggu, dan bulan.</p>\n' +
    ' <p>Peringkat menunjukkan 3 teratas dari siklus sebelumnya, serta 50 teratas dari siklus saat ini, diperbarui secara real time.\n' +
    ' (Dengan penundaan kurang dari 1 menit)</p>\n' +
    '<p>Waktu Refresh (UTC+7): Refresh Peringkat Harian pada jam 5 pagi; Penyegaran Peringkat Mingguan pukul 5 pagi setiap hari Senin; Penyegaran Peringkat Bulanan pukul 5 pagi pada tanggal 1 setiap bulan.</p>\r\n' +
    ' <h1>Perlindungan Privasi</h1>\r\n' +
    ' <p>Beberapa Peringkat tidak akan menampilkan angka tertentu; </p>\r\n' +
    ' <p>Namun, perbedaan antara peringkat sebelumnya ditampilkan; </p>\r\n' +
    ' <p>3 teratas tidak akan menunjukkan perbedaan antara satu sama lain.</p>',
  'total.rank.help.eu':
    '<h1>Jenis Peringkat</h1>\n' +
    ' <p>Peringkat &lt;GIFTER&gt;: Selama periode waktu tersebut, semua pengguna akan diberi peringkat sesuai dengan jumlah hadiah koin yang dikirim oleh pengguna.</p>\n' +
    ' <p>Peringkat &lt;CHARMER&gt;: Selama periode waktu tersebut, semua pengguna akan diberi peringkat sesuai dengan jumlah hadiah koin yang Anda terima.</p>\n' +
    ' <h1>Peringkat Siklus Waktu</h1>\n' +
    ' <p>Peringkat menunjukkan 3 teratas dari siklus sebelumnya, serta 50 teratas dari siklus saat ini, diperbarui secara real time.\n' +
    ' (Dengan penundaan kurang dari 1 menit)</p>\n' +
    ' <p>Waktu Refresh (UTC+7): Refresh Peringkat Harian pada jam 5 pagi; Penyegaran Peringkat Mingguan pukul 5 pagi setiap hari Senin; Penyegaran Peringkat Bulanan pukul 5 pagi pada tanggal 1 setiap bulan.</p>\n' +
    ' <h1>Perlindungan Privasi</h1>\n' +
    ' <p>Beberapa Peringkat tidak akan menampilkan angka tertentu; </p>\n' +
    ' <p>Namun, perbedaan antara peringkat sebelumnya ditampilkan; </p>\n' +
    ' <p>3 teratas tidak akan menunjukkan perbedaan antara satu sama lain.</p>',
  'common.cap.Ranking': 'PERINGKAT',
  'total.rank.room': 'ruangan',
  'total.rank.sent': 'GIFTER',
  'common.up.RECEIVED': 'PAWANG',
  'fission.relation.by.amount': 'Berdasarkan Jumlah',
  'fission.relation.by.time': 'Berdasarkan Waktu',
  'common.cap.Countdown': 'Countdown',
  'common.up.RULES': 'ATURAN',
  'common.up.DAYS': 'HARI',
  'common.up.HOURS': 'JAM',
  'common.up.MINS': 'MENIT',
  'common.cap.Successfully.Collected': 'Berhasil Dikumpulkan',
  'common.cap.Countdown.to.Start': 'Hitungan Mundur Untuk Memulai',
  'common.cap.Day': 'Hari',
  'common.cap.Hour': 'Jam',
  'common.cap.Min': 'Min',
  'common.cap.Sec': 'Sec',
  'common.cap.Rules': 'Aturan',
  'common.cap.event.over': 'Acara selesai',
  'recharge.event.tab.1': 'Kemajuan Isi Ulang',
  'recharge.event.amount.reached': 'Jumlah koin yang dicapai',
  'recharge.event.ranking.list': 'Daftar Peringkat',
  'recharge.event.group': 'Kelompok',
  'recharge.event.next.1': 'Hanya perlu',
  'recharge.event.next.2': 'koin untuk mencapai level berikutnya',
  'recharge.event.rewards.for': 'Hadiah Untuk',
  'recharge.event.trouble': 'Apakah Anda mengalami masalah dengan pengisian ulang?',
  'recharge.event.done': 'Selamat! Anda telah mencapai level tertinggi! Nikmati hadiah Anda sekarang!',
  'fission.trouble': 'Apakah Anda mengalami masalah dengan pengisian ulang?',
  'common.cap.No': 'Tidak',
  'common.cap.Unfortunately.Yes': 'Sayangnya ya',
  'common.cap.Waiting': 'Menunggu',
  'common.cap.update': 'Harap perbarui versi untuk menggunakan fitur baru',
  'common.cap.Customer.Server': 'Layanan Pelanggan',
  'common.cap.Ranking.click': 'Klik',
  'common.cap.Ranking.click.text': 'untuk memeriksa hadiah yang berbeda',
  'common.cap.Collect': 'Mengumpulkan',
  'common.cap.Collected': 'Dikumpulkan',
  'common.cap.Limited.Gifts': 'Hadiah Terbatas',
  'family.football.target': 'Target Hadiah Koin',
  'dream.city.rule.reward': 'Aturan&\nHadiah',
  'dream.city.city.map': 'Peta Kota',
  'dream.city.rules': 'Aturan Acara',
  'dream.city.supply.reward': 'Hadiah Stasiun Pasokan',
  'dream.city.wealth': 'Kekayaan',
  'dream.city.charm': 'Jimat',
  'dream.city.wealth.rank': 'Kekayaan',
  'dream.city.charm.rank': 'Jimat',
  'dream.city.current.coins': 'Koin Saat Ini',
  'dream.city.current.diamonds': 'Berlian Saat Ini',
  'dream.city.go': 'GO',
  'dream.city.will.get': 'Anda akan mendapatkan',
  'dream.city.received': 'Selamat! Anda telah menerima',
  'dream.city.top5': '5 pengguna teratas yang mencapai stasiun pasokan di sonnest akan mendapatkan hadiah pasokan.',
  'dream.city.waiting': 'Menunggu',
  'about.title': 'Mengenai',
  'Terms of Service': 'Persyaratan Layanan',
  'Privacy Policy': 'Kebijakan Privasi',
  'Return Policy': 'Kebijakan Pengembalian',
  'Intellectual Property Rights': 'Hak Kekayaan Intelektual',
  'Contact Us': 'Hubungi kami',
  'About Us': 'Tentang kita',
  'Supported Payment Methods': 'Metode Pembayaran yang Didukung',
  subTitle: 'Jangan memercayai saluran isi ulang lain selain merchant terverifikasi.',
  chat: 'Chat',
  id: 'SOYO ID',
  contact: 'Kontak',
  whatsapp: 'WhatsApp',
  right: 'Tim SOYO berhak atas interpretasi akhir.',
  check: 'Kata sandi harus terdiri dari 8 digit dan huruf besar dan kecil',
  same: 'Silakan masukkan kata sandi yang sama',
  copied: 'Tersalin',
  details: 'Detail Berlian',
  miss: 'Berlian yang Terlewatkan',
  profit: 'Statistik Pendapatan',
  missToday: 'Terlewatkan-Hari Ini',
  cumulativeMiss: 'Terlewatkan-Total',
  distributionOfDiamondIncome: 'Distribusi pendapatan berlian',
  getDiamondsMonth: 'Berlian bulan ini:',
  getDiamondsDay: 'Berlian hari ini:',
  cancel: 'Membatalkan',
  confirm: 'Mengkonfirmasi',
  daily: 'Harian',
  month: 'Bulan',
  tips: 'Tidak ada lagi data',
  voiceChat: 'Penilaian pengguna <br /> untuk Voice Chat dengan anchor',
  voiceChat1: 'Penilaian pengguna <br /> untuk Voice Chat dengan saya',
  quickCall: 'Penilaian pengguna <br /> untuk Panggilan Cepat dengan anchor',
  quickCall1: 'Penilaian pengguna <br /> untuk Quick Call dengan saya',
  chatting: 'Performa message chat',
  chatting1: 'Performa obrolan saya',
  chatSpeed: 'Kecepatan balasan',
  chatSpeed1: 'Kecepatan saya membalas pesan',
  like: 'suka',
  boring: 'Membosankan',
  Hate: 'Benci',
  chatConversation: '💬 Putaran Chat',
  reached: '🙋‍♀️Jumlah pengguna',
  count: '🙋‍♀️ Messages',
  speed: 'Kecepatan balasan',
  quality: 'Kualitas obrolan',
  call: 'Panggilan & Peringkat',
  currentCycle: 'Siklus Saat Ini',
  speedTitle: 'Balas Aturan Pendapatan Pesan',
  roundTitle: 'Aturan Pendapatan Putaran Obrolan',
  modalTips: 'Semakin cepat Anda membalas pesan akan meningkatkan pengalaman pengguna, dan semakin banyak berlian yang Anda peroleh.',
  modalTipsTwo: 'Kualitas konten balasan Anda yang lebih tinggi dan lebih banyak putaran obrolan akan meningkatkan pengalaman pengguna, dan semakin banyak berlian yang Anda peroleh:',
  modalSpeed: 'Kecepatan respons',
  modalIncome: 'Tingkat Pendapatan',
  modalRound: 'Putaran obrolan',
  modalReward: 'Hadiah ekstra',
  modalBtn: 'Lanjutkan Ke Chat',
  cycleDiamonds: 'Berlian siklus ini',
  'terms.help': 'Masalah Aplikasi',
  'terms.bug': 'Bug',
  'terms.suggestions': 'Saran',
  'contacts.whatsapp': 'Whatsapp',
  'contacts.email': 'Email',
  'contacts.phone': 'Telepon',
  'submit.success': 'Terima kasih atas tanggapan Anda',
  'terms.title': 'Kategori',
  'details.placeholder': 'Tolong jelaskan detail masalah Anda',
  'contacts.title': 'Cara menghubungi Anda',
  'contact.placeholder': 'Tinggalkan informasi kontak Anda di sini[Rahasia]',
  'submit.btn': 'Kirim ulang',
  'country.select.hot': 'Hot',
  'country.select.all': 'Semua',
  'feedback.details': 'Rincian',
  'feedback.screenshot': 'Tangkapan layar (Opsional)',
  'feedback.title': 'Feedback',
  flowWater: 'Detail Koin',
  coinBack: 'Koin Dikembalikan',
  'home.recharge': 'Isi ulang',
  'home.slogan': 'Temui belahan jiwamu Di sini<br />Selalu bersamamu',
  'home.download': 'Unduhan',
  'income.rules.rules.1': '1. Aturan Gaji: tanggal 16 setiap bulannya dan tanggal satu bulan berikutnya pukul 00:00 akan dipotong dari saldo rekening berlian',
  'income.rules.rules.2': '2. Rasio Gaji: 1USD = 0diamonds',
  'income.rules.rules.3': '3. Waktu Penyelesaian: (1)tanggal 16 setiap bulan 00:00(2) bulan pertama bulan berikutnya 00:00',
  'income.rules.rules.4': '4. Periode Penyelesaian: 7 ~ 14 hari kerja (hari libur ditunda)',
  'income.rules.chat': 'Hubungi Layanan Pelanggan',
  'income.rules.important': 'Penekanan:',
  'income.rules.title': 'Aturan Gaji',
  'income.rules.rules.extra':
    'Kami akan memberi tahu Anda melalui "Tim SOYO" ketika gaji diselesaikan. Detail pengurangan berlian dapat diperiksa di "Pendapatan", harap perhatikan pemberitahuan Tim SOYO. Penyelesaian normal akan selesai dalam 7-14 hari kerja, jika ada hari libur, periode rilis bank akan tertunda, harap bersabar dan tunggu pembayaran. Jika Anda memiliki pertanyaan, silakan hubungi Layanan Pelanggan SOYO, terima kasih atas dukungan Anda!',
  'income.rules.usd': 'USD',
  'income.rules.diamonds': 'Berlian',
  'my.earning.title': 'Penghasilan Saya',
  'my.earning.tips': 'Saldo berlian',
  'my.earning.daimonds': 'Berlian',
  'my.earning.withdrawal.rules': 'Aturan Penarikan',
  'my.earning.salary.rules': 'Aturan Gaji',
  'new.task.go': 'Go',
  'new.task.done': 'Selesai',
  'task.center.title': 'Pusat Tugas',
  'recharge.result.success': 'Pembayaran Berhasil',
  'recharge.result.error': 'Pembayaran Gagal',
  'common.up.OK': 'OK',
  'report.option.Porn': 'Pornografi',
  'report.option.Spam': 'Spam',
  'report.option.Fraud': 'Penipuan',
  'report.option.Violence': 'Kekerasan',
  'report.option.Illegal': 'Ilegal',
  'report.option.Other': 'Lainnya',
  'report.option.room': 'Saya tidak suka ini',
  'report.option.user': 'Saya tidak suka penggunanya',
  'report.reason': 'Kategori',
  'report.details': 'Rincian',
  'report.details.placeholder': 'Menjelaskan detail laporan Anda',
  'report.screenshot': 'Tangkapan layar (Opsional)',
  'report.submit': 'Kirim',
  'report.submit.success': 'Berhasil dilaporkan',
  'report.room': 'Lapor',
  'report.user': 'Lapor pengguna',
  'retirar.rules.title': 'Aturan Penarikan',
  'retirar.rules.rules.1': '1.Sebelum menggunakan layanan penarikan, Anda perlu mengirimkan informasi identitas asli untuk memastikan keamanan akun Anda',
  'retirar.rules.rules.2': '2. Harap berikan informasi identitas asli yang akan digunakan sebagai dasar untuk identifikasi dan penentuan hak untuk menggunakan akun SOYO Anda',
  'retirar.rules.rules.3': '3. Informasi identitas yang Anda kirimkan akan dijaga kerahasiaannya dan tidak akan digunakan untuk tujuan lain',
  'retirar.rules.chat': 'Hubungi Layanan Pelanggan',
  'retirar.rules.important': 'Penekanan:',
  'retirar.rules.rules.extra': 'Silakan hubungi layanan pelanggan dengan kebutuhan untuk menggunakan layanan penarikan dan berikan informasi berikut:',
  'retirar.rules.rules.extra.1': '1) Nama asli',
  'retirar.rules.rules.extra.2': '2) Nomor Whatsapp',
  'retirar.rules.rules.extra.3': '3) Dokumen identitas (kartu identitas, paspor, dll.)',
  'retirar.rules.rules.extra.4': '4) Metode pembayaran Anda (Transfer Bank, Payoneer, Fawry, dll.)',
  'task.center.retirar': 'Klaim Sekarang',
  'task.center.complete': 'Selesai',
  'lucky.darw.end': 'Acara tidak berlangsung',
  'total.rank.daily': 'Harian',
  'task.center.common.level': 'Level',
  'common.cap.Event.To.Start': 'Acara akan segera dimulai',
  'task.center.limit.task': 'Limited Task',
  'task.center.normal.task': 'Normal Task',
  'task.center.limit.today': 'Today',
  'task.center.limit.Total': 'Total',
  'task.center.limit.Diamonds.Received': 'Diamonds Received',
  'task.center.limit.Reward.Received': 'Reward Received',
  'task.center.limit.receive': 'Receive',
  'task.center.limit.extra': 'Extra',
  'task.center.limit.to.start': 'Event has not started yet',
  'gift.wall.title': 'Hadiah',
  'gift.wall.help.text1': 'Hadiah yang melebihi {num} koin sekaligus akan muncul di dinding hadiah',
  'gift.wall.help.text2': 'Hadiah dengan jumlah koin yang lebih besar akan ditampilkan di peringkat pertama',
  'gift.wall.help.text3': 'Catatan di dinding hadiah akan disimpan selama 24 jam',
  'level.user': 'Level Pengguna',
  'level.halo': 'Level Halo',
  'level.halo.question1.title': 'Manfaat dari Level Tinggi',
  'level.halo.question1.answer1': 'Semakin Tinggi Level, Semakin Terang Halo',
  'level.halo.question2.title': 'Tampilan Level Ikon',
  'level.halo.question2.answer1': 'Semakin tinggi level, semakin unik ikonnya',
  'level.halo.question3.title': 'Hadiah Level Up',
  'level.halo.question3.answer1': 'Tingkatkan Level Untuk Mendapatkan Hadiah Menakjubkan',
  'level.halo.question4.title': 'Cara Meningkatkan Level',
  'level.halo.question4.answer1': 'Terima hadiah senilai 1 koin, Dapatkan 1 pengalaman. Semakin tinggi pengalaman, semakin cepat naik level.',
  'level.user.question1.title': 'Manfaat dari Level Tinggi',
  'level.user.question1.answer1': 'Anda akan mendapatkan item dan hadiah khusus.',
  'level.user.question2.title': 'Cara Meningkatkan Level',
  'level.user.question2.answer1': 'Berkomunikasi di ruangan, bermain game, dan mengirim hadiah',
  'level.user.question2.answer2': 'Selesaikan tugas harian, dapatkan pengalaman untuk naik level',
  'level.user.question2.answer7': 'Bergabung dengan pesta di ruangan',
  'level.user.question2.answer8': 'Kirim hadiah dengan koin',
  'level.user.question2.answer9': 'Selesaikan tugas dan klaim EXP',
  'level.user.question3.title': 'Tampilan Level Ikon',
  'level.user.question3.answer1': 'Semakin tinggi level, semakin unik ikonnya',
  'level.user.question4.title': 'Hadiah Level Up',
  'level.user.question4.answer1': 'Tingkatkan Level Untuk Mendapatkan Hadiah Menakjubkan',
  'level.user.level': 'Level',
  'level.user.icon': 'Ikon',
  'level.user.tips': 'Saat Anda terus mendapatkan poin EXP, level Anda akan bertambah secara bertahap.',
  'level.user.way.up': 'Cara meningkatkan poin EXP:',
  'about.badges.title': 'Tentang Lencana',
  'about.badges.0': 'Pakai lencana',
  'about.badges.1': 'Semua lencana dapat dipakai, dan hingga lima lencana dapat dipakai secara bersamaan.',
  'about.badges.2': 'Lencana Kehormatan',
  'about.badges.3':
    'Lencana yang terbuka berdasarkan nilai akumulasi bulanan dihitung pada tanggal 1 setiap bulan, baik lencana tersebut dimiliki atau tidak. Masa berlaku lencana tersebut adalah selama satu bulan.',
    'vip.privileges.title.privacy': 'Keamaan Pribadi',
  'vip.privileges.label.privacy': 'Room mu tidak bisa ditemukan',
  'vip.privileges.title.invisible': 'Mode tidak terlihat',
  'vip.privileges.label.invisible': 'Mode tidak terlihat dan sembunyikan kamu dari rekomendasi',
  'vip.privileges.title.blockMessages': 'Mode diam',
  'vip.privileges.label.blockMessages': 'Block orang tidak dikenal',
  'vip.privileges.title': 'Privilige SOYO VIP',
  'vip.privileges.activate': 'Aktifkan VIP{N}',
  'vip.privileges.stealth.text': 'Sembunyikan riwayat akses saya',
  'vip.privileges.stealth.open': 'Buka',
  'vip.privileges.stealth.close': 'Tutup',
  'vip.privileges.title.icon': 'Label VIP',
  'vip.privileges.label.icon': 'Label VIP yang menonjol, identitas eksklusif pengguna VIP, menunjukkan kehormatan Anda.',
  'vip.privileges.title.visitor': 'Jejak Kunjungan',
  'vip.privileges.label.visitor': 'Lihat semua jejak pengunjung sehingga Anda tidak melewatkan orang yang menarik dan penggemar.',
  'vip.privileges.title.list': 'Tampilan Prioritas',
  'vip.privileges.label.list': 'Tampilan prioritas hasil pencarian.',
  'vip.privileges.title.follower': 'Batas Atas Mengikuti yang Lebih Tinggi.',
  'vip.privileges.label.follower':
    'Anda dapat mengikuti lebih banyak teman untuk memperluas lingkaran teman. Non-VIP dapat mengikuti 500 orang VIP1-VIP3 dapat mengikuti 1000 orang VIP4-VIP6 dapat mengikuti 2000 orang VIP7 ke atas dapat mengikuti 3000 orang',
  'vip.privileges.title.sign': 'Hadiah Check-in VIP',
  'vip.privileges.label.sign': 'VIP dapat mengklaim hadiah check-in ganda',
  'vip.privileges.title.stealth': 'Kunjungan Tidak Terlihat',
  'vip.privileges.label.stealth': 'Ketika Anda mengunjungi pengguna lain, tidak ada riwayat yang akan dicatat',
  'vip.privileges.title.name': 'Sorotan Nama Pengguna',
  'vip.privileges.label.name': 'Pengguna akan mendapatkan efek khusus untuk menghias nama pengguna',
  'vip.privileges.title.avatar': 'Bingkai VIP',
  'vip.privileges.label.avatar': 'Bingkai eksklusif untuk menunjukkan identitas Anda.',
  'vip.privileges.title.bubble': 'Bubble Chat VIP',
  'vip.privileges.label.bubble': 'Bubble chat eksklusif membuat ucapan Anda menonjol.',
  'vip.privileges.title.room': 'Kartu Mini VIP',
  'vip.privileges.label.room': 'Kartu ruangan VIP eksklusif',
  'vip.privileges.title.picture': 'Kartu mini eksklusif untuk menyoroti kehormatan Anda.',
  'vip.privileges.label.picture': 'Opsi untuk mengirim gambar pada bullet chat',
  'vip.privileges.title.message': 'Daftar Pesan Teratas',
  'vip.privileges.label.message': 'Anda dapat menempatkan dialog pengguna lain di bagian atas daftar pesan.',
  'vip.privileges.title.vehicle': 'Kendaraan VIP',
  'vip.privileges.label.vehicle': 'Kendaraan VIP eksklusif',
  'vip.privileges.title.rank': 'Sembunyikan Identitas',
  'vip.privileges.label.rank': 'Anda dapat mengaktifkan tombol [Sembunyikan Identitas] untuk muncul sebagai orang misterius di peringkat.',
  'vip.privileges.title.broadcast': 'Pemberitahuan Aplikasi',
  'vip.privileges.label.broadcast': 'Ketika Anda mencapai VIP8 dan di atasnya, peningkatan akan memicu pemberitahuan aplikasi.',
  'vip.privileges.title.id': 'ID Eksklusif',
  'vip.privileges.label.id':
    'Dapatkan ID eksklusif Anda dan biarkan orang lain mengingat Anda dengan sekilas! VIP8: ABABAB (A dan B harus berbeda) VIP9: AAABB (A dan B harus berbeda) VIP10: ABAB (A dan B harus berbeda) VIP11: AABB (A dan B harus berbeda) Semua bentuk tidak boleh dimulai dengan 0. Anda perlu menghubungi layanan pelanggan VIP untuk mendapatkan ID eksklusif.',
  'vip.privileges.title.protection': 'Pencegahan Penghapusan',
  'vip.privileges.label.protection': 'Tidak perlu khawatir tentang dihapus dari mikrofon atau ruangan mana pun.',
  'vip.privileges.title.gif': 'Bingkai Dinamis Kustom',
  'vip.privileges.label.gif':
    'Mengkustomisasi bingkai dinamis eksklusif Anda. Ketika Anda mencapai VIP9, silakan hubungi Layanan Pelanggan untuk mendapatkan Bingkai Dinamis Kustom, setiap pengguna hanya memiliki satu kesempatan untuk mengkustomisasi.',
  'vip.privileges.title.gift': 'Hadiah Kustom',
  'vip.privileges.label.gift':
    'Kustomisasikan hadiah eksklusif Anda. Ketika Anda mencapai VIP10, silakan hubungi Layanan Pelanggan untuk mendapatkan Hadiah Kustom, setiap pengguna hanya memiliki satu kesempatan untuk mengkustomisasi.',
  'vip.privileges.title.exclusivevehicles': 'Kendaraan Kustom',
  'vip.privileges.label.exclusivevehicles':
    'Kustomisasikan kendaraan eksklusif Anda. Ketika Anda mencapai VIP11, silakan hubungi Layanan Pelanggan untuk mendapatkan Kendaraan Kustom, setiap pengguna hanya memiliki satu kesempatan untuk melakukan kustomisasi.',
  'vip.privileges.title.open': 'Halaman Bukaan Kustom',
  'vip.privileges.label.open':
    'Pengguna lain dapat melihat Anda secara otomatis setiap kali mereka membuka aplikasi. Kejayaan yang tak tertandingi! Ketika anda mencapai VIP12, mohon kontak Pelayanan Pengguna untuk mendapatkan Halaman Bukaan Kustom selama 24 jam, setiap pengguna hanya mendapatkan satu kali kesempatan untuk melakukan modifikasi.',
  'vip.privileges.update.info': 'Sistem VIP sudah di perbarui, versi anda terlalu lama untuk melihat fitur terbaru. Mohon update versi terbaru dan nikmati fungsi VIP yang baru!',
  'aristocracy.vip.title': 'Aristokrasi',
  'vip.help.1': 'Aturan VIP',
  'vip.help.2': 'Apa itu VIP?',
  'vip.help.3': 'VIP adalah kelas khusus di TalkTalk. Terdapat 16 tingkat identifikasi VIP. Anda akan mendapatkan hak istimewa yang sesuai setelah Anda memiliki VIP.',
  'vip.help.4': 'Bagaimana cara meningkatkan level VIP?',
  'vip.help.5':
    '1. Melakukan isi ulang senilai $1 akan mendapatkan 1 VIP EXP.\n2. Level VIP Anda akan langsung naik ketika VIP EXP mencapai persyaratan peningkatan pada bulan tersebut.\n3. Mulai dari hari pertama setiap bulan, VIP EXP awal setiap orang akan diatur ulang menjadi 0. Dan level VIP Anda akan ditentukan berdasarkan jumlah akumulasi EXP.',
  'vip.help.6': 'Persyaratan Peningkatan Level VIP',
  'vip.help.7': 'Aturan Peningkatan',
  'vip.help.8':
    'Setelah Anda mencapai syarat peningkatan pada bulan ini, level VIP Anda akan segera diperbarui. Level VIP tersebut akan dipertahankan hingga akhir bulan berikutnya. Misalnya, pengguna mencapai VIP5 pada tanggal 10 Maret, dan level VIP5 akan berlanjut hingga 30 April.',
  'vip.help.9': 'Aturan Pemeliharaan/Penurunan Level',
  'vip.help.10':
    'Jika total akumulasi nilai EXP Anda mencapai persyaratan untuk level VIP Anda saat ini pada bulan ini, Anda akan mempertahankan level VIP Anda pada bulan berikutnya; jika tidak, level VIP Anda akan diturunkan pada bulan berikutnya. Misalnya, jika Anda adalah VIP5 pada bulan Mei, tetapi Anda hanya mengakumulasi VIP3 pada bulan Mei, maka level VIP Anda akan diturunkan menjadi VIP3 mulai bulan Juni.',
  'vip.help.11': 'Pengembalian Level VIP',
  'vip.help.12': 'Berdasarkan Level VIP pada tanggal 1 setiap bulannya, kami akan mengirimkan jumlah tertentu EXP VIP kepada pengguna sesuai dengan Level VIP mereka pada tanggal 1 setiap bulannya.',
  'vip.help.13': 'Tingkat',
  'vip.help.14': 'Rentang Nilai EXP',
  'vip.help.15': 'Pengembalian Nilai EXP',
  'vip.help.update.info': 'Sistem VIP telah diperbarui, versi anda terlalu lama untuk melihat fitur terbaru. Mohon perbarui ke versi terbaru dan nikmati fitur VIP yang baru!',
  'to.exchange.page.button': 'Pertukaran',
  'ranking.daily': 'Harian',
  'ranking.weekly': 'Mingguan',
  'ranking.contributed': 'Berkontribusi:',
  'ranking.blank.daily': 'Tidak ada yang mengirimkan hadiah hari ini',
  'ranking.blank.weekly': 'Tidak ada yang mengirimkan hadiah minggu ini',
  'lucky.packet.title': 'Amplop keberuntungan',
  'lucky.packet.1': 'Kirim amplop keberuntungan',
  'lucky.packet.2': 'Semua pengguna di ruangan dapat mengirim dan mengklaim amplop keberuntungan',
  'lucky.packet.3':
    'Ketika ada banyak amplop keberuntungan di ruangan, mereka akan ditumpuk dan ditampilkan. Semakin awal amplop keberuntungan dikirim, semakin banyak yang ditampilkan di bagian atas.',
  'lucky.packet.4': 'Batasan dan Persyaratan',
  'lucky.packet.5': '1. Hanya mendukung pengiriman koin.',
  'lucky.packet.6':
    '2. Rentang jumlah koin yang dapat diterbitkan: 1000 hingga 9999999\nJumlah koin default adalah 3000 koin.\nJumlah orang yang dapat diterbitkan: 5-500\nJumlah default adalah 10 orang.',
  'lucky.packet.7': 'Jumlah per kapita (jumlah koin/jumlah orang) harus lebih besar atau sama dengan 100 koin.',
  'lucky.packet.8': 'Ketika jumlah amplop melebihi 10000 koin dan jumlah orang melebihi 10, akan memicu pemberitahuan dunia, dan ikon khusus akan ditampilkan di daftar ruangan.',
  'lucky.packet.9': 'Cara bermain',
  'lucky.packet.10': '1. Amplop keberuntungan dengan kata sandi\n- Anda perlu mengirim kata sandi di ruangan untuk mendapatkannya.',
  'lucky.packet.11': '2. Amplop keberuntungan membutuhkan pengikut\n- Anda perlu mengikuti pengirim amplop keberuntungan untuk mendapatkannya.',
  'lucky.packet.12': 'Masalah Umum',
  'lucky.packet.13': '1. Ketika amplop memicu pemberitahuan dunia atau ketika ikon amplop keberuntungan ditampilkan, orang lain dapat masuk ke ruangan Anda dengan mengkliknya.',
  'lucky.packet.14': '2. Jika Anda mengunci ruangan Anda, pemberitahuan dunia dan ikon tidak akan muncul.',
  efficient_text_2: 'Waktu yang efektif',
  efficient_text_5: 'Hari yang efektif',
  efficient_text_6: '1. Waktu yang valid di dalam ruangan',
  efficient_text_7:
    'Di ruang suara, waktu pada mic - waktu dari mic> = %$ menit adalah waktu yang efektif pada mic. Kami mendorong orang di mic untuk mengambil inisiatif untuk mengobrol dan berinteraksi dengan teman baik untuk waktu yang lama, seperti:',
  efficient_text_8: 'Waktu di mic',
  efficient_text_9: 'Waktu luang mikrofon',
  efficient_text_10: 'Apakah mencapai standar',
  efficient_text_11: 'Menjelaskan',
  efficient_text_12: 'Mencapai standar',
  efficient_text_13: 'Tidak mencapai standar',
  efficient_text_14: 'Jika waktu di mikrofon berlangsung sampai hari berikutnya, itu dihitung sebagai waktu untuk hari sebelumnya',
  efficient_text_15: 'Waktu luang mikrofon pada mic <%$ menit',
  efficient_text_16: 'Waktu libur mic-time pada mic> = %$ menit',
  efficient_text_17: '12/1 9:00:00 Waktu di mic tidak mencapai standar, itu tidak dapat dihitung sebagai waktu yang efektif',
  efficient_text_18: '12/1 23:00:00 Waktu di mic berlanjut sampai 12/2 dan berlangsung 90 menit berturut -turut, sehingga dihitung sebagai waktu selama 12/1',
  efficient_text_19: '2. Hari -hari yang efektif di ruangan itu',
  efficient_text_20:
    'Di ruang suara, semua durasi efektif ditambahkan pada hari> = %$ menit adalah hari yang efektif. Semakin tinggi jumlah hari yang valid, semakin tinggi aktivitasnya, dan laju aktivitas secara langsung mempengaruhi hadiah yang Anda dapatkan.',
  efficient_text_21: 'Total waktu efektif',
  efficient_text_22: '12/1 3 kali pada mic di siang hari: 15 menit +35 menit +90 menit. Karena 15 menit tidak memenuhi standar, waktu yang valid = 90 +35 = 125 menit = 02H 05 menit',
  efficient_text_23: '12/1 Durasi yang valid dari hari itu total = 02H 05 menit> = %$ menit, yang dihitung sebagai hari yang valid',
  efficient_text_24: '3. Waktu di mic',
  efficient_text_25:
    'Waktu pada mic harus waktu yang efektif, dan waktu yang efektif harus> = %$ menit. Waktu pada mikrofon hanya menunjukkan waktu mengobrol di mikrofon di dalam ruangan. Ini akan dianggap sebagai waktu yang efektif ketika waktu pada mic> = %$ menit, seperti:',
  efficient_text_26: '4. Detail Data',
  efficient_text_27: 'Data akan ditampilkan sesuai dengan siklus yang dipilih',
  efficient_text_28: 'Berlian',
  efficient_text_29: 'Mengobrol',
  efficient_text_30: 'Ruang suara',
  efficient_text_31: 'Detail',
  efficient_text_32: 'Tanggal',
  efficient_text_33: 'Waktu di mic',
  efficient_text_34: 'Waktu yang efektif dari ruang suara',
  efficient_text_35: 'Hari -hari efektif ruang suara',
  share_text23: 'unduh',
  share_text24: 'Bergabunglah dengan kamar',
  share_text25: 'Saya di ruangan ini, datang dan bergabung dengan kami',
  share_text26: 'ID kamar',
  OfficialTask_Card_common_text1: 'Tugas resmi',
  OfficialTask_Card_common_text2: 'Setelah menyelesaikan tugas hadiah resmi, Anda bisa mendapatkan gundam {%s} berlian!',
  OfficialTask_dailyCard_text1: 'Hadiah Bor Harian ({%s1}/{%s2})',
  OfficialTask_dailyCard_text2: 'Acquisition Daily Diamond ({%s1}) mencapai {%s2} 💎, dapatkan {%s3} 💎',
  OfficialTask_dailyCard_text3: 'Hadiah Bor Harian ({%s3}) mencapai {%s1} 💎, dapatkan {%s2} 💎',
  OfficialTask_dailyCard_text4: 'Waktu hadiah',
  OfficialTask_dailyCard_text5: 'PERGI',
  OfficialTask_PeriodCard_text1: 'Siklus hadiah berlian ({%s1}/{%s2})',
  OfficialTask_PeriodCard_text2: 'Hadiah berlian siklus ({%s3}) mencapai {%s1} 💎, dapatkan {%s2} 💎',
  OfficialTask_ActiveCard_text1: 'Hadiah aktif',
  OfficialTask_ActiveCard_text2: 'Login kumulatif dalam siklus {%s1} tian ({%s2}/{%s3})',
  OfficialTask_ActiveCard_text3: 'Dan ambil bor setiap hari untuk mencapai {%s1} 💎, dan Anda bisa mendapatkan {%s2} 💎. (Hari ini: {%s3}/{%s4})',
  OfficialTaskPic_Level_text1: 'Penghasilan Berlian Harian Hadiah Penghasilan Berlian Harian (Pesan IM)',
  OfficialTaskPic_Level_text2: 'TINGKAT',
  OfficialTaskPic_Level_text3: 'Target berlian',
  OfficialTaskPic_Level_text4: 'Hadiah berlian',
  OfficialTaskPic_Level_text5: 'Hadiah Penghasilan Berlian Harian （Hadiah Ruang Suara: Ruang Suara Normal + Ruang Suara Keluarga)',
  OfficialTaskPic_Level_text6: 'Hadiah Penghasilan Berlian Periode Kerja (Pesan IM + Semua Hadiah Diterima)',
  OfficialTaskPic_Level_text7: 'Periode kerja Hadiah Aktif',
  OfficialTaskPic_Level_text8: 'Masuk untuk {%s1} hari dan dapatkan {%s2} berlian setiap hari',
  OfficialTaskPic_Level_text9: 'Persyaratan',
  OfficialTaskPic_Level_text10: 'Hadiah berlian',
  OfficialTaskPic_Level_text11: 'Melihat:',
  OfficialTaskPic_Level_text12: '1. Hadiah Ruang Suara: Termasuk ruang suara normal dan ruang suara keluarga;',
  OfficialTaskPic_Level_text13: '2. Hadiah Aktivitas Periode Kerja: Setelah periode berakhir, jumlah hari aktif akan dihitung, dan hadiah maksimum yang bisa Anda dapatkan adalah 350 berlian;',
  OfficialTaskPic_Level_text14: '3. Semua Imbalan Berlian akan dikirim langsung ke akun Anda selama periode kerja ini;',
  OfficialTaskPic_Level_text15: '4. Setiap 70 berlian = 1 USD',
  OfficialTaskPic_Level_text16: 'Hadiah harian (untuk hadiah dalam obrolan, panggilan audio dan video, kecocokan audio)',
  OfficialTaskPic_Level_text17:
    'Jika Anda menerima berlian untuk hadiah dalam obrolan, panggilan audio-dan video, pencocokan audio dan memenuhi target harian, Anda akan menerima hadiah berlian yang sesuai. Imbalan berlian akan ditambahkan ke acount Anda pada hari berikutnya secara otomatis.',
  OfficialTaskPic_Level_text18: 'Hadiah harian (untuk hadiah di obrolan dan ruang suara)',
  OfficialTaskPic_Level_text19: 'Hadiah Periode Kerja (untuk panggilan audio dan video)',
  OfficialTaskPic_Level_text20:
    '1. Jika Anda menerima berlian untuk hadiah di obrolan dan ruang suara dan memenuhi target harian, Anda akan menerima hadiah berlian yang sesuai. Imbalan berlian akan ditambahkan ke acount Anda pada hari berikutnya secara otomatis.',
  OfficialTaskPic_Level_text21:
    '2. Jika Anda menerima berlian untuk panggilan audio dan video dan memenuhi target pada akhir periode kerja, Anda akan menerima hadiah berlian yang sesuai. Imbalan berlian akan ditambahkan ke acount Anda pada hari berikutnya setelah akhir periode kerja secara otomatis.',
  OfficialTaskPic_Level_text22: '3. Ada 2 periode kerja yang berlangsung dari tanggal 1 hingga 15 setiap bulan, dan dari 16 hingga 30/31 setiap bulan.',
  OfficialTaskPic_Level_text23: 'Hadiah Harian 1 (Ruang Pesta Audio & Berlian Hadiah Keluarga)',
  OfficialTaskPic_Level_text24: 'Hadiah Harian 2 (Panggilan Suara, Suara Match Diamond)',
  OfficialTaskPic_Level_text25: 'Hadiah Periode Kerja (Obrolan Kencan, Ruang Pesta Audio, Panggilan Suara, Pencocokan Audio)',
  OfficialTaskPic_Level_text26:
    '1. Jika Anda menerima berlian untuk hadiah dalam obrolan, ruang audio dan panggilan video, kecocokan audio dan memenuhi target mingguan, Anda akan menerima hadiah berlian yang sesuai. Imbalan Diamond akan ditambahkan ke acount Anda dalam istilah gaji ini secara otomatis.',
  OfficialTaskPic_Level_text27: '2. Laju Gaji: 100 Berlian = 1 USD ;',
  OfficialTaskPic_Level_text28: '3. Pengguna berlian yang didapat dari penghargaan harian tidak akan dihitung ke target berlian ini;',
  OfficialTaskPic_Level_text29: 'Sistem Hadiah Resmi Soyo 02.2024',
  OfficialTaskPic_Level_text30: 'Tugas Harian 1: Semua Panggilan + Semua Hadiah',
  OfficialTaskPic_Level_text31: 'Misi Harian 2: Balas Pesan',
  OfficialTaskPic_Level_text32: 'Target berlian harian',
  OfficialTaskPic_Level_text33: 'Target berlian harian kami ditunjukkan di atas, dan suatu hari dalam sistem penghargaan kami didasarkan pada waktu setempat Turki.',
  cms_report_text3: 'Laporkan Alasan:',
  cms_report_text4: 'Detail Laporan:',
  cms_report_text5: 'Laporkan tangkapan layar:',
  cms_report_text6: '*Harap verifikasi informasi di atas. Harap ambil penalti untuk informasi.',
  cms_report_text7: 'Reporter Showid:',
  cms_report_text8: 'Reporter showid:',
  cms_report_text9: 'Metode penalti:',
  cms_report_text10: 'Hasil penalti:',
  cms_report_text11: 'Sejarah Hukuman',
  cms_report_text12: 'Mengurangi peringatan',
  cms_report_text13: 'Metode pelarangan:',
  cms_report_text14: 'Alasan Banning:',
  cms_report_text15: 'Waktu pelarangan:',
  cms_report_text16: 'Sensitivitas Politik',
  cms_report_text17: 'Vulgar',
  cms_report_text18: 'Serangan dan Penyalahgunaan',
  cms_report_text19: 'Kekerasan berdarah',
  cms_report_text20: 'Drainase iklan',
  cms_report_text21: 'Dugaan penipuan',
  cms_report_text22: 'Informasi ilegal',
  cms_report_text23: 'Minor',
  cms_report_text24: 'lainnya',
  cms_report_text25: 'Konfirmasi',
  cms_report_text26: 'Operasi Sensitif Akun, Harap Konfirmasi Informasi Setelah Informasi',
  OfficialTask_dailyCard_text6: 'lengkap',
  OfficialTask_rewardTime_text1: 'jam',
  OfficialTask_rewardTime_text2: 'menit',
  delete_account_web_title:
  'Dear SOYO Pengguna. Terima kasih telah menggunakan dan mendukung produk kami. Jika Anda ingin menghapus akun Anda, silakan periksa langkah -langkah berikut untuk diproses:',
  delete_account_web1: '1. Buka Aplikasi dan Masukkan Halaman Utama, Klik Tab Bawah Kanan untuk Memasuki Saya.',
  delete_account_web2: '2. Klik Pengaturan Setelah memasuki tab saya.',
  delete_account_web3: '3. Klik Hapus Akun Setelah Memasuki Pengaturan.',
  delete_account_web4: '4. Konfirmasikan langkah pertama untuk menghapus akun secara permanen.',
  delete_account_web5: '5. Konfirmasikan langkah kedua untuk tidak dapat login menggunakan akun yang sama.',
  delete_account_web6: '6. Konfirmasi langkah terakhir untuk menghapus semua informasi pribadi yang sesuai.',
  delete_account_web7:
    '7. Setelah menyelesaikan semua langkah di atas. Akun Anda telah berhasil dihapus, dengan semua informasi pribadi dan data akun Anda. Platform kami tidak akan lagi menyimpan data Anda di database kami.',
  delete_account_web: 'Hapus Akun',
  svip_introduce_1: '1.Apa itu SVIP',
  svip_introduce_text1: 'SVIP adalah sistem level yang dibedakan dalam platform. Total ada 8 level, SVIP1-SVIP8. Semakin tinggi levelnya, semakin tinggi statusnya, dan semakin tinggi hak dan kepentingan yang Anda nikmati.',
  svip_introduce_2: '2. Bagaimana menjadi SVIP',
  svip_introduce_text2: 'Anda bisa mendapatkan poin pengalaman SVIP dengan mengisi ulang koin emas, 1 koin emas = 1 poin pengalaman. Ketika poin pengalaman mencapai level SVIP yang berbeda, Anda bisa mendapatkan level SVIP yang sesuai.',
  svip_introduce_3: '3. Cara menghitung masa berlaku SVIP',
  svip_introduce_text31: 'SVIP menjadikan setiap bulan alami sebagai sebuah siklus, dan pada tanggal 1 setiap bulan alami, poin pengalaman SVIP diakumulasikan dari 0. Level SVIP yang sesuai akan diperoleh sesuai dengan level yang dicapai oleh nilai pengalaman SVIP.',
  svip_introduce_text32: 'Sejak Anda memperoleh status SVIP, status dan hak istimewa Anda akan dipertahankan hingga akhir bulan berikutnya. (Misalnya: jika SVIP3 tercapai pada tanggal 18 Mei, maka status SVIP3 akan diperpanjang hingga 30 Juni.)',
  svip_introduce_text33: 'Jika nilai pengalaman SVIP Anda mencapai level yang lebih tinggi dalam satu bulan alami, nilai tersebut akan segera ditingkatkan. (Misalnya: jika Anda mencapai SVIP3 pada tanggal 18 Mei dan SVIP4 pada tanggal 25 Mei, Anda akan langsung memperoleh status SVIP4 pada tanggal 25 Mei dan berlanjut hingga akhir tanggal 30 Juni.)',
  svip_introduce_text34: 'Ketika bulan alami berakhir, identitas dan hak istimewa akan dipertahankan, tetapi poin pengalaman SVIP untuk bulan berikutnya akan mulai diakumulasikan dari 0. (Misalnya: Jika Anda mencapai SVIP4 pada tanggal 25 Mei, Anda masih akan menikmati status dan hak SVIP4 sepanjang bulan Juni, namun Anda akan mulai mengumpulkan poin pengalaman SVIP dari 0 pada bulan Juni)',
  svip_introduce_4: '4. Degradasi dan penurunan peringkat SVIP',
  svip_introduce_text41: 'Di awal setiap bulan alami, jika Anda memiliki level SVIP dan ingin mempertahankan level SVIP ini di bulan berikutnya, Anda harus mencapai 50% dari nilai pengalaman level SVIP sebelum akhir bulan alami, yaitu Anda dapat mempertahankan level ini di bulan depan, lanjutkan identitas dan haknya. (Misalnya: ketika bulan Mei dimulai, Anda adalah SVIP4, maka Anda hanya perlu mencapai 50% nilai pengalaman level SVIP4 sepanjang bulan Mei untuk mempertahankan level SVIP4 hingga akhir Juni.)',
  svip_introduce_text42: 'Jika 50% nilai pengalaman level SVIP tidak tercapai dalam satu bulan alami, maka akan dikurangi 1 level di bulan berikutnya. (Misalnya: awal bulan Mei Anda SVIP4, dan nilai pengalaman sepanjang bulan Mei tidak mencapai 50% dari level SVIP4, maka bulan Juni akan turun 1 level, dimulai dari SVIP3.)',
  noble_introduce_title: 'Pengantar Aturan Mulia',
  noble_introduce_welcome: 'Selamat bergabung dengan sistem aristokrat kami! Menjadi seorang bangsawan akan memungkinkan Anda menikmati lebih banyak hak istimewa dan manfaat unik. Berikut ini adalah pengenalan yang bermanfaat tentang sistem mulia kita:',
  noble_introduce_1: '1.Apa itu bangsawan?',
  noble_introduce_text1: 'Bangsawan merupakan simbol status khusus dan dibagi menjadi tujuh tingkatan status, yaitu ksatria, baron, viscount, earl, marquis, duke, dan king. Tingkat bangsawan yang berbeda menikmati hak istimewa yang berbeda.',
  noble_introduce_2: '2. Bagaimana menjadi seorang bangsawan',
  noble_introduce_text2: 'Bangsawan dapat dibeli dengan mengisi ulang koin emas, dan pembelian dapat diselesaikan dengan memilih level yang sesuai di halaman bangsawan. Di saat yang sama, Anda juga bisa memperoleh status mulia melalui hadiah dari orang lain.',
  noble_introduce_3: '3. Perhitungan masa berlaku',
  noble_introduce_text3: 'Status bangsawan berlaku selama 30 hari sejak tanggal pembelian, dan Anda akan menikmati hak istimewa mulia selama masa berlakunya. Hak istimewa tersebut akan kedaluwarsa pada akhir masa berlaku.',
  noble_introduce_4: '4. Instruksi pengiriman koin harian',
  noble_introduce_text4: 'Setelah membeli bangsawan melalui koin emas, Anda berhak menerima koin emas sebagai hadiah setiap hari. Anda akan menerima koin emas setelah masuk setiap hari. Jika Anda tidak masuk pada hari itu, Anda tidak akan bisa mendapatkan koin emas untuk hari itu. Saat menghadiahkan bangsawan kepada orang lain, penerima akan menerima hak untuk menerima koin harian.',
  noble_introduce_5: '5. Diskon pembelian berkelanjutan',
  noble_introduce_text5: 'Selama periode memperoleh status bangsawan, dan dalam waktu 10 hari setelah status bangsawan berakhir, status bangsawan yang sama dapat dibeli kembali dengan harga yang lebih menguntungkan. Paket ini tidak otomatis dan mengharuskan Anda membelinya secara manual agar dapat diterapkan.',
  noble_introduce_6: '6. Peningkatan bangsawan',
  noble_introduce_text6: 'Ketika Anda sudah memiliki status bangsawan, Anda dapat membeli bangsawan dengan level lebih tinggi dan langsung mendapatkan manfaat terbaru. Sisa hari bangsawan yang ada akan digabungkan ke level terbaru sesuai dengan nilai yang tersisa, dan masa berlaku bangsawan baru level akan diperpanjang.',
  noble_introduce_7: '7. Hadiah dari bangsawan',
  noble_introduce_text7: 'Saat memberikan bangsawan kepada orang lain, harga asli akan diberikan kepada orang lain, dan penerima akan menikmati koin harian dan hak lain yang sesuai dengan levelnya. Jika penerima sudah memiliki status bangsawan, dia akan langsung menikmati status bangsawan tingkat tinggi, dan level lainnya akan dikonversi sesuai dengan durasi yang sesuai.',
  vip_introduce_title: 'Pengantar aturan VIP',
  vip_introduce_welcome: 'Selamat menjadi VIP! VIP akan memungkinkan Anda menikmati lebih banyak hak istimewa. Berikut ini adalah pengenalan bantuan tentang VIP:',
  vip_introduce_1: '1.Apa itu VIP',
  vip_introduce_text1: 'VIP adalah sistem pertumbuhan pengguna. Saat ini ada 16 level, VIP-VIP6. Semakin tinggi level VIP, semakin banyak hak yang akan Anda nikmati dan semakin kaya propertinya.',
  vip_introduce_2: '2.Bagaimana menjadi VIP',
  vip_introduce_text2: 'Anda bisa mendapatkan poin pengalaman VIP dengan mengisi ulang koin emas, 1 koin emas = 1 poin pengalaman. Semakin banyak koin emas yang Anda isi ulang, semakin banyak poin pengalaman yang Anda dapatkan, dan VIP akan naik ke level yang lebih tinggi.',
  vip_introduce_3: '3.Masa berlaku VIP',
  vip_introduce_text3: 'Setelah status VIP diperoleh, status tersebut berlaku secara permanen, dan poin pengalaman VIP diakumulasikan secara permanen dan tidak akan dihapus. Saat poin pengalaman meningkat, Anda akan dipromosikan ke level VIP yang lebih tinggi dan menikmati lebih banyak manfaat level.',
  vip_introduce_4: '4. Penggunaan alat peraga VIP',
  vip_introduce_text4: 'Props level VIP yang Anda peroleh akan selalu disimpan di ransel Anda dan dapat dipakai kapan saja saat Anda melakukan upgrade, props level rendah yang Anda peroleh masih dapat digunakan.',
  svip_introduce_title: 'Pengantar aturan SVIP',
  svip_introduce_welcome: 'Selamat menjadi SVIP! SVIP akan memungkinkan Anda menikmati lebih banyak hak istimewa. Berikut ini adalah pengenalan SVIP yang bermanfaat:',
  svip_introduce_text43: 'Jika tidak ada poin pengalaman SVIP yang dihasilkan dalam satu bulan alami, level SVIP akan turun 3 level di bulan berikutnya. (Misalnya: ketika bulan Mei dimulai, Anda SVIP4, dan tidak ada poin pengalaman yang dihasilkan sepanjang bulan Mei, maka bulan Juni akan turun 3 level, dimulai dari SVIP1)',
  svip_introduce_5: '5. Hak dan kepentingan SVIP',
  svip_introduce_text5: 'Semakin tinggi level SVIP, semakin banyak hak yang Anda nikmati. Saat level berubah, Anda akan menikmati manfaat level baru, dan hadiah prop yang Anda terima akan ditimpa oleh level baru. Saat meningkatkan atau menurunkan versi alat peraga yang disesuaikan, Anda perlu secara proaktif menghubungi layanan pelanggan dan mengajukan persyaratan penyesuaian sebelum diterbitkan, namun alat tersebut tidak akan dikeluarkan jika Anda menurunkan versi. Jumlah pesan gratis dan panggilan gratis akan bertambah seiring dengan peningkatan level. Jumlah yang sesuai akan diberikan saat Anda meningkatkan atau mempertahankan level, tetapi tidak akan diberikan saat Anda menurunkan versi. Jumlah yang didistribusikan pada setiap tingkat adalah sebagai berikut:',
  svip_table1: 'tingkat SVIP',
  vip_table1: 'tingkat VIP',
  svip_table2: 'pesan gratis',
  svip_table3: 'Panggilan gratis',
  Anonymity_name: 'anonim',
  Noble_Change_2: '1. Ketentuan pembelian: Item level rendah dapat dibeli dengan item level tinggi, tetapi item level tinggi tidak dapat dibeli dengan item level rendah. Misalnya: Jika Anda saat ini adalah seorang earl, pengguna hanya dapat membeli marquis, duke, dan king.',
  Noble_Change_3: '2. Logika konversi: Jika Anda sudah menjadi bangsawan, setelah membeli bangsawan lain, masa berlaku bangsawan lama akan diubah menjadi bangsawan baru sebanding dengan harga koin emas, dan bangsawan lama akan menjadi tidak valid.',
  Noble_Change_4: '3. Rumus perhitungan masa berlaku: Masa berlaku bangsawan yang baru dibeli = sisa hari bangsawan saat ini * (harga bangsawan saat ini/harga bangsawan yang dibeli) + 30, jumlah hari terakhir dibulatkan ke bawah',
  Noble_Change_5: '4. Kasus: Saat ini saya adalah Viscount (99990 koin emas/bulan), dengan sisa 13 hari. Saya membeli earl (199990 koin emas/bulan) hari ini. Setelah pembayaran, earl berlaku selama 13*(99990/199990) +30=6,5 dibulatkan +30=36 hari',
  rich_list: 'Daftar kaya',
  lucky_list: 'Daftar keberuntungan',
  lucky_daily: 'langit',
  lucky_weekly: 'pekan',
  lucky_faq1: 'Cara bermain',
  lucky_faq2: 'Aturan permainan',
  lucky_faq3: '1. Setelah mengirimkan hadiah keberuntungan, Anda berpeluang mendapatkan 1-1000 kali lipat hadiah koin emas;',
  lucky_faq4: '2. Semakin banyak hadiah keberuntungan yang Anda kirimkan dalam bentuk Kombo atau batch, semakin besar peluang menang;',
  lucky_faq5: '3. Hadiah koin emas akan didistribusikan ke saldo akun secara real time, dan detail kemenangan dapat dilihat di catatan hadiah keberuntungan;',
  lucky_faq6: '4. Setiap kali hadiah keberuntungan diberikan, jangkar juga akan menerima berlian dalam jumlah tertentu.',
  lucky_faq7: 'Daftar aturan',
  lucky_faq8: '1. Aturan statistik',
  lucky_faq9: 'Sepuluh pengguna yang menghabiskan koin emas paling banyak untuk berpartisipasi dalam gameplay harian dan mingguan akan masuk dalam daftar kaya, dan sepuluh pengguna dengan kelipatan kemenangan tertinggi akan masuk dalam daftar keberuntungan.',
  lucky_faq10: '2. Siklus statistik',
  lucky_faq11: 'Daftar harian: data mulai pukul 0:00 hingga 24:00 setiap hari',
  lucky_faq12: 'Daftar mingguan: data mulai pukul 0:00 setiap Senin hingga pukul 24:00 pada hari Minggu',
  lucky_faq13: '3. Buat daftar hadiah',
  lucky_faq14: 'Sepuluh teratas dalam daftar harian dan mingguan akan menerima hadiah seperti yang ditunjukkan di bawah ini',
  lucky_faq15: 'Hadiah peringkat harian',
  lucky_faq16: 'Peringkat',
  lucky_faq17: 'Hadiah peringkat mingguan',
  Charge_Same_1: 'Anda telah melakukan pengisian ulang dengan jumlah yang sama kepada pengguna ini dalam waktu singkat dan telah dikreditkan.',
  Charge_Same_2: 'Batalkan pembayaran',
  Charge_Same_3: 'tetap harus membayar',
  Noble_Change_2: '1. Ketentuan pembelian: Item level rendah dapat dibeli dengan item level tinggi, tetapi item level tinggi tidak dapat dibeli dengan item level rendah. Misalnya: Jika Anda saat ini adalah seorang earl, pengguna hanya dapat membeli marquis, duke, dan king.',
  Noble_Change_3: '2. Logika konversi: Jika Anda sudah menjadi bangsawan, setelah membeli bangsawan lain, masa berlaku bangsawan lama akan diubah menjadi bangsawan baru sebanding dengan harga koin emas, dan bangsawan lama akan menjadi tidak valid.',
  Noble_Change_4: '3. Rumus perhitungan masa berlaku: Masa berlaku bangsawan yang baru dibeli = sisa hari bangsawan saat ini * (harga bangsawan saat ini/harga bangsawan yang dibeli) + 30, jumlah hari terakhir dibulatkan ke bawah',
  Noble_Change_5: '4. Kasus: Saat ini saya adalah Viscount (99990 koin emas/bulan), dengan sisa 13 hari. Saya membeli earl (199990 koin emas/bulan) hari ini. Setelah pembayaran, earl berlaku selama 13*(99990/199990) +30=6,5 dibulatkan +30=36 hari',
  Registr_optimization_1: 'Masalah registrasi dan login',
  AnchorCenter_biList_interaction_text1: 'interaktif',
  AnchorCenter_biList_interaction_text2: 'Jumlah orang yang membangun aliansi (UV)',
  AnchorCenter_biList_interaction_text3: 'Tingkat balasan (UV)',
  AnchorCenter_biList_interaction_text4: 'Tingkat respons (PV)',
  AnchorCenter_biList_interaction_text5: 'Tingkat respons (UV)',
  AnchorCenter_biList_interaction_text6: 'Menerima pesan (uv)',
  AnchorCenter_biList_interaction_text7: 'Balasan pesan (uv)',
  AnchorCenter_biList_interaction_text8: 'Tingkat balasan (uv)',
  AnchorCenter_biList_interaction_text9: 'Kirim pesan (uv)',
  AnchorCenter_biList_interaction_text10: 'Pesan yang dibalas (uv)',
  AnchorCenter_biList_interaction_text11: 'Tingkat balasan (uv)',
  AnchorCenter_biList_interaction_text12: 'Kirim pesan (pv)',
  AnchorCenter_biList_interaction_text13: 'Pesan yang dibalas (pv)',
  AnchorCenter_biList_interaction_text14: 'Tingkat balasan (pv)',
  AnchorCenter_biList_interaction_Popup1: "Anda telah menerima pesan dari 'x' pengguna",
  AnchorCenter_biList_interaction_Popup2: "Anda telah menerima pesan dari 'x' pengguna, dan Anda telah membalas ke 'n' pengguna.",
  AnchorCenter_biList_interaction_Popup3: "Contoh: Anda menerima informasi dari 100 pengguna, dan Anda secara efektif membalas 90 dari 100 pengguna tersebut. Tingkat respons efektif (UV) Anda = 90/100 = 90%.",
  AnchorCenter_biList_interaction_Popup4: 'Persentase balasan pesan (UV)',
  AnchorCenter_biList_interaction_Popup5: 'Tingkat balasan efektif: hanya menghitung jumlah pengguna yang tidak membalas dalam jangka waktu tertentu',
  AnchorCenter_biList_interaction_Popup6: 'Jadi begitu',
  AnchorCenter_biList_interaction_Popup7: "Anda telah mengirimkan pesan yang tidak diminta ke 'x' pengguna",
  AnchorCenter_biList_interaction_Popup8: "Di antara 'x' pengguna yang Anda kirimi, 'n' pengguna merespons Anda",
  AnchorCenter_biList_interaction_Popup9: 'Persentase balasan dari pengguna lain (UV)',
  AnchorCenter_biList_interaction_Popup10: 'Contoh: Anda secara proaktif mengirimkan informasi ke 100 pengguna, dan 50 dari 100 pengguna ini telah membalas Anda. Tingkat balasan efektif (UV) = 50/100 = 50%.',
  AnchorCenter_biList_interaction_Popup11: 'Tingkat balasan efektif: hanya menghitung jumlah pengguna yang tidak membalas dalam jangka waktu tertentu',
  AnchorCenter_biList_interaction_Popup12: "Anda mengirim 'x' pesan yang tidak diminta",
  AnchorCenter_biList_interaction_Popup13: "Dari 'x' pesan yang Anda kirim secara inisiatif, 'n' telah dibalas secara efektif.",
  AnchorCenter_biList_interaction_Popup14: 'Persentase balasan dari pengguna lain (PV)',
  AnchorCenter_biList_interaction_Popup15: 'Contoh: Anda secara proaktif mengirim 100 pesan, dan 70 dari 100 pesan ini dibalas secara efektif (PV)=70/100=70%.',
  AnchorCenter_biList_interaction_Popup16: 'Tingkat balasan efektif: hanya menghitung jumlah pesan yang belum mencapai batas waktu balasan',
  program_list_24_ios: 'Hapus',
  withdraw_text_26: 'Harap verifikasi informasi pembayaran yang benar. Informasi yang salah akan mengakibatkan kegagalan penarikan, dan biaya tambahan akan ditanggung oleh pengguna.',
  withdraw_text_29: 'petunjuk:',
  withdraw_text_43: 'Ada pesanan yang belum selesai untuk metode pembayaran ini dan tidak dapat diubah atau dihapus sementara.',
  withdraw_text_44: 'Berhasil dikirimkan!',
  payment_bind_2: 'kartu bank',
  payment_bind_2_GPAY: 'kartu bank',
  payment_bind_3: 'nama Bank',
  payment_bind_4: 'Nama',
  payment_bind_5: 'Surat',
  payment_bind_6: 'alamat',
  payment_bind_7: '10 digit atau PL ditambah 10 digit',
  payment_bind_8: 'Dimulai dengan TR + 24 digit',
  payment_bind_9: '{n} Wajib',
  payment_bind_10: 'berhasil dihapus',
  payment_bind_11: 'Nama depan',
  payment_bind_12: 'nama keluarga',
  Tvwall_gift_9: 'Daftar keinginan hadiah lengkap senilai lebih dari atau sama dengan 2.000 koin emas untuk dipajang di dinding TV',
  Tvwall_gift_10: 'Kondisi layar kunci pintu masuk:',
  Tvwall_gift_32: 'Haruskah memberi hadiah ditampilkan di TV?',
  Tvwall_gift_33: '(Klik untuk memilih agar segera diterapkan)',
  Tvwall_gift_11: 'Kunci layar selama 10 detik: hadiah keinginan selesai dan nilai hadiah keinginan antara 2000 koin emas dan 9999 koin emas;',
  Tvwall_gift_12: 'Layar kunci selama 20 detik: Menyelesaikan hadiah keinginan dan nilai hadiah keinginan antara 10.000 koin emas dan 49.999 koin emas;',
  Tvwall_gift_13: 'Layar kunci selama 60 detik: Menyelesaikan hadiah permintaan dan nilai hadiah permintaan lebih dari 50.000 koin emas',
  Tvwall_gift_14: 'Ketentuan untuk tampil di Tembok TV Dunia:',
  Tvwall_gift_15: 'Catatan biasa: Menyelesaikan hadiah keinginan dan nilai hadiah keinginan adalah antara 2000 koin emas dan 9999 koin emas',
  Tvwall_gift_16: 'Catatan mewah: Menyelesaikan hadiah permintaan dan nilai hadiah keinginan lebih besar dari atau sama dengan 10.000 koin emas',
  Tvwall_gift_17: 'Di dinding TV',
  Tvwall_gift_18: 'Anonim di dinding TV',
  Tvwall_gift_19: 'Bukan di dinding TV',
  Admob_text7: 'Dalam persiapan',
  Admob_text8: 'Video belum siap',
  EXP: 'EXP',
  Experience_Card_Statement: '*Kartu pengalaman tidak memiliki manfaat ini',
  Official_website_payment_1: 'Isi Ulang Online SOYO',
  Official_website_payment_2: 'ID isi ulang',
  Official_website_payment_3: 'Masukkan IDnya',
  Official_website_payment_4: 'apa itu tanda pengenal?',
  Official_website_payment_5: 'Silakan periksa terlebih dahulu untuk melindungi privasi dan keamanan akun.',
  Official_website_payment_6: 'Pertanyaan',
  Official_website_payment_7: 'Instruksi pengguna',
  Official_website_payment_8: '1. Mohon jangan membeli koin SOYO melalui situs web tidak resmi atau tidak sah untuk menghindari perselisihan yang tidak perlu.',
  Official_website_payment_9: '2. Mohon jangan percaya informasi tidak resmi yang dipublikasikan di situs web, saluran komunikasi, atau grup komunikasi lain. Semua informasi acara harus tunduk pada situs web resmi atau pengumuman di sistem.',
  Official_website_payment_10: '3. Tidak mendukung transaksi offline apa pun.',
  Official_website_payment_11: '4. Mohon untuk tidak mengungkapkan informasi akun Anda kepada orang lain dengan alasan dan cara apa pun.',
  Official_website_payment_12: '5. SOYO tidak bertanggung jawab atas perselisihan antara pengguna dan pihak ketiga.',
  Official_website_payment_13: '6. Jika Anda mengalami masalah pengisian daya, silakan hubungi layanan pelanggan atau email kami.',
  Official_website_payment_14: 'Anti pencucian uang',
  Official_website_payment_15: '1. Pengguna harus memastikan bahwa mereka memiliki identitas hukum dan kapasitas untuk menggunakan layanan yang kami sediakan, dan tidak akan berpartisipasi atau dikaitkan dengan aktivitas kriminal apa pun.',
  Official_website_payment_16: '2. Akun pengguna hanya untuk penggunaan pribadi Anda dan milik Anda secara pribadi. Anda tidak boleh mentransfer, menyewakan, atau membuang akun Anda kepada pihak ketiga mana pun.',
  Official_website_payment_17: '3. Pengguna harus memastikan bahwa seluruh dana yang digunakan untuk pengisian ulang diperoleh melalui jalur yang sah dan tidak terkait dengan kegiatan atau perilaku kriminal sewaktu-waktu.',
  Official_website_payment_18: 'Metode pembayaran',
  Official_website_payment_19: 'Saat ini menggunakan saluran pembayaran {%s}',
  Official_website_payment_20: 'Opsi isi ulang',
  Official_website_payment_21: 'Mengubah',
  Official_website_payment_22: 'ID Akun tidak ada!',
  Official_website_payment_23: 'Belum ada metode pembayaran yang didukung',
  Official_website_payment_24: 'Umpan balik metode pembayaran yang Anda inginkan',
  Official_website_payment_25: 'Menanyakan hasil transaksi',
  Official_website_payment_26: 'Hasil isi ulang harus dikonfirmasi',
  Official_website_payment_27: 'Hasil isi ulang mungkin tertunda. Silakan masuk ke Aplikasi untuk memeriksa catatan isi ulang akun. Jika akun tidak sampai dalam waktu lama, silakan hubungi layanan pelanggan.',
  Official_website_payment_28: 'menyegarkan',
  Official_website_payment_29: 'Gagal mengisi ulang',
  Official_website_payment_30: 'Informasi bantuan:',
  Official_website_payment_31: '1. Harap konfirmasi apakah jumlah dan metode pembayaran sudah benar, termasuk informasi pribadi, nomor kartu kredit, masa berlaku, kode CVV, dll.',
  Official_website_payment_32: '2. Silakan periksa apakah saldo rekening mencukupi.',
  Official_website_payment_33: '3. Periksa apakah koneksi jaringan stabil. Terkadang masalah jaringan dapat menyebabkan kegagalan pembayaran.',
  Official_website_payment_34: '4. Coba ubah ke metode pembayaran lain atau coba isi ulang nanti.',
  Official_website_payment_35: '5. Jika masalah masih belum teratasi, disarankan untuk menghubungi layanan pelanggan platform isi ulang untuk bantuan lebih lanjut.',
  Official_website_payment_36: 'Isi ulang berhasil',
  Official_website_payment_37: 'Silakan masuk ke Aplikasi untuk melihat catatan isi ulang akun',
  Official_website_payment_38: 'Batas waktu tautan gagal',
  Official_website_payment_39: 'Tautan telah kedaluwarsa. Harap buat ulang tautan pembayaran di Aplikasi.'
};


export default ID;
