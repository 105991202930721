import { fetchHaloLevel, fetchHaloIcon } from '@/services/api';

function formatData(data) {
  if (!data) return {};
  let res = {};
  let arr = Object.keys(data);
  if (arr && arr.length > 0) {
    res.level = getLevel(arr[0]);
    res.url = data[arr[0]];
    return res;
  }
  return {};
}

function getLevel(l) {
  if (!l) return '';
  let arr = l.split('.');
  if (isNaN(Number(arr[1]))) {
    return '';
  } else {
    return Number(arr[1]);
  }
}

const haloLevel = {
  namespace: 'haloLevel',
  state: {
    curLevel: '',
    curExp: '',
    allExp: '',
    avatar: '',
    levelImg: [],
    levelReward: [],
  },
  reducers: {
    updateUserLevel(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *getUserLevel({ payload }, { call, put }) {
      const { error, data } = yield call(fetchHaloLevel, payload);
      if (error) {
        return error;
      }
      yield put({
        type: 'updateUserLevel',
        payload: {
          curLevel: data.userLevel || 1,
          curExp: data.userLevelValue || 0,
          allExp: data.userNextLevelValue || 0,
          avatar: data.headAvatar || '',
        },
      });
      return true;
    },
    *getUserIcon({ payload }, { call, put }) {
      const { error, data } = yield call(fetchHaloIcon, payload);
      if (error) {
        return error;
      }
      if (!data) return;
      let json = {};
      try {
        json = JSON.parse(data);
      } catch (error) {
        json = {};
      }
      yield put({
        type: 'updateUserLevel',
        payload: {
          levelImg: json.iconDisplay && json.iconDisplay.length > 0 ? json.iconDisplay : [],
          levelReward: json.reward && json.reward.length > 0 ? json.reward.map(item => formatData(item)) : [],
        },
      });
      return true;
    },
  },
};

export default haloLevel;
