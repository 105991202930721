import { fetchData } from './api';

export const fetchTotalRank = async params => fetchData('GET', '/api/voice/content/ranking/getRankingList', params);

export const fetchGiftTotalRank = async params => fetchData('GET', '/api/voice/content/ranking/getPopularGiftRankingList', params);

export const hideUserInRank = async params => fetchData('GET', '/api/voice/content/ranking/updateUserRankingListStatus', params);

export const fetchTotalRankVoice = async params => fetchData('GET', '/api/voice/content/ranking/getRankingList/v2', params);

export const fetchGiftTotalRankVoice = async params => fetchData('GET', '/api/voice/content/ranking/getPopularGiftRankingList/v2', params);

export const hideUserInRankVoice = async params => fetchData('GET', '/api/voice/content/ranking/updateUserRankingListStatus', params);
