// 常用常量配置
export const succBizCode = 10000;
// 系统错误
export const systemErrCode = -99999;
// 超时
export const timeoutErrCode = -99998;

export const cancelErrCode = -99997;

export const networkErrCode = -99996;
