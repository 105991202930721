// 单页面项目入口文件
import { useRoutes } from 'react-router-dom';
import useRootSize from './asset/js/hooks/useRootSize';
import routes from './route';

const App = () => {
  // useRootSize(375); // 默认 设计稿 375px
  const element = useRoutes(routes);
  return element;
};

export default App;
