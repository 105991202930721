import { createBrowserHistory, createHashHistory } from 'history';
import { jumpWidthCountry } from '@/asset/js/utils';

const isSupportHistory = window.history && window.history.pushState;
const history = isSupportHistory ? createBrowserHistory() : createHashHistory();
const replace = history.replace.bind(history);
const push = history.push.bind(history);

function detailUrl(url) {
  if (!url) {
    return url;
  }
  if (typeof url === 'string') {
    url = jumpWidthCountry(url);
  } else if (url.pathname) {
    url.search = jumpWidthCountry(url.search);
  }
  return url;
}

export default Object.assign(history, {
  replace(url, state, isDetailCountry = true) {
    if (isDetailCountry) {
      return replace(detailUrl(url), state);
    }
    return replace(url, state);
  },
  push(url, state, isDetailCountry = true) {
    if (isDetailCountry) {
      return push(detailUrl(url), state);
    }
    return push(url, state);
  },
});
