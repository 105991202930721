import { fetchTotalRankVoice as fetchTotalRank, fetchGiftTotalRankVoice as fetchGiftTotalRank, hideUserInRankVoice as hideUserInRank } from '@/services/totalRank';

function getKey(type) {
  switch (type) {
    case 'room':
      return 1;
    case 'sent':
      return 2;
    case 'received':
      return 3;
    case 'gift':
      return 4;
    default:
      return null;
  }
}

function getMode(key) {
  switch (key) {
    case 'hour':
      return 0;
    case 'day':
      return 1;
    case 'week':
      return 2;
    case 'month':
      return 3;
    default:
      return null;
  }
}

function formatRank(data, type) {
  if (!data) return {};
  return {
    top3: data.beforeRankingListTop3 && data.beforeRankingListTop3.length > 0 ? formatTop3(data.beforeRankingListTop3, type) : [],
    top50: data.rankingListTop50 && data.rankingListTop50.length > 0 ? formatTop50(data.rankingListTop50, type) : [],
    user: data.rankingListInfoVO ? formatUser(data.rankingListInfoVO) : {},
    time: data.residueTime || 0,
    userHidden: data.rankingListInfoVO && data.rankingListInfoVO.userStealthStatus === 1,
  };
}

function formatTop3(data, type) {
  let res = [false, false, false];
  if (data[1]) {
    res[0] = { key: 'second', ...fromatData(data[1], type) };
  }
  if (data[0]) {
    res[1] = { key: 'first', ...fromatData(data[0], type) };
  }
  if (data[2]) {
    res[2] = { key: 'third', ...fromatData(data[2], type) };
  }
  return res;
}

function formatTop50(data, type) {
  return data.map(item => fromatData(item, type));
}

function fromatData(data, type) {
  return {
    ...data,
    hide: data.userStealthStatus === 1,
    image: type === 'room' ? data.roomHeadAvatar : data.headAvatar,
    online: type === 'room' ? false : !!data.currentRoomId,
    name: type === 'room' ? data.roomName : data.nickName,
    showId: type === 'room' ? data.currentRoomId : '',
    id: type === 'room' ? data.roomId : data.userId,
    score: data.score,
  };
}

function formatUser(data) {
  return {
    ...data,
    index: data.curSort && data.curSort >= 1 ? data.curSort : -1,
    id: data.userId,
    name: data.nickName,
    hide: data.userStealthStatus === 1,
    avatar: data.headAvatar,
  };
}

function formatGiftRank(data) {
  return {
    time: data.residueTime || 0,
    user: data.rankingListInfoVO ? formatUser(data.rankingListInfoVO) : {},
    top50: data.rankingListTop50 && data.rankingListTop50.length > 0 ? formatTop50(data.rankingListTop50) : [],
    userHidden: data.rankingListInfoVO && data.rankingListInfoVO.userStealthStatus === 1,
  };
}

const totalRank = {
  namespace: 'totalRankAll',
  state: {
    room: {},
    sent: {},
    received: {},
    gift: {},
    giftTab: [],
  },
  reducers: {
    updateRank(state, { payload }) {
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          [payload.mode]: formatRank(payload.value, payload.key),
        },
      };
    },
    updateGiftTab(state, { payload }) {
      return {
        ...state,
        giftTab: payload,
      };
    },
    updateGiftRank(state, { payload }) {
      return {
        ...state,
        gift: payload,
      };
    },
    updateUserHidden(state, { payload }) {
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          [payload.mode]: {
            ...state[payload.key][payload.mode],
            userHidden: payload.value,
          },
        },
      };
    },
    updateGiftUserHidden(state, { payload }) {
      return {
        ...state,
        gift: {
          ...state.gift,
          userHidden: payload,
        },
      };
    },
  },
  effects: {
    *getTotalRank({ payload }, { call, put }) {
      const { rankType, rankTimeType } = payload;
      const { error, data } = yield call(fetchTotalRank, {
        rankType: getKey(rankType),
        rankTimeType: getMode(rankTimeType),
      });
      if (error) {
        return error;
      }
      if (!data) {
        return;
      }
      yield put({
        type: 'updateRank',
        payload: {
          key: rankType,
          mode: rankTimeType,
          value: data,
        },
      });
      return true;
    },
    *getGiftTotalRank({ payload }, { call, put }) {
      const { error, data } = yield call(fetchGiftTotalRank, { giftId: payload });
      if (error) {
        return error;
      }
      if (!data) {
        return;
      }
      yield put({
        type: 'updateGiftRank',
        payload: formatGiftRank(data),
      });
      yield put({
        type: 'updateGiftTab',
        payload: data && data.giftVoList ? data.giftVoList : [],
      });
      return true;
    },
    *hide({ payload }, { call, put }) {
      const { status, rankType, rankTimeType } = payload;
      const { error } = yield call(hideUserInRank, { userStatus: status ? 1 : 0 });
      if (error) {
        return error;
      }
      if (rankType === 'gift') {
        yield put({
          type: 'updateGiftUserHidden',
          payload: status,
        });
      } else {
        yield put({
          type: 'updateUserHidden',
          payload: {
            key: rankType,
            mode: rankTimeType,
            value: status,
          },
        });
      }
      return true;
    },
  },
};

export default totalRank;
