import { getValueFromUA } from '@/asset/js/utils';
const config = window.config || {};
window.config = config;
export const initCountry = () => {
  // const userAgent =  'Mozilla/5.0 (Linux; Android 10; Infinix X680B Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/95.0.4638.74 Mobile Safari/537.36  company/soulu client/soulu country/cn lang/en version/1.0 build/1 android/10 manufacturer/INFINIX+MOBILITY+LIMITED model/Infinix+X680B channel/null deviceId/null _MAPP_/null';
  const userAgent = navigator.userAgent || '';
  // const isNewUA = userAgent.toLowerCase().includes('team');
  // if (isNewUA) {
  //   clientUaTest = /(?:team\/soulu)(?:.*)\s+uCountry\/(\w+)\s+uLanguage\/(\w+)/;
  // } else {
  // }
  // const clientUaTest = /(?:company\/soulu\sclient\/soulu)(?:.*)\s+country\/(\w+)\s+lang\/(\w+)/;
  // const clientCL = userAgent.match(clientUaTest);
  let clientLang;
  let clientCountry;
  // if (clientCL && clientCL[1] && clientCL[2]) {
  //   clientLang = clientCL[2];
  //   clientCountry = clientCL[1];
  // } else {
  //   clientLang = getValueFromUA('uLanguage');
  //   clientCountry = getValueFromUA('uCountry');
  // }
  clientLang = getValueFromUA('lang');
  clientCountry = getValueFromUA('country');
  const params = URL.getPara(true);
  let paramLanguage;
  let paramCountry;
  if (params.lang) {
    paramLanguage = params.lang;
  }
  if (params.country) {
    paramCountry = params.country;
  }
  config.lang = paramLanguage || clientLang || 'en';
  config.country = paramCountry || clientCountry || null;
};
initCountry();
