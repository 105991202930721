import Apps from './core';
import Helper from './helper';
import './css/share.css';
// 扩展工具函数
Helper.extend(Helper, {
  showLayout(app, id, htmlMaker) {
    const layoutId = 'shareBoxLayout';
    Helper.remove('#' + layoutId + ',#shareBoxImgLoading');
    Helper.append(document.body, '<div id="' + layoutId + '"></div>');
    const layout = document.body.querySelector('#' + layoutId);
    Helper.addClass(layout, 'appShare_' + app);
    // 延迟绑定，防止被透点
    window.setTimeout(() => {
      layout.addEventListener('click', Helper.hideLayout, false);
    }, 800);
  },
  hideLayout() {
    Helper.remove('#shareBoxLayout');
  },
  preloadLayout() {
    if (!document.body.querySelector('#shareBoxImgLoading')) {
      Helper.ready(() => {
        const div = document.createElement('div');
        div.id = 'shareBoxImgLoading';
        document.body.appendChild(div);
      });
    }
    this.preloadLayout = function() {};
  },
});

// 检查转化分享对象
const checkShareObj = function(shareConf) {
  const conf = (Helper.isFunction(shareConf) ? shareConf() : shareConf) || {};
  // 2015-04-16 增加对相对图片地址的支持
  // 2015-07-17 马超 增加对 // 格式图片路径支持
  conf.img = (conf.img || conf.image || '').replace(/^\/\//, window.location.protocol + '//');
  const img = conf.img;
  if (img && !/^http/i.test(img)) {
    conf.img = document.URL.match(img.substr(0, 1) === '/' ? /^http.+?\/\/[^/]+/ : /^http.+?\/\/.+\//)[0] + img;
  }
  // 映射标准字段，并保留扩展字段
  return Helper.extend(
    {
      title: document.title,
      linkUrl: conf.linkUrl || conf.url || document.URL,
      text: conf.text || conf.title,
      imageUrl: conf.img || conf.imageUrl,
      via: conf.via,
      quote: conf.quote,
      hashtag: conf.hashtag,
    },
    conf,
    {
      // 按照key顺序获取share的内容
      get(...keys) {
        let val;
        const share = this;
        keys.forEach(key => {
          val = val || share[key];
        });
        return val;
      },
    }
  );
};

/**
 * @name  AppCore.share
 * @param {object|function} obj_or_fn 分享配置，内容包括：title / link(url) / desc(content) / img(image)<br>
 *                                    可以为函数，即每次分享的时候，获取最新的分享内容
 * @param {function} [callback=noop] 分享回调，未必会触发，依赖于执行环境
 * @description AppCore分享模块统一接口，依赖其他子模块挂接<br>调用接口后，会绑定 .share 元素，或链接为 #share 或 cmd属性为share 的元素点击行为
 * @function
 *
 * @example
 * AppCore.share({
 *     img : "xxx.jpg",
 *     url : "xxx.html",    //可选，默认 document.URL
 *     title : "这是分享标题",
 *     desc : "这是分享内容"
 * });
 *
 * @example
 * AppCore.share(function(){
 *     return shareObj;
 * }, function(ret, txt){
 *     alert("分享"+ (ret ? "成功":"失败"));
 * });
 */
let hookObj;
Apps.regHook(
  'share',
  (hookObj = {
    defaultFn() {
      console.log('当前环境暂时不支持分享');
    },
    run(fn, appName, cache, obj, callback) {
      const shareNow = Helper.isFunction(cache.shareNow) ? cache.shareNow : hookObj.defaultFn;
      // 将包装后的参数传给app的share函数
      fn(
        () => checkShareObj(obj),
        (ret, message, ...arg) => {
          if (Helper.isFunction(callback)) {
            // 如果有回调，则关闭蒙层提示，2016-02-02 马超 增加
            Helper.hideLayout();
            callback(
              !!{
                ok: 1,
                success: 1,
                confirm: 1,
                true: 1,
              }[ret],
              message,
              ...arg
            );
          }
        }
      );
      // 绑定页面监听
      hookObj.shareNow = shareNow;
      if (!this.bindDocListen && !(obj || {}).initOnly) {
        this.bindDocListen = 1;
        Helper.ready(() => {
          Helper.delegate(document.body, 'click', ".share,a[href*='#share'],a[href='share://'],[cmd=share]", e => {
            hookObj.shareNow(e);
            e.preventDefault();
          });
        });
      }
    },
  })
);
