import Apps from './core';
import Helper, { AppCore } from './helper';
// 注册login的钩子
import './login';
// 注册分享钩子
import './share';

// AppCore对外提供的方法
Helper.extend(AppCore, {
  /**
   * @name AppCore.getAppName
   * @param  {boolean} [getAll=false]  是否返回所有命中的环境名称
   * @return {string|string[]} 默认返回当前最为匹配的一个环境名称，如果getAll为true，则返回所有命中的环境名称，比如 ["weixin", "wap"]
   * @description 查询当前的环境名称，返回诸如 weixin、yixin 等字符串标识符，或 wap；
   * @function
   */
  getAppName(getAll) {
    const appNames = Apps.is();
    return getAll ? appNames : appNames[0];
  },
  /**
   * @name AppCore.findMethodOwner
   * @param  {string} method  要查询的方法名
   * @return {string} 返回指定方法名所属的环境
   * @description 查找命中的环境配置中，有配置方法的数据
   * @function
   */
  findMethodOwner(method) {
    return Apps.findMethodOwner(method);
  },
  /**
   * @privateEntry {boolean} [privateEntry=false] 是否注册到私有入口： AppCore[appName] - 私有接口即只存在于某个环境下
   * @param {string} appName 注册的环境标识，比如 weixin
   * @param {object} appConfig 注册的配置内容，其中<br>
   *                           appConfig.is 方法作为判断环境之用，不引用到任何入口<br>
   *                           appConfig.version 方法仅仅添加到私有入口中<br>
   *                           appConfig.ready 方法仅仅添加到私有入口中<br>
   *                           以 _ 开头的方法会被忽略<br>
   *                           非函数成员会被忽略<br>
   *                           其他方法的同名代理方法会被扩展到 AppCore 上<br>
   *                           如果privateEntry为true，则同步扩展到 AppCore[appName] 上
   * @description 注册一个App到AppCore
   */
  reg(privateEntry, name, config) {
    return Apps.reg(privateEntry, name, config);
  },
  /**
   * @name AppCore.setCacheIndex
   * @param {function} fn	cacheIndex会被当作参数传递给此函数，返回的数组作为新的cacheIndex
   * @description 调整cacheIndex的元素顺序
   * @function
   */
  setCacheIndex(fn) {
    return Apps.setCacheIndex(fn);
  },
});


Apps.reg('lumos', {
  // 私有核心方法，不暴露
  is(ua, os) {
    // console.log(ua, 'ua')
    return ua.indexOf('app_name/lumos') >= 0 || ua.indexOf('client/soulu') >= 0;;
  },
  // 获取app的协议，
  // 如  morebuzz:// 所有客户端必须实现该方法，否则cmd命令无法使用
  getPrivateProto() {
    return 'lumos://';
  },
  /**
   * @name  AppCore.caipiao.version
   * @description 从UA中获取客户端版本，如果非客户端，则返回0
   * @param  {boolean} [getMainVersion=false] 是否获取主版本号，默认获取build号
   * @return {number}  彩票App版本号
   * @function
   * @example
   * var build = AppCore.caipiao.version();
   * //build = 4300;
   */
  version(getMainVersion) {
    // 框架提供的辅助对象
    let version = 0;
    let build = 0;
    if (/(?:company\/lumos\sclient\/lumos)(?:.*)\s+version\/([^\s]+)\s+versionint\/(\d+)/.test(Helper.ua)) {
      version = RegExp.$1;
      build = +RegExp.$2;
    }
    return getMainVersion ? version : build;
  },
  /**
   * @name AppCore.caipiao.ready
   * @description 彩票JS Bridge准备OK事件挂接
   * @param {function} fn 绑定的ready事件
   * @function
   */
  ready(fn) {
    if (window.iOSJSApi) {
      console.log(window.iOSJSApi, '(window.iOSJSApi')
      return fn(window.iOSJSApi, true);
    }
    if (window.AFJsApi) {
      return fn(window.AFJsApi);
    }
    // const supportReady = Helper.checkBuild('soulu', {
    //   ios: 1,
    //   android: 1,
    // });
    const supportReady = true;
    if (supportReady) {
      document.addEventListener('LDMJsBridgeReady', () => {
        console.log(window.iOSJSApi, '(window.iOSJSApi-LDMJsBridgeReady')
        fn(window.iOSJSApi);
      });
    } else {
      Helper.ready(() => fn(window.iOSJSApi));
    }
  },

  // 简易url参数处理工具，仅内部使用
  _rmUrlPara(url, paraArr) {
    url = url.replace(/#.+$/, '');
    paraArr.forEach(key => {
      url = url.replace(new RegExp('(\\?|&)' + key + '=[^&|$]*', 'gi'), '$1');
    });
    return url
      .replace(/\?&/, '?')
      .replace(/&{1,}/g, '&')
      .replace(/&$/, '');
  },
});

Apps.reg('lumos', {
  report(result, callback) {
    console.log(result, 'result')
    // 参数result  ：json
    AppCore.lumos.ready((jsapi, isIos) => {
      if (isIos) {
        jsapi.common.eventReport(result);
        return;
      }
      jsapi.cmd.report(result);
    });
  },
  rechargeClose(result) {
    // 参数result  ： true ，false
    AppCore.lumos.ready((jsapi, isIos) => {
      console.log('rechargeClose')
      if (isIos) {
        jsapi.common.pageClose(result);
        return;
      }
      jsapi.pay.close(result);
    });
  },
  getStatusBarHeight(platform) {
    return new Promise((reslove, reject) => {
      try {
        AppCore.lumos.ready((jsapi) => {
          if ((jsapi.cmd.getStatusBarHeight && typeof jsapi.cmd.getStatusBarHeight === 'function') || typeof jsapi?.common?.getStatusBarHeight === 'function') {
            if (platform === 'android') {
              jsapi.cmd.getStatusBarHeight({}, res => {
                if (res) {
                  console.log('androidBarHeight', res);
                  reslove(res);
                } else {
                  reject('');
                }
              });
            } else {
              const res = jsapi.common.getStatusBarHeight();
              if (res) {
                console.log('iosBarHeight', res);
                reslove(res);
              } else {
                reject('');
              }
            }
          } else {
            reject('');
          }
        });
      } catch (error) {
        reject('');
      }
    });
  },
  goBack(obj) {
    AppCore.lumos.ready((jsapi, isIos) => {
      if (isIos) {
        jsapi.common.pageGoBack && jsapi.common.pageGoBack(obj);
        return;
      }
      jsapi.cmd.goBack && jsapi.cmd.goBack(obj);
    });
  },
});

window.AppCore = AppCore;
export default AppCore;
