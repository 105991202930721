// 下拉刷新上拉加载模块
export default {
  // 下拉刷新准备状态
  'pull.refresh.deactivate': 'Pull to refresh',
  'pull.refresh.activate': 'Release to refresh',
  'pull.refresh.release': 'Loading more',
  // 上拉加载
  'push.refresh.activate': 'Loading more',
  'push.refresh.noMore': 'No more info',
  'click.refresh': 'Click to refresh',
};
