import { fetchDetailInfo, fetchCoinBackDetail } from '@/services/goldCoin';

const goldCoin = {
  namespace: 'goldCoin',
  state: {},
  reducers: {},
  effects: {
    *getDetailInfo({ payload }, { call }) {
      const res = yield call(fetchDetailInfo, payload);
      return res;
    },
    *getCoinBackDetail({ payload }, { call }) {
      const res = yield call(fetchCoinBackDetail, payload);
      return res;
    },
  },
};

export default goldCoin;
