import * as api from '@/services/api';
import { Toast } from 'antd-mobile';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  namespace: 'global',
  state: {
    userInfo: {},
  },
  reducers: {
    updateUserInfo(state, { payload }) {
      return {
        ...state,
        userInfo: {
          level: payload.level || '',
          userViewId: payload.userViewId || '',
          country: payload.country || '',
          vipLevel: payload.vipLevel || '',
          countryPhoneCode: payload.countryPhoneCode || '',
          phone: payload.phone || '',
          gender: payload.gender === 0 ? 'woman' : payload.gender === 1 ? 'man' : 'unknown',
          userId: payload.userId || '',
          nickName: payload.nickName || '',
          headAvatar: payload.headAvatar || '',
          customerServiceGroupId: payload.customerServiceGroupId || '',
          customerServicePersonalId: payload.customerServicePersonalId || '',
        },
      };
    },
  },
  effects: {
    *getUserInfoByShowId({ payload }, { call, put }) {
      const { data } = yield call(api.getUserInfoById, payload);
      if (data && data.userId) {
        yield put({
          type: 'updateUserInfo',
          payload: data,
        });
        return data;
      } else {
        yield put({
          type: 'updateUserInfo',
          payload: {},
        });
        return false;
      }
    },
    *upload({ payload }, { call }) {
      const data = yield call(api.uploadImage, payload);
      return data;
    },
  },
};
