import { fetchData } from './api';
import { getValueFromUA } from '@/asset/js/utils';

export const setCoinPassword = async params => fetchData('POST', '/api/user/coin-trader/password/setup', params);

export const editCoinPassword = async params => fetchData('PUT', '/api/user/coin-trader/password/change', params);

export const checkCoinPassword = async params => fetchData('POST', '/api/user/coin-trader/password/verify', params);

export const checkCoinStatus = async params => fetchData('GET', '/api/user/coin-trader/password/needs-setup', params);

export const sendCoin = async params => fetchData('POST', '/api/user/coin-trader/wallet/send/coin', params);

export const fetchCoinSendHistory = async params => fetchData('GET', '/api/user/coin-trader/wallet/trade/detail', params);

export const fetchCoinMerchantCoins = async params => fetchData('GET', '/api/user/coinUser/getCoinAccountInfo', params);

export const fetchUserInfoByShowId = async params => fetchData('GET', '/api/user/snackUser/getUserSimpleByViewId', params);

export const fetchTransferList = async params => fetchData('GET', '/api/user/coinUser/configInfo', params);

// export const fetchNewCoinRechargeListV2 = async params => fetchData('GET', '/api/order/product/coinRechargeList/v2', params);

export const fetchNewCoinRechargeListV2 = async params => fetchData('GET', '/api/order/product/coinRechargeList', params);

export const fetchPayChannelList = async params =>
  fetchData('GET', '/api/order/wap/payChannel/list/coin-seller', params, {
    headers: {
      appPlatform: getValueFromUA('appPlatform') === 'android' ? 1 : 2,
    },
  });

export const recharge = async params => {
  const { userId, ...other } = params;
  return fetchData('POST', '/api/order/wap/init', other, {
    headers: {
      appPlatform: 3,
      userId,
    },
  });
};

export const sendCheckSmsCode = async params => fetchData('POST', '/api/user/snackUser/sendVerifyCode', params);

export const checkVerifyCode = async params => fetchData('POST', '/api/user/snackUser/checkVerifyCode', params);

export const getPayUser = async params => fetchData('POST', '/api/user/coin-trader/wallet/trade/detail/users', params);

export const getUserArea = async params => fetchData('GET', '/api/user/snackUser/getUserArea', params);

export const fetchPayAreaDefaultCountry = async params => fetchData('GET', '/api/order/wap/payChannel/list/v2/improve', params);

export const fetchCoinMain = async params => fetchData('GET', '/api/user/coin-trader/home-page', params);

export const fetchCoinConfig = async params => fetchData('GET', '/api/user/coin-trader/config/recommend', params);

export const fetchCoinCustomer = async params => fetchData('GET', '/api/user/coin-trader/config/whats-app/customer', params);

export const sendCheck = async params => fetchData('POST', '/api/user/coin-trader/wallet/send/coin/check', params);
