import { tvStatus, tvConfig } from '@/services/api';

const TVWall = {
  namespace: 'tvWall',
  state: {
    bankList: [],
  },
  effects: {
    *submit({ payload }, { call }) {
      const { error, code } = yield call(tvStatus, payload);
      if (error) {
        return {error, code};
      }
      return true;
    },
    *tvConfig({ payload }, { call }) {
      const { error, code, data } = yield call(tvConfig, payload);
      if (error) {
        return {error, code};
      }
      return data;
    },
  },
};

export default TVWall;
