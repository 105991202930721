// 下拉刷新上拉加载模块
export default {
  // 下拉刷新准备状态
  'pull.refresh.deactivate': 'جر للتجديد',
  'pull.refresh.activate': 'اطلق للتجديد',
  'pull.refresh.release': 'جاري تحميل المزيد',
  // 上拉加载
  'push.refresh.activate': 'جاري تحميل المزيد',
  'push.refresh.noMore': 'لا يوجد المزيد من المعلومات',
  'click.refresh': 'إعادة التحميل',
};
