import { channelBind, channelList, channelUnbind, channelBindConfig } from '@/services/api';

const withdrawBind = {
  namespace: 'withdrawBind',
  state: {
    bankList: [],
  },
  reducers: {
    updateChannel(state, { payload }) {
      return {
        ...state,
        bankList: payload,
      };
    },
  },
  effects: {
    *submit({ payload }, { call }) {
      const { error, code } = yield call(channelBind, payload);
      if (error) {
        return {error, code};
      }
      return true;
    },
    *channelList({ payload }, { call }) {
      const { error, data = [] } = yield call(channelList, payload);
      if (error) {
        return [];
      }
      return data;
    },
    *channelBindConfig({ payload }, { call, put }) {
      const { error, data } = yield call(channelBindConfig, payload);
      if (error) {
        return error;
      }
      yield put({
        type: 'updateChannel',
        payload: data?.bankConfigVoList || [],
      })
      return data;
    },
    *channelUnbind({ payload }, { call }) {
      const { error, code } = yield call(channelUnbind, payload);
      if (error) {
        return {error, code};
      }
      return true;
    },
  },
};

export default withdrawBind;
