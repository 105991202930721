import {
  setCoinPassword,
  editCoinPassword,
  checkCoinPassword,
  checkCoinStatus,
  sendCoin,
  fetchCoinSendHistory,
  fetchCoinMerchantCoins,
  fetchUserInfoByShowId,
  fetchTransferList,
  fetchNewCoinRechargeListV2,
  fetchPayChannelList,
  recharge,
  sendCheckSmsCode,
  checkVerifyCode,
  getPayUser,
  getUserArea,
  fetchPayAreaDefaultCountry,
  fetchCoinMain,
  fetchCoinConfig,
  fetchCoinCustomer,
  sendCheck,
} from '@/services/coinMerchant';
import md5 from 'blueimp-md5';
import countryList from '@/config/country';
import { Toast } from 'antd-mobile';
import { rechargeClickResult } from '@/page/coinMerchant/action';

const coinMerchant = {
  namespace: 'coinMerchant',
  state: {
    hasPassword: false,
    history: [],
    coins: null,
    lastId: null,
    userInfo: null,
    list: [],
    rechargeList: [],
    coutryList: [],
    pageIndex: 1,
    pageSize: 20,
    isMore: false,
    userList: [],
    area: '',
    areaDefaultCountry: '',
  },
  reducers: {
    updateList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    updateUserInfo(state, { payload }) {
      return {
        ...state,
        userInfo: payload,
      };
    },
    updateHasPassword(state, { payload }) {
      return {
        ...state,
        hasPassword: payload,
      };
    },
    updateLastId(state, { payload }) {
      return {
        ...state,
        lastId: payload,
      };
    },
    updateCoins(state, { payload }) {
      return {
        ...state,
        coins: payload,
      };
    },
    updateRechargeList(state, { payload }) {
      return {
        ...state,
        rechargeList: payload,
      };
    },
    updateHistory(state, { payload = [] }) {
      return {
        ...state,
        history: payload,
      };
    },
    updateChannels(state, { payload = [] }) {
      const temp = [];
      payload.forEach(item => {
        const country = countryList.find(val => val.alpha2Code == item.country);
        temp.push({
          ...item,
          ...country,
        });
      });
      return {
        ...state,
        coutryList: temp,
      };
    },
    updateUserList(state, { payload }) {
      let newList = payload.force ? [] : [...state.userList];
      if (payload && payload.records && payload.records.length) {
        newList = [...newList, ...payload.records];
      }
      return {
        ...state,
        userList: newList,
        pageIndex: payload.pageIndex,
        isMore: newList.length < payload.total,
      };
    },
    updateArea(state, { payload }) {
      return {
        ...state,
        area: payload || '',
      };
    },
    updateDefaultCountry(state, { payload }) {
      return {
        ...state,
        areaDefaultCountry: payload || '',
      };
    },
  },
  effects: {
    *sendCheck({ payload }, { call }) {
      const data = yield call(sendCheck, payload);
      return data;
    },
    *fetchCoinConfig({ payload }, { call }) {
      const { error, data } = yield call(fetchCoinConfig, payload);
      if (error) {
        return error;
      }
      return data;
    },
    *fetchCoinCustomer({ payload }, { call }) {
      const { error, data } = yield call(fetchCoinCustomer, payload);
      if (error) {
        return error;
      }
      return data;
    },
    *fetchCoinMain({ payload }, { call, put }) {
      const { error, data } = yield call(fetchCoinMain, payload);
      if (error) {
        return error;
      }
      yield put({
        type: 'updateCoins',
        payload: data ? data.coinsBalance : null,
      });
      yield put({
        type: 'updateList',
        payload: data?.coinTransferLevels || [],
      });
      return true;
    },
    *fetchList({ payload }, { call, put }) {
      const { error, data } = yield call(fetchTransferList, payload);
      if (error) {
        return error;
      }
      yield put({
        type: 'updateList',
        payload: data && data.length > 0 ? data : [],
      });
      return true;
    },
    *sendCoin({ payload }, { call }) {
      const res = yield call(sendCoin, {
        ...payload,
        password: md5(payload.password),
      });
      const { error, code, data } = res;
      if (error && code != 1) {
        return {
          success: false,
          error,
          code,
          data,
        };
      } else {
        return {
          data,
          success: true,
        };
      }
    },
    *checkCoinPassword({ payload }, { call }) {
      const { error, data } = yield call(checkCoinPassword, { password: md5(payload) });
      if (error) {
        return error;
      }
      return data;
    },
    *setCoinPassword({ payload }, { call }) {
      const { error } = yield call(setCoinPassword, {
        password: md5(payload.p),
        confirm: md5(payload.cp),
      });
      if (error) {
        return error;
      }
      return true;
    },
    *editCoinPassword({ payload }, { call }) {
      const { error } = yield call(editCoinPassword, {
        originPassword: md5(payload.p),
        newPassword: md5(payload.np),
        confirm: md5(payload.cnp),
      });
      if (error) {
        return error;
      }
      return true;
    },
    *checkCoinStatus({ payload }, { call, put }) {
      const { error, data } = yield call(checkCoinStatus, payload);
      if (error) {
        return error;
      }
      yield put({
        type: 'updateHasPassword',
        payload: data === 2,
      });
      return data === 2;
    },
    *getCoinSendHistory({ payload }, { call, put }) {
      const { error, data } = yield call(fetchCoinSendHistory, payload);
      if (error) {
        return error;
      }
      if (data && data.length > 0) {
        yield put({
          type: 'updateHistory',
          payload: data,
        });
        yield put({
          type: 'updateLastId',
          payload: data[data.length - 1].detailId,
        });
      } else {
        yield put({
          type: 'updateHistory',
          payload: [],
        });
        yield put({
          type: 'updateLastId',
          payload: null,
        });
      }
    },
    *pullCoinSendHistory({ payload }, { call, put, select }) {
      const { history } = yield select(state => state.coinMerchant);
      const { error, data } = yield call(fetchCoinSendHistory, payload);
      if (error) {
        return error;
      }
      if (data && data.length > 0) {
        yield put({
          type: 'updateHistory',
          payload: [...history, ...data],
        });
        yield put({
          type: 'updateLastId',
          payload: data[data.length - 1].detailId,
        });
      } else {
        yield put({
          type: 'updateLastId',
          payload: null,
        });
      }
    },
    *fetchUserCoins({ payload }, { call, put }) {
      const { error, data } = yield call(fetchCoinMerchantCoins, payload);
      if (error) {
        return error;
      }
      yield put({
        type: 'updateCoins',
        payload: data ? data.coinsBalance : null,
      });
      return true;
    },
    *fetchUserCoinsAgain({ payload }, { call, put }) {
      const { error, data } = yield call(fetchCoinMerchantCoins, payload);
      if (error) {
        return error;
      }
      yield put({
        type: 'updateCoins',
        payload: data ? data.coinsBalance : null,
      });
      return true;
    },
    *getUserInfoByShowId({ payload }, { call, put }) {
      const res = yield call(fetchUserInfoByShowId, payload);
      const { error, data, code } = res;
      const params = {
        extra: 'inquiry',
        extra1: payload.userViewId,
        label: error ? 'fail' : 'success',
      };
      if (error) {
        params.extra3 = code;
        params.actionParam = error;
      }
      rechargeClickResult(params);
      if (error) {
        Toast.show({
          content: error,
        });
        return error;
      }
      if (data && data.userId) {
        yield put({
          type: 'updateUserInfo',
          payload: data,
        });
        return data;
      } else {
        yield put({
          type: 'updateUserInfo',
          payload: null,
        });
        return false;
      }
    },
    *getCoinRechargeList({ payload }, { call, put }) {
      const { error, data } = yield call(fetchNewCoinRechargeListV2, payload);
      if (error) {
        yield put({
          type: 'updateRechargeList',
          payload: [],
        });
        return error;
      }
      yield put({
        type: 'updateRechargeList',
        payload: data && data.length ? data : null,
      });
      return data;
    },
    *getPayChannelList({ payload }, { call, put }) {
      const { error, data } = yield call(fetchPayChannelList, payload);
      if (error) {
        Toast.show({
          content: error,
        });
        yield put({
          type: 'updateChannels',
          payload: [],
        });
        return false;
      }
      if (data && data.length > 0) {
        yield put({
          type: 'updateChannels',
          payload: data,
        });
        return data;
      }
    },
    *recharge({ payload }, { call }) {
      const { error, data } = yield call(recharge, payload);
      if (error) {
        Toast.show({
          content: error,
        });
        return false;
      }
      return data;
    },
    *sendCheckSmsCode({ payload }, { call }) {
      const { error, data } = yield call(sendCheckSmsCode, payload);
      if (error) {
        Toast.show({
          content: error,
        });
        return {
          success: false,
          error,
        };
      }
      if (data) {
        return {
          data,
          success: true,
        };
      } else {
        return false;
      }
    },
    *checkVerifyCode({ payload }, { call }) {
      const { error, data, code } = yield call(checkVerifyCode, payload);
      if (error) {
        Toast.show({
          content: error,
        });
        return {
          success: false,
          error,
          code,
        };
      }
      if (data) {
        return {
          data,
          success: true,
        };
      } else {
        return false;
      }
    },
    *getUserList({ payload }, { call, put, select }) {
      const { pageIndex, pageSize } = yield select(state => state.coinMerchant);
      const { error, data } = yield call(getPayUser, { ...payload, pageIndex: payload.force ? 1 : pageIndex, pageSize: pageSize, force: undefined });
      if (error || !data) {
        yield put({
          type: 'updateUserList',
          payload: {},
        });
        if (error) {
          Toast.show({
            content: error,
          });
        }
        return false;
      }
      yield put({
        type: 'updateUserList',
        payload: { ...data, pageIndex: payload.force ? 2 : pageIndex + 1, force: payload.force },
      });
      return true;
    },
    *getUserArea({ payload }, { call, put }) {
      const { error, data } = yield call(getUserArea, payload);
      if (error) {
        Toast.show({
          content: error,
        });
        yield put({
          type: 'updateArea',
          payload: '',
        });
        return error;
      }
      if (data) {
        yield put({
          type: 'updateArea',
          payload: data || '',
          // payload: 'TR',
        });
        return true;
      }
    },
    *getPayAreaDefaultCountry({ payload }, { call, put }) {
      const { error, data } = yield call(fetchPayAreaDefaultCountry, payload);
      if (error) {
        Toast.show({
          content: error,
        });
        yield put({
          type: 'updateDefaultCountry',
          payload: '',
        });
        return false;
      }
      if (data && data.length > 0) {
        yield put({
          type: 'updateDefaultCountry',
          payload: data,
        });
        return data;
      }
    },
  },
};

export default coinMerchant;
