import {create} from 'dva-core'
import createLoading from 'dva-loading';
import models from './models'
const app = create()//创建应用实例
app.use(createLoading());

if(Array.isArray(models)) {
  models.forEach(model => {
    app.model(model)
  })
}

app.start()

export default app._store;
