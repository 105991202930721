import React, { memo } from 'react';
import classnames from 'classnames';
import './index.less';
export default memo(function LoaingIcon(props) {
  const { size, className, style = {}, maskColor, ...otherProps } = props;
  style.fontSize = size;
  const cls = classnames(className, 'm-loading-icon-wrap');
  return (
    <div className={cls} style={style} {...otherProps}>
      <div className='m-loading-ani'>
        <div className='m-loading-icon'></div>
        <div className='m-loading-mask' style={{ borderColor: maskColor }}></div>
      </div>
    </div>
  );
});
