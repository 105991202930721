import { useCallback, useLayoutEffect, useState } from 'react';

export default function useRootSize(size = 375, font = 12, defaultMax = 10000) {
  const width = document.documentElement.clientWidth || document.body.clientWidth;
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth || document.body.clientWidth);
  const setRootSize = useCallback(() => {
    if (document.documentElement.clientWidth) {
        document.documentElement.style.fontSize = (document.documentElement.clientWidth / size) * font + 'px';
      } else {
      if (document.body.clientWidth) {
        document.body.style.fontSize = (document.body.clientWidth / size) * font + 'px';
      }
    }
  }, [font, size]);
  useLayoutEffect(() => {
    if (width > defaultMax) {
      return;
    }
    setRootSize();
  }, [setRootSize]);
  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      if (width > defaultMax) {
        return;
      }
      setRootSize();
      setWindowWidth(document.documentElement.clientWidth || document.body.clientWidth);
    });
  }, [defaultMax, setRootSize]);
  const pxtorem = useCallback(
    n => {
      return `${n * (windowWidth / size)}px`;
    },
    [size, windowWidth]
  );
  const pxtoremNpx = useCallback(
    n => {
      return `${n * (windowWidth / size)}`;
    },
    [size, windowWidth]
  );
  return {
    pxtorem: pxtorem,
    pxtoremNpx: pxtoremNpx,
  };
}
