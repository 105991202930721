import { reportRoom, reportUser } from '@/services/api';
import countryList from '@/config/country';
import { Toast } from 'antd-mobile';
import { officialPayInfo, coinList, orderInit, rechargeResult } from '@/services/api';

const report = {
  namespace: 'recharge',
  state: {
    rechargeList: [],
    coutryList: [],
    areaDefaultCountry: '',
    userInfo: {},
    allCountryList: [],
  },
  reducers: {
    updateChannels(state, { payload = [] }) {
      const temp = [];
      payload.forEach(item => {
        const country = countryList.find(val => val.alpha2Code == item.country);
        temp.push({
          ...item,
          ...country,
        });
      });
      return {
        ...state,
        coutryList: temp,
      };
    },
    updateInfo(state, { payload }) {
      const temp = [];
      const allCountryList = [];
      payload?.payChannelList?.forEach(item => {
        const country = countryList.find(val => val.alpha2Code == item.country);
        if (!allCountryList.find(ele => ele.alpha2Code == item.country)) {
          allCountryList.push({
            ...item,
            ...country,
          })
        }
        temp.push({
          ...item,
          ...country,
        });
      });
      return {
        ...state,
        userInfo: payload?.userInfo,
        payChannelList: temp,
        allCountryList,
        isExpired: payload?.isExpired,
      };
    }
  },
  effects: {
    *rechargeResult({ payload }, { call }) {
      const { data } = yield call(rechargeResult, payload);
      return data;
    },
    *orderInit ({ payload }, { call }) {
      const { data } = yield call(orderInit, payload);
      return data;
    },
    *coinList ({ payload }, { call }) {
      const { data } = yield call(coinList, payload);
      return data;
    },
    *officialPayInfo ({ payload }, { call, put }) {
      const { data = {}, error } = yield call(officialPayInfo, payload);
      console.log(data, 'sdck')
      const { userInfo, payChannelList, isExpired } = data || {}
      // console.log(res, 'dsac')
      // return res;
      yield put({
       type: 'updateInfo',
       payload: {
        userInfo,
        payChannelList,
        isExpired,
       }
      });
      if (error) {
        return false;
      }
      return true;
    }
  },
};

export default report;
