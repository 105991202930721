import { feedback } from '@/services/api';

const feedbackModel = {
  namespace: 'feedback',
  state: {},
  reducers: {},
  effects: {
    *submit({ payload }, { call }) {
      const { error } = yield call(feedback, {
        ...payload,
        screenShots: payload.screenShots.join(','),
      });
      if (error) {
        return error;
      }
      return true;
    },
  },
};

export default feedbackModel;
