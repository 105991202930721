import React, { memo } from 'react';
import classnames from 'classnames';
import LoadingIcon from '@/component/loadingIcon';
import { format } from '@/asset/js/i18n';
import './index.less';

export default memo(function Loading(props) {
  const { className, maskColor, ...otherProps } = props;
  const cls = classnames('m-loading', className);
  return (
    <div className={cls} {...otherProps}>
      <div className='m-loading-wrap'>
        <LoadingIcon maskColor={maskColor} />
        {/* <p>{format('loading')}</p> */}
      </div>
    </div>
  );
});
