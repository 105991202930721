import { getDiamondToMoneyProductList, getUserAccount, getUserInfoById, fullTaskInfo } from '@/services/api';
import { Toast } from 'antd-mobile';

const myEarning = {
  namespace: 'myEarning',
  state: {
    list: [],
    diamondBalance: 0,
    anchorStatus: 0, // 0：UGC，1:PGC
    userInfo: {},
    officialTask: {},
  },
  reducers: {
    updateList(state, { payload = [] }) {
      return {
        ...state,
        list: payload || [],
      };
    },
    updateAccount(state, { payload = {} }) {
      return {
        ...state,
        diamondBalance: payload.diamondBalance || 0,
        anchorStatus: payload.anchorStatus || 0,
        showExchange: payload.showExchange || false,
      };
    },
    updateUserInfo(state, { payload = {} }) {
      return {
        ...state,
        userInfo: payload || {},
      };
    },
    updateOfficialTask(state, { payload = [] }) {
      return {
        ...state,
        officialTask: payload || {},
      };
    },
  },
  effects: {
    *getList({ payload }, { call, put }) {
      const { error, data } = yield call(getDiamondToMoneyProductList, payload);
      if (error || !data) {
        yield put({
          type: 'updateList',
          payload: [],
        });
        Toast.show({
          content: error,
        });
        return false;
      }
      yield put({
        type: 'updateList',
        payload: data,
      });
      return true;
    },
    *getAccount({ payload }, { call, put }) {
      const { error, data } = yield call(getUserAccount, payload);
      if (error || !data) {
        yield put({
          type: 'updateAccount',
          payload: {},
        });
        Toast.show({
          content: error,
        });
        return false;
      }
      yield put({
        type: 'updateAccount',
        payload: data,
      });
      return true;
    },
    *getUserInfoById({ payload }, { call, put }) {
      const { error, data } = yield call(getUserInfoById, payload);
      if (error || !data) {
        yield put({
          type: 'updateUserInfo',
          payload: {},
        });
        Toast.show({
          content: error,
        });
        return false;
      }
      yield put({
        type: 'updateUserInfo',
        payload: data,
      });
      return true;
    },
    *fullTaskInfo({ payload }, { call, put }) {
      const res = yield call(fullTaskInfo, payload);
      yield put({
        type: 'updateOfficialTask',
        payload: res?.data,
      });
      return res;
    },
  },
};

export default myEarning;
