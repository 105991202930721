import { finishAristocratCoinGet, fetchAristocratList, fetchAristocratUserInfo } from '@/services/api';

const curAristocracyModel = {
  namespace: 'curAristocracyModel',
  state: {
    // level: {
    //   nextLevel: 12,
    //   needExp: 333,
    // },
    list: [],
    userInfo: {},
    coin: 0,
    showOk: false,
    isGeted: false,
    title: '',
    productId: '',
  },
  reducers: {
    updateList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    updateUserInfo(state, { payload }) {
      return {
        ...state,
        userInfo: payload,
      };
    },
    updateState(state, { payload }) {
      return {
        ...state,
        coin: payload.coin,
        showOk: payload.showOk,
        isGeted: payload.isGeted,
        title: payload.title,
        productId: payload.productId,
      };
    },
  },
  effects: {
    *getVipList({ payload }, { call, put }) {
      const { data, error } = yield call(fetchAristocratList);
      if (error) {
        return error;
      }
      if (data && data.length) {
        const current = data.filter(item => item.identityLevel == payload.identityLevel)[0];
        const list = [...current.details];
        if (current.welfare) {
          list.unshift({ ...current.welfare });
        }
        yield put({
          type: 'updateList',
          payload: list,
        });
        yield put({
          type: 'updateState',
          payload: {
            coin: current.welfare ? current.welfare.amount : 0,
            showOk: current.welfare ? current.welfare.enable : false,
            isGeted: current.welfare ? current.welfare.received : false,
            title: current.title,
            productId: current.productId,
          },
        });
        return true;
      }
    },
    *getVipUserInfo({ payload }, { call, put }) {
      const { data, error } = yield call(fetchAristocratUserInfo, { ...payload });
      if (error) {
        return error;
      }
      if (data) {
        yield put({
          type: 'updateUserInfo',
          payload: data || {},
        });
        return true;
      }
    },
    *getCoin({ payload }, { call, put }) {
      const { data, error } = yield call(finishAristocratCoinGet, { ...payload });
      if (error) {
        return error;
      }
      if (data) {
        return true;
      }
    },
  },
};

export default curAristocracyModel;
