// 异步调用后台接口
import axios from 'axios';
import { stringify } from 'qs';
import { getValueFromUA } from '@/asset/js/utils';

export const fetchData = async (method, url, params, options) => {
  try {
    let result = {};
    if (method === 'POST') {
      result = await axios.post(url, { ...params }, options);
    } else if (method === 'GET') {
      result = await axios.get(`${url}?${stringify({ ...params })}`, options);
    } else if (method === 'PUT') {
      result = await axios.put(url, { ...params }, options);
    }
    const { code, message, body } = result;
    if (code === 1 || code === '1') {
      return { data: body };
    }
    return { error: message || 'ajax.system', code: code, data: body };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export async function uploadImage(params) {
  return await axios.post('/api/cms/image', params, {
    headers: {
      adminName: 'wap',
    },
  });
}

const platform = getValueFromUA('appPlatform') === 'android' ? 1 : 2;
const appPlatform = getValueFromUA('appPlatform') === 'android' ? 1 : 2;

export const getUserInfoById = async params => fetchData('GET', '/api/user/snackUser/getUserInfoById', params);

export const getShareUserInfoById = async params => fetchData('GET', '/api/user/snackUser/share/userInfo', params);

export const getRoomInfo = async params => fetchData('GET', '/api/voice/content/room/share/info', params);

export const feedback = async params => fetchData('POST', '/api/cms/feedback/insert', params);

export const reportRoom = async params => fetchData('POST', '/api/cms/roomReport/insert', params);

export const reportUser = async params => fetchData('POST', '/api/cms/userReport/insert', params);

export const fetchGiftWallList = async params => fetchData('GET', '/api/voice/user/userAssets/getGiftWallInfo', params);

export const fetchGiftWallValue = async params => fetchData('GET', '/api/voice/user/userAssets/getCoinMinValue', params);

export const fetchHaloLevel = async params => fetchData('GET', '/api/voice/user/userCharmLevel/getInfo', params);

export const fetchUserIcon = async params => fetchData('GET', '/api/voice/user/userLevel/getWebInfo', params);

export const fetchHaloIcon = async params => fetchData('GET', '/api/voice/user/userCharmLevel/getWebInfo', params);

export const fetchUserLevel = async params => fetchData('GET', '/api/voice/user/userLevel/getInfo', params);

export const fetchAristocratList = async params => fetchData('GET', '/api/voice/order/product/aristocrat/listV2', params);
export const fetchAristocratUserInfo = async params => fetchData('GET', '/api/voice/user/snackUser/getUserInfoById', params);
export const finishAristocratCoinGet = async params => fetchData('POST', '/api/voice/user/snackUser/clockIn', params);

export const fetchVipUserStatus = async params => fetchData('GET', '/api/voice/user/switch/status/get', params);

export const setVipUserStatus = async params => fetchData('POST', '/api/voice/user/switch/status/update', params);

export const apolloConfig = async params => {
  return await axios.post('/api/message/snackMessage/getApolloConfigByList', params.data);
};

export const getDiamondToMoneyProductList = async params => fetchData('GET', '/api/order/purchase/diamondToMoneyProduct/list', params);

export const getUserAccount = async params => fetchData('GET', '/api/user/userAccount/income', params);

export const fetchRankingList = async params => fetchData('GET', '/api/voice/content/room/membersRank', params);

export const fullTaskInfo = async params => fetchData('GET', '/api/user/task/fullTaskInfo', params);

export const getTaskList = async params => fetchData('GET', '/api/user/task/list', params, {
  headers: {
    appPlatform: getValueFromUA('appPlatform') === 'android' ? 1 : 2,
  },
});

export const getLimitedTaskList = async params => fetchData('GET', '/api/activity/limitedTask/info', params);
export const getLimitedTaskReward = async params => fetchData('POST', '/api/activity/limitedTask/reward', params);

export const luckyGift = async params => fetchData('GET', '/api/voice/user/luckyGift/rank', params);

export const channelBind = async params => fetchData('POST', '/api/order/payout/channelBind', params, {
  headers: {
    platform,
    appPlatform
  },
});

export const channelList = async params => fetchData('GET', '/api/order/payout/channelList', params, {
  headers: {
    platform,
    appPlatform
  },
});

export const channelUnbind = async params => fetchData('GET', '/api/order/payout/channelUnbind', params, {
  headers: {
    platform,
    appPlatform
  },
});

export const channelBindConfig = async params => fetchData('GET', '/api/order/payout/channelBindConfig', params, {
  headers: {
    platform,
    appPlatform
  },
});

export const tvStatus = async params => fetchData('GET', '/api/user/tvWall/status/modify', params);

export const tvConfig = async params => fetchData('GET', '/api/user/config/info', params);

export const officialPayInfo = async params => fetchData('GET', '/api/order/payChannel/official/channelInfo', params);

export const coinList = async params => fetchData('GET', '/api/order/product/official/coin/sort/list', params);

export const orderInit = async params => fetchData('POST', '/api/order/wap/init', params, {
  headers: {
    appPlatform: 3,
  },
});

export const rechargeResult = async params => fetchData('GET', '/api/order/rechargeResult', params);
