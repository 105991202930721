import { getShareUserInfoById, getRoomInfo } from '@/services/api';

const share =  {
  namespace: 'shareLand',
  state: {
    shareInfo: {},
    roomInfo: {},
  },
  reducers: {
    updateUserId(state, { payload }) {
      return {
        ...state,
        shareInfo: payload,
      };
    },
    updateRoomInfo(state, { payload }) {
      return {
        ...state,
        roomInfo: payload,
      };
    },
  },
  effects: {
    *getUserInfoById({ payload }, { call, put }) {
      const data = yield call(getShareUserInfoById, payload);

      yield put({
        type: 'updateUserId',
        payload: data.data || {},
      });
    },
    *getRoomInfo({ payload }, { call, put }) {
      const data = yield call(getRoomInfo, payload);

      yield put({
        type: 'updateRoomInfo',
        payload: data.data || {},
      });
    },
  },
};

export default share;
