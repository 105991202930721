import Helper, { AppCore } from './helper';

/**
 * 内部App管理模块
 */
const Apps = {
  // App缓存
  cache: {},

  // 保留插入的App顺序
  cacheIndex: [],
  /**
   * @param {function} fn	cacheIndex会被当作参数传递给此函数，返回的数组作为新的cacheIndex
   * @description 调整cacheIndex的元素顺序
   */
  setCacheIndex(fn) {
    if (Helper.isFunction(fn)) {
      const cacheIndex = fn(this.cacheIndex.slice(0));
      this.cacheIndex = Helper.isArray(cacheIndex) ? cacheIndex : [];
    }
  },
  /**
   * @privateEntry {boolean} [privateEntry=false] 是否注册到私有入口： AppCore[appName]
   * @param {string} appName 注册的环境标识，比如 weixin
   * @param {object} appConfig 注册的配置内容，其中<br>
   *                           appConfig.is 方法作为判断环境之用，不引用到任何入口<br>
   *                           appConfig.version 方法仅仅添加到私有入口中<br>
   *                           appConfig.ready 方法仅仅添加到私有入口中<br>
   *                           以 _ 开头的方法会被忽略<br>
   *                           非函数成员会被忽略<br>
   *                           其他方法的同名代理方法会被扩展到 AppCore 上<br>
   *                           如果privateEntry为true，则同步扩展到 AppCore[appName] 上
   * @description 注册一个App到AppCore
   */
  reg(privateEntry, name, config) {
    if (typeof privateEntry !== 'boolean') {
      config = name;
      name = privateEntry;
      privateEntry = false;
    }
    let cache = this.cache[name];
    const conf = config || {};
    const privateFns = (AppCore[name] = AppCore[name] || {});
    if (!cache) {
      this.cacheIndex.push(name);
      cache = this.cache[name] = {};
    }

    // 扩展所有的方法到私有缓存上
    Helper.extend(cache, conf);

    // 检查新插入的方法，进行不同的扩展处理
    for (const methodName in conf) {
      if (Object.prototype.hasOwnProperty.call(conf, methodName)) {
        const method = conf[methodName];
        // version / ready / sendCmd 作为通用方法，仅仅能保存到私有命名空间上
        const isPrivateMethod = /^(?:version|ready|sendCmd)$/.test(methodName) || methodName.indexOf('_') === 0;

        // is 方法作为核心方法仅仅保存在Apps私有的缓存上
        if (methodName !== 'is' && Helper.isFunction(method)) {
          // version / ready 作为通用方法，强制存到私有命名空间上
          if (privateEntry || isPrivateMethod) {
            privateFns[methodName] = method;
          }
          // 非私有方法扩展到主入口上
          if (!isPrivateMethod) {
            Apps.extendCore(methodName);
          }
        }
      }
    }
  },
  // 分析当前环境是什么
  is() {
    const appName = [];
    const cache = Apps.cache;
    this.cacheIndex.forEach((name, index) => {
      const app = cache[name];
      if (Helper.isFunction(app.is) && app.is(Helper.ua, Helper.os) === true) {
        appName.push(name);
      }
    });
    return appName;
  },
  // 查找命中的环境配置中，有配置方法的数据
  findMethodOwner(method) {
    const cache = Apps.cache;
    const env = Apps.is();
    return env[Helper.findIndex(env, appName => Helper.isFunction(cache[appName][method]))];
  },
  // 扩展AppCore自查询方法
  // 内部使用
  extendCore(method) {
    // _开始是内部方法，不让外部访问
    if (!method || AppCore[method] || method.indexOf('_') === 0) {
      return;
    }
    // 找到该方法的hook，如果没有设置空对象
    const hooks = Apps.hooks[method] || {};
    // 扩展同名方法
    AppCore[method] = function(...args) {
      // 通过findMethodOwner找到要调用具体环境
      const appName = Apps.findMethodOwner(method);
      //  准备参数
      const arg = Array.prototype.slice.call(args, 0);
      // 是否有默认的hook方法，如果有调用默认并返回，否则返回undefined
      // 没有具体环境就是调用hook里面默认的方法
      if (!appName) {
        return hooks.defaultFn ? hooks.defaultFn.apply(this, arg) : undefined;
      }
      // 通过app
      const fn = Apps.cache[appName][method];
      // 有hook，通过hook的run方法
      if (hooks.run) {
        // 追加三个参数用于hook修正
        return hooks.run.apply(this, [fn, appName, Apps.cache[appName]].concat(arg));
      }
      // 没有hook直接调用
      return fn.apply(this, arg);
    };
  },
  // 注册钩子
  regHook(method, obj) {
    if (!method || !obj) {
      return;
    }
    if (!obj.defaultFn && !obj.run) {
      return;
    }
    Apps.hooks[method] = obj;
  },
  // 扩展hook钩子
  hooks: {
    /**
     * @name AppCore.sendCmd
     * @param  {string} cmd  要发送的客户端命令
     * @param  {type} [type=iframe] 发送客户端命令的方式，但是无任何互调通知。可选 href / iframe
     * @description 用指定的方式发送客户端命令
     * @function
     */
    sendCmd: {
      defaultFn(cmd, type) {
        Helper.sendCmd(cmd, type || 'href');
      },
      // run(fn, appName, allAppMethod, ...otherParam)
    },
  },
};

/**
 * 立即注册sendCmd方法
 * 之所有没有直接扩展到AppCore上，就是为了提供sendCmd覆盖重写的可能
 */
Apps.extendCore('sendCmd');

/**
 * 绑定cmd命令的默认监听
 */
Helper.ready(() => {
  Helper.delegate(document.body, 'click', '*[cmd], *[data-cmd]', function(e) {
    let cmd = String(this.getAttribute('cmd') || this.getAttribute('data-cmd'));
    // 由分享模块处理
    if (!cmd || cmd === 'share') {
      return;
    }
    // AppCore.XXX(para1, para2...);
    // 指定AppCore调用方法
    const result = cmd.match(/^\s*AppCore\s*\.\s*([a-zA-Z$_]+)\s*\(([^)]*)\)/);
    const dot = /^(?:"|')+|(?:"|')+$/g;
    let param = [];
    if (result && result.length > 1) {
      if (result[2]) {
        param = result[2].split(',');
        param = param.map(current => current.trim().replace(dot, ''));
      }
      // 查到指定方法
      if (AppCore[result[1]]) {
        e.preventDefault();
        return AppCore[result[1]](...param);
      }
    }
    // 查找当前应用名称
    const appId = window.appId || document.body.id;
    const detectApp = Apps.is()[0];
    if (AppCore.getPrivateProto) {
      // cmd格式  netescaipiao://hall||neteshappybuy://hall
      // 多条命令用||隔开
      const cmds = cmd.split('||');
      // 获取当前的协议类型，如 netscaipiao
      let protocol = AppCore.getPrivateProto();
      // 如果取到了协议，就按协议走
      if (protocol) {
        if (typeof protocol === 'string') {
          protocol = [protocol];
        }
        Helper.findIndex(protocol, data => {
          const index = Helper.findIndex(cmds, one => one.trim().indexOf(data) === 0);
          if (index > -1) {
            cmd = cmds[index];
            return true;
          }
        });
      }
    }
    // window.inMyApp兼容新版的 pageMaker判断
    if ((detectApp && detectApp === appId) || window.inMyApp) {
      // 没有协议就检查当前环境是否需要跳转命令
      // 如果在当前项目App内嵌，就阻止元素默认行为
      // 在自己app中阻止默认时间有问题(无法阻止，估计是app的拦截问题)，所以用这个
      // eslint-disable-next-line no-script-url
      e.currentTarget.setAttribute('href', 'javascript:void;');
      e.preventDefault();
    }

    // 发送命令
    // 只有一个的时候取第一个命令，多个的时候也取第一个命令
    AppCore.sendCmd(cmd.split('||')[0]);
    let href = this.getAttribute('data-href');
    if (href) {
      window.setTimeout(() => {
        // location.href = href;
        window.top.location.href = href;
      }, 600);
    }
  });
});

export default Apps;
