import {
  fetchDiamondIncomeReplySpeed,
  fetchDiamondIncomeReplyDepth,
  fetchDetailInfo,
  fetchDiamondBackDetail,
  fetchDiamondIncomeStatistics,
  fetchEvaluateInfo,
  fetchImRound,
  fetchReplySpeed,
  fetchEffectiveMic,
  fetchEffectiveMicdetails,
  fetchEffectiveDailyMicdetails,
  fetchEffectiveMicConfig,
  interactionInfo,
} from '@/services/goldCoin';
import { apolloConfig } from '@/services/api';
import { getUserInfoById } from '@/services/api';
import { format } from '@/asset/js/i18n';

import like from '@/page/diamondsFlow/assets/like.png';
import boring from '@/page/diamondsFlow/assets/boring.png';
import hate from '@/page/diamondsFlow/assets/hate.png';

const diamondFlow = {
  namespace: 'diamondFlow',
  state: {
    totalBackDiamond: 0,
    todayBackDiamond: 0,
    salary_guild_show: '{}',
    effective: {},
    interactionInfo: {},
    table: [
      {
        title: [format('voiceChat'), format('voiceChat1')],
        contentTitle: [format('like'), format('boring'), format('Hate')],
        icon: [like, boring, hate],
      },
      {
        title: [format('quickCall'), format('quickCall1')],
        contentTitle: [format('like'), format('boring'), format('Hate')],
        icon: [like, boring, hate],
      },
      {
        title: [format('chatting'), format('chatting1')],
        contentTitle: [format('chatConversation'), format('reached')],
      },
      {
        title: [format('chatSpeed'), format('chatSpeed1')],
        contentTitle: [format('speed'), format('count')],
      },
    ],
    userId: '',
  },
  reducers: {
    updataMiss(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateInfo(state, { payload }) {
      return {
        ...state,
        table: [
          { ...state.table[0], content: [payload[0].data], format: ['likeNum', 'boringNum', 'hateNum'] },
          { ...state.table[1], content: [payload[1].data], format: ['likeNum', 'boringNum', 'hateNum'] },
          { ...state.table[2], content: payload[2].data, format: ['roundDes', 'userNum'] },
          { ...state.table[3], content: payload[3].data, format: ['levelDesc', 'levelCount'] },
        ],
      };
    },
    updateUserId(state, { payload }) {
      return {
        ...state,
        userId: payload,
      };
    },
    updateSalary(state, { payload }) {
      return {
        ...state,
        salary_guild_show: payload,
      };
    },
    updateEffective(state, { payload }) {
      return {
        ...state,
        effective: payload,
      };
    },
    updateInteractionInfo(state, { payload }) {
      return {
        ...state,
        interactionInfo: payload,
      };
    }
  },
  effects: {
    *apolloConfig({ payload }, { call, put }) {
      const res = yield call(apolloConfig, { data: ['salary_guild_show'] });
      yield put({
        type: 'updateSalary',
        payload: res.body.salary_guild_show,
      });
      return res;
    },
    *getDetailInfo({ payload }, { call }) {
      const res = yield call(fetchDetailInfo, payload);
      return res;
    },
    *getDiamondBackDetail({ payload }, { call, put }) {
      const { data } = yield call(fetchDiamondBackDetail, payload);
      yield put({
        type: 'updataMiss',
        payload: {
          totalBackDiamond: data?.totalBackDiamond,
          todayBackDiamond: data?.todayBackDiamond,
        },
      });

      return {
        data: data?.backDetail,
      };
    },
    *getDiamondIncomeStatistics({ payload }, { call }) {
      const { data = {} } = yield call(fetchDiamondIncomeStatistics, payload);
      return data;
    },
    *getUserInfoById(_, { call, put }) {
      const { data } = yield call(getUserInfoById);
      const { userAnchor, userId } = data || {};

      yield put({
        type: 'updateUserId',
        payload: userId,
      });
      return userAnchor;
    },
    *getDiamondIncomeInfo({ payload }, { call, put, select }) {
      const { month, day, anchorId, cycle, weekCycle } = payload;
      const userId = yield select(state => state.diamondFlow.userId);
      const voice = yield call(fetchEvaluateInfo, { type: 1, anchorId: anchorId || userId, day, month, cycle, weekCycle });
      const quick = yield call(fetchEvaluateInfo, { type: 2, anchorId: anchorId || userId, day, month, cycle, weekCycle });
      const imRound = yield call(fetchImRound, { anchorId: anchorId || userId, day, month, cycle, weekCycle });
      const speed = yield call(fetchReplySpeed, { userId: anchorId, day, month, cycle, weekCycle });

      const { data } = voice;
      if (!data) {
        return;
      }
      yield put({
        type: 'updateInfo',
        payload: {
          0: voice,
          1: quick,
          2: imRound,
          3: speed,
        },
      });

      return;
    },
    *getchDiamondIncomeReplySpeed({ payload }, { call }) {
      const res = yield call(fetchDiamondIncomeReplySpeed, payload);
      return res;
    },
    *getchDiamondIncomeReplyDepth({ payload }, { call }) {
      const res = yield call(fetchDiamondIncomeReplyDepth, payload);
      return res;
    },
    *getEffectiveMic({ payload }, { call, put }) {
      const res = yield call(fetchEffectiveMic, payload);
      yield put({
        type: 'updateEffective',
        payload: res?.data,
      });
      return res?.data;
    },
    *getEffectiveMicdetails({ payload }, { call }) {
      const res = yield call(fetchEffectiveMicdetails, payload);
      return res?.data;
    },
    *getEffectiveDailyMicdetails({ payload }, { call }) {
      const res = yield call(fetchEffectiveDailyMicdetails, payload);
      return res?.data;
    },
    *fetchEffectiveMicConfig({ payload }, { call }) {
      const res = yield call(fetchEffectiveMicConfig, payload);
      return res?.data;
    },
    *interactionInfo({ payload }, { call, put }) {
      const res = yield call(interactionInfo, payload);
      console.log(res, 'res')
      yield put({
        type: 'updateInteractionInfo',
        payload: res.data || {},
      })
      return res;
    }
  },
};

export default diamondFlow;
