import action, { getCommonParam } from '@/asset/js/userAction/index';

export function coinClick(id) {
  return action('coin_click', {
    ...getCommonParam(),
    itemId: id,
    refer: 'coin_dealer_recharge',
  });
}
export function channelClick(id) {
  return action('pay_channel_click', {
    ...getCommonParam(),
    itemId: id,
    refer: 'coin_dealer_recharge',
  });
}
export function channelList(ids) {
  return action('pay_channel_show', {
    ...getCommonParam(),
    itemId: ids,
    refer: 'coin_dealer_recharge',
  });
}
export function orderInit(id, orderId, channelName) {
  return action('orderInit', {
    ...getCommonParam(),
    itemId: id,
    refer: 'coin_dealer_recharge',
    extra1: orderId,
    extra2: channelName,
  });
}

export function rechargeShow() {
  return action('coinseller_recharge_pageshow', {
    ...getCommonParam(),
  });
}

export function checkShow() {
  return action('coinseller_check_pageshow', {
    ...getCommonParam(),
  });
}

export function rechargeSucShow() {
  return action('coinseller_recharge_suc', {
    ...getCommonParam(),
  });
}

export function rechargeClick(params) {
  return action('coinseller_exchangepage_click', {
    ...getCommonParam(),
    ...params,
  });
}

export function rechargeClickResult(params) {
  return action('coinseller_exchangepage_click_result', {
    ...getCommonParam(),
    ...params,
  });
}

export function rechargePopupClick(extra, itemId, extra1, extra2) {
  return action('coinseller_confirmpopup_click', {
    ...getCommonParam(),
    extra,
    itemId,
    extra1,
    extra2,
  });
}

export function rechargePopupClickResult(itemId, extra1, extra2, extra4, label, extra3, actionParam) {
  const params = {
    extra: 'confirm',
    itemId,
    extra1,
    extra2,
    extra4,
    label,
  };
  if (extra3) {
    params.extra3 = extra3;
  }
  if (actionParam) {
    params.actionParam = actionParam;
  }
  return action('coinseller_confirmpopup_click_result', {
    ...getCommonParam(),
    ...params,
  });
}

export function coinseller_verifycode_ask(extra, extra1) {
  return action('coinseller_verifycode_ask', {
    ...getCommonParam(),
    extra,
    extra1,
  });
}

export function coinseller_verifycode_click(extra1) {
  return action('coinseller_verifycode_click', {
    ...getCommonParam(),
    extra1,
  });
}

export function coinseller_verifycode_ask_result(extra, extra1, label, extra3, actionParam) {
  const params = {
    extra,
    extra1,
    label,
  };
  if (extra3) {
    params.extra3 = extra3;
  }
  if (actionParam) {
    params.actionParam = actionParam;
  }
  return action('coinseller_verifycode_ask_result', {
    ...getCommonParam(),
    ...params,
  });
}
