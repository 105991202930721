import { fetchRankingList } from '@/services/api';

const GiftRank = {
  namespace: 'ranking',
  state: {
    daily: [],
    weekly: [],
  },
  reducers: {
    updateList(state, { payload }) {
      return {
        ...state,
        [payload.key]: payload.value,
      };
    },
  },
  effects: {
    *getRankingList({ payload }, { call, put }) {
      const { error, data } = yield call(fetchRankingList, payload);
      if (error) {
        return error;
      }
      yield put({
        type: 'updateList',
        payload: {
          key: payload.type === 0 ? 'daily' : 'weekly',
          value: data && data.length > 0 ? data.sort((a, b) => a.position - b.position) : [],
        },
      });
      return true;
    },
  },
};

export default GiftRank;
