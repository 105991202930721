import { fetchVipUserStatus, setVipUserStatus } from '@/services/api';

const vipPrivileges = {
  namespace: 'vipPrivileges',
  state: {
    isOpen: false,
    isShow: false,
  },
  reducers: {
    updateIsShow(state, { payload }) {
      return {
        ...state,
        isShow: payload,
      };
    },
    updateIsOpen(state, { payload }) {
      return {
        ...state,
        isOpen: payload,
      };
    },
  },
  effects: {
    *getVipUserStatus({ payload }, { call, put }) {
      const { data, error } = yield call(fetchVipUserStatus, { switchUse: 1 });
      if (error) {
        return error;
      }
      if (data) {
        yield put({
          type: 'updateIsShow',
          payload: !!data.isShow,
        });
        yield put({
          type: 'updateIsOpen',
          payload: !!data.switchStatus,
        });
        return true;
      }
    },
    *setVipUserStatus({ payload }, { call, put }) {
      const { data, error } = yield call(setVipUserStatus, { switchUse: 1, switchStatus: payload });
      if (error) {
        return error;
      }
      yield put({
        type: 'updateIsOpen',
        payload: !!payload,
      });
      return true;
    },
  },
};

export default vipPrivileges;
