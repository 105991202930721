import { fetchGiftWallList, fetchGiftWallValue } from '@/services/api';

const giftWall =  {
  namespace: 'giftWall',
  state: {
    list: [],
    value: null,
    lastId: null,
  },
  reducers: {
    updateLastId(state, { payload }) {
      return {
        ...state,
        lastId: payload,
      };
    },
    updateValue(state, { payload }) {
      return {
        ...state,
        value: payload,
      };
    },
    updateList(state, { payload = [] }) {
      return {
        ...state,
        list: payload,
      };
    },
  },
  effects: {
    *getGiftWallValue({ payload }, { call, put }) {
      const { error, data } = yield call(fetchGiftWallValue, payload);
      if (error) {
        return error;
      }
      yield put({
        type: 'updateValue',
        payload: data,
      });
    },
    *getGiftWallList({ payload }, { call, put }) {
      const { error, data } = yield call(fetchGiftWallList, {
        count: 20,
      });
      if (error) {
        return error;
      }
      if (data && data.length > 0) {
        yield put({
          type: 'updateList',
          payload: data,
        });
        yield put({
          type: 'updateLastId',
          payload: data[data.length - 1].id,
        });
      } else {
        yield put({
          type: 'updateList',
          payload: [],
        });
        yield put({
          type: 'updateLastId',
          payload: null,
        });
      }
    },
    *pullGiftWallList({ payload }, { call, put, select }) {
      const { list } = yield select(state => state.giftWall);
      const { error, data } = yield call(fetchGiftWallList, {
        count: 20,
        lastId: payload,
      });
      if (error) {
        return error;
      }
      if (data && data.length > 0) {
        yield put({
          type: 'updateList',
          payload: [...list, ...data],
        });
        yield put({
          type: 'updateLastId',
          payload: data[data.length - 1].id,
        });
      } else {
        yield put({
          type: 'updateLastId',
          payload: null,
        });
      }
    },
    *pullDownGiftWallList({ payload }, { call, put }) {
      const { error, data } = yield call(fetchGiftWallList, {
        count: 20,
      });
      if (error) {
        return error;
      }
      if (data && data.length > 0) {
        yield put({
          type: 'updateList',
          payload: data,
        });
        yield put({
          type: 'updateLastId',
          payload: data[data.length - 1].id,
        });
      } else {
        yield put({
          type: 'updateList',
          payload: [],
        });
        yield put({
          type: 'updateLastId',
          payload: null,
        });
      }
    },
  },
};

export default giftWall;
