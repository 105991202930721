// 多语言初始化
import React from 'react';
import 'intl';
import { addLocaleData, IntlProvider } from 'react-intl'; /* react-intl imports */
import IntlMessageFormat from 'intl-messageformat';
import memoizeFormatFromat from 'intl-format-cache';
// import DateFormat from '@/asset/js/dateFormat';
// 添加语言请修改这里，然后在修改locales下的语言包
import en from 'react-intl/locale-data/en';
import ar from 'react-intl/locale-data/ar';
import hi from 'react-intl/locale-data/hi';
import id from 'react-intl/locale-data/id';
import tr from 'react-intl/locale-data/tr';
import en_US from '@/locales/en_US';
import trLang from '@/locales/tr';
import hiLang from '@/locales/hi';
import idLang from '@/locales/id';
import arLang from '@/locales/ar';
// import dateFormatCfg from '@/locales/timeFormat';
// 引入多语言环境的数据
addLocaleData([...en, ...ar, ...tr, ...hi, ...id]);
// rtl布局的语言
const rtlLang = ['ar'];

const message = {
  en: en_US,
  ar: arLang,
  tr: trLang,
  id: idLang,
  hi: hiLang,
};
// 注册语言方法
export function registerLang(lang, msg) {
  if (message[lang]) {
    Object.assign(message[lang], msg);
  }
}
const langs = Object.keys(message);
let locale = window.config.lang || 'en';
// 默认国际站
let country = window.config.country || null;
// 缓存中语言出错,或者国家出错
if (langs.indexOf(locale) === -1) {
  locale = 'en';
  country = '';
}
let fallBackLocale = 'en';

Object.defineProperty(window.config, 'lang', {
  enumerable: true,
  configurable: true,
  get() {
    return locale;
  },
  set(lang) {
    if (langs.find(cur => cur === lang)) {
      locale = lang;
      if (window.__i18n__) {
        window.__i18n__.setState({
          locale: locale,
        });
      }
    }
  },
});

Object.defineProperty(window.config, 'country', {
  enumerable: true,
  configurable: true,
  get() {
    return country;
  },
  set(c) {
    country = c;
    if (window.__i18n__) {
      window.__i18n__.setState({
        country: c,
      });
    }
  },
});

const IntlMessageFormatCache = memoizeFormatFromat(IntlMessageFormat);
/**
 * @param [String] msgName 定义在语言包中的msg的名称
 * @param [Object] format所需要的data
 * @format [Object] 自定义格式样式，请看https://github.com/yahoo/intl-messageformat
 */
export const format = (msgName, data, fromat) => {
  let lang = message[locale] || {};
  let m = lang[msgName];
  if (!m) {
    console.warn(`this is no ${msgName} feilds in the ${locale}, fallback to ${fallBackLocale}`);
    m = message[fallBackLocale][msgName];
    if (!m) {
      throw new Error(`fallback en failed, please add field ${msgName}`);
    }
  }
  const msg = new IntlMessageFormatCache(m, locale, fromat);
  return msg.format(data);
};

export class I18n extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale,
      country,
    };
    window.__i18n__ = this;
    this.initDir();
  }
  get message() {
    return message[this.state.locale || 'en'];
  }
  componentDidUpdate(prevProps, prevState) {
    this.initDir(prevState);
  }
  // initTime() {
  //   const { locale } = this.state;
  //   // 取不到默认英文格式化时间
  //   DateFormat.i18n = dateFormatCfg[locale] || dateFormatCfg['en'];
  // }
  // 初始化语言国家等等
  initDir(prevState = {}) {
    const lastCountry = prevState.country;
    const { locale, country } = this.state;
    const docClassList = document.documentElement.classList;
    docClassList.add('dir');
    document.documentElement.setAttribute('lang', locale);
    document.documentElement.setAttribute('country', country);
    if (lastCountry) {
      docClassList.remove(lastCountry);
    }
    // this.initTime();
    docClassList.add(country);
    if (rtlLang.includes(locale)) {
      document.documentElement.setAttribute('dir', 'rtl');
      docClassList.add('dir-rtl');
      docClassList.remove('dir-ltr');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
      docClassList.add('dir-ltr');
      docClassList.remove('dir-rtl');
    }
  }
  render() {
    const { component: Com, ...otherProps } = this.props;
    const { locale, country } = this.state;
    return (
      <IntlProvider locale={locale} messages={this.message}>
        <Com {...otherProps} key={`${locale}-${country}`} />
      </IntlProvider>
    );
  }
}

export default message;
